import React, { useContext } from 'react';
import './../../../form.css'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { Switch } from '../../../../common/formik';

import {
    Grid,
    Card,
    CardContent,
    CardHeader
} from '@material-ui/core';

import {
    Select,
} from '../../../../common'

import {
    FieldError
} from '../../../../common'

import { TransactionRequestContext } from '../../TransactionRequestContext';
import { TabsContext } from '../TabsContext';

import { AuthPlatformTableNames, ComplexEntityNames } from '../../../../../helpers';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
    },
    field: {
        width: '100%',
        "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.6)" // (default alpha is 0.38)
        }
    },
}));

export const CisoInfoForm = (props) => {
    const classes = useStyles();

    const {
        state,
        getTableOptionsWithCode,
        getComplexOptionsWithDescriptionByEntity
    } = useContext(TransactionRequestContext)

    const {
        isSavingRequest
    } = useContext(TabsContext);

    return (
        <>
            <Grid item xl={12} xs={12} md={12}>
                <Card>
                    <CardHeader title="Transaction Status" />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field
                                    name="isMappingCompleted"
                                    label="Mapping Completed"
                                    disabled={isSavingRequest || props.disabled}
                                    className={classes.field}
                                    component={Switch}
                                />
                                <FieldError name="isMappingCompleted" errors={state.errors} />
                            </Grid>                            
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xl={12} xs={12} md={12}>
                <Card>
                    <CardHeader title="SoD Activity" />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xl={12} md={12} xs={12}>
                                <Select
                                    multiple
                                    name="cisoSodActivities"
                                    label="SoD activity *"
                                    disabled={props.disabled}
                                    className={classes.field}
                                    options={getComplexOptionsWithDescriptionByEntity(ComplexEntityNames.SodActivity)}
                                />
                                <FieldError name="cisoSodActivities" errors={state.errors} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xl={12} xs={12} md={12}>
                <Card>
                    <CardHeader title="Technical Details" />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xl={12} md={12} xs={12}>
                                <Select
                                    name="releaseToUsersId"
                                    label="Release To Users"
                                    disabled={props.disabled}
                                    className={classes.field}
                                    options={getTableOptionsWithCode(AuthPlatformTableNames.ReleaseToUsers)}
                                />
                                <FieldError name="releaseToUsersId" errors={state.errors} />
                            </Grid>
                            <Grid item xl={12} md={12} xs={12}>
                                <Field
                                    multiline
                                    className={classes.field}
                                    name="securityTeamActivityNotes"
                                    variant="outlined"
                                    label="CISO Office - Activity Notes"
                                    disabled={props.disabled}
                                    component={TextField}
                                />
                                <FieldError name="securityTeamActivityNotes" errors={state.errors} />
                            </Grid>
                            <Grid item xl={12} md={12} xs={12}>
                                <Field
                                    className={classes.field}
                                    name="dedicatedRole"
                                    variant="outlined"
                                    label="Dedicated Role to assign the transaction"
                                    helperText=""
                                    disabled={props.disabled}
                                    component={TextField}
                                />
                                <FieldError name="dedicatedRole" errors={state.errors} />
                            </Grid>
                            <Grid item xl={12} md={12} xs={12}>
                                <Field
                                    className={classes.field}
                                    name="pfcgLink"
                                    variant="outlined"
                                    label="PFCG Link between transactions"
                                    helperText="i.e. MB51 & MB59"
                                    disabled={props.disabled}
                                    component={TextField}
                                />
                                <FieldError name="pfcgLink" errors={state.errors} />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name="noLogTransaction"
                                    label="No LOG transaction"
                                    disabled={isSavingRequest || props.disabled}
                                    className={classes.field}
                                    component={Switch}
                                />
                                <FieldError name="noLogTransaction" errors={state.errors} />
                            </Grid>
                            <Grid item xl={12} md={12} xs={12}>
                                <Field
                                    className={classes.field}
                                    name="alignmentAuthorityChecks"
                                    variant="outlined"
                                    label="Alignment between Authority Checks & USOBT_C"
                                    disabled={props.disabled}
                                    component={TextField}
                                />
                                <FieldError name="alignmentAuthorityChecks" errors={state.errors} />
                            </Grid>
                            <Grid item xl={12} md={12} xs={12}>
                                <Field
                                    multiline
                                    className={classes.field}
                                    name="securityTeamTransactionNotes"
                                    variant="outlined"
                                    label="CISO Office - Transaction Notes"
                                    disabled={props.disabled}
                                    component={TextField}
                                />
                                <FieldError name="securityTeamTransactionNotes" errors={state.errors} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xl={12} xs={12} md={12}>
                <Card>
                    <CardHeader title="Ciso information" />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xl={12} md={12} xs={12}>
                                <Field
                                    multiline
                                    className={classes.field}
                                    name="sodAnalysisFeedback"
                                    variant="outlined"
                                    label="SoD analysis feedback"
                                    disabled={props.disabled}
                                    component={TextField}
                                />
                                <FieldError name="sodAnalysisFeedback" errors={state.errors} />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    multiline
                                    type="text"
                                    className={classes.field}
                                    name="cisoInfoNotes"
                                    variant="outlined"
                                    helperText=""
                                    label="Notes"
                                    disabled={props.disabled}
                                    component={TextField}
                                />
                                <FieldError name="cisoInfoNotes" errors={state.errors} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

        </>
    );
}