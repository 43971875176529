import React, { useContext } from 'react';

import {
    Typography,
} from '@material-ui/core';

import { TransactionRequestContext } from '../TransactionRequestContext';

import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
    root: {
    }
}));

export const RequestHeader = (props) => {
    const classes = useStyles();

    const {
        state
    } = useContext(TransactionRequestContext);

    const {
        request
    } = state;

    return (
        <div className={classes.root}>
            <Typography component="h2" gutterBottom variant="overline">
                {request.transactionRequestId !== 0 ? `#NUMBER ${request.transactionRequestId}` : "DRAFT"}
            </Typography>
            <Typography variant="h4">
                {`${request.transactionName} ${request.reworkInProgress ? '(rework)' : ''}` || "new transaction request"}
            </Typography>
        </div>
    );
}
