import React, { useContext } from 'react';

import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Avatar,
    Typography,
    Button
} from '@material-ui/core';

import { SearchUser } from '../../../common';

import { TransactionRequestContext } from '../TransactionRequestContext';
import { TabsContext } from './TabsContext';
import { FetchContext, ApiRoutes, HttpMethods, UserContext } from '../../../contexts';
import { ApplicationRoles } from '../../../../helpers';

import PersonIcon from '@material-ui/icons/PersonOutlined'

import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: 20
    },
    user: {
        display: 'flex',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1)
    }
}));

export const OpenerSelector = (props) => {
    const classes = useStyles();
    const { fetchAuthorized } = useContext(FetchContext);

    const {
        userInfo,
        isUserInRole
    } = useContext(UserContext);

    const {
        state,
        resetRequestOwner
    } = useContext(TransactionRequestContext);

    const {
        values,
        isSubmitting,
        setFieldValue
    } = useContext(TabsContext);

    const loadUsers = (inputValue, callback) => {
        return fetchAuthorized(ApiRoutes.User.GetOpeners({ userFullPartialName: inputValue }),
            {
                method: HttpMethods.GET
            })
            .then(r => r.json())
            .then(users => callback(users))
            .catch(error => {
                console.error(error);
                callback([]);
            });
    }

    const userChangeHandler = (newUserInfo) => {
        const externalKey = newUserInfo ? newUserInfo.externalKey : null;
        const displayName = newUserInfo ? newUserInfo.displayName : null;
        resetRequestOwner(externalKey, displayName);

        setFieldValue("ownerKey", externalKey);
        setFieldValue("ownerFullName", displayName);

        const { onUserSelected } = props;
        if (onUserSelected) onUserSelected(userInfo);
    }

    return (
        <Card className={classes.root}>
            <CardHeader title="Transaction Opener" />
            <CardContent>
                {props.disabled || values.ownerKey ?

                    <>
                        <div className={classes.user}>
                            <Avatar aria-label="recipe" className={classes.avatar}>
                                <PersonIcon />
                            </Avatar>
                            <Typography>
                                {values.ownerFullName}
                            </Typography>
                        </div>
                    </>

                    :

                    <>
                        <Typography variant="subtitle1" gutterBottom>
                            Assign the request to this Transaction Opener
                        </Typography>
                        <SearchUser
                            loadOptions={loadUsers}
                            getOptionLabel={option => `${option.displayName} (${option.email || "no mail"})`}
                            getOptionValue={option => option.externalKey}
                            disabled={isSubmitting}
                            onChange={userChangeHandler} />
                    </>
                }
            </CardContent>
            {values.ownerKey && !props.disabled ?
                <CardActions>
                    <Button
                        color="primary"
                        size="small"
                        onClick={() => userChangeHandler(null)}>
                        Change
                    </Button>
                </CardActions>
                : isUserInRole(ApplicationRoles.Opener) && !props.disabled ?
                    <CardActions>
                        <Button
                            color="primary"
                            size="small"
                            onClick={() => userChangeHandler(userInfo)}>
                            Select myself
                    </Button>
                    </CardActions>
                    :
                    null
            }
        </Card>
    );
}