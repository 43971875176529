import React, { useEffect, useContext, useState } from 'react';

import { Grid } from '@material-ui/core';

import { HomeProvider, HomeContext } from './HomeContext';
import { NavContext } from '../../contexts';

import { 
    RequestsTable,
    ToDoList,
    RequestsCounter,
    AreaRequests,
    TypeRequests
} from './components'


const PageContent = (props) => {
    const { setBreadcrumbRoot } = useContext(NavContext);    
    
    const { 
        loadRequests,
        loadToDos,
        loadStats,
    } = useContext(HomeContext);

    useEffect(() => {
        setBreadcrumbRoot("My Desk");
        loadRequests();
        loadToDos();
        loadStats();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={8} xl={8}>
                <RequestsTable/>
            </Grid>
            <Grid item xs={12} md={4} xl={4}>
                <ToDoList/>
            </Grid>
            <Grid item xs={12} md={3} xl={3}>
                <RequestsCounter/>
            </Grid>
            <Grid item xs={12} md={3} xl={3}>
                <AreaRequests/>
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
                <TypeRequests/>
            </Grid>
        </Grid>
    );
}

export const HomePage = (props) => {    

    return (
        <HomeProvider>
            <PageContent />
        </HomeProvider>
    );
};