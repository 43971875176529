import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import _ from "lodash";

import {
    Typography
} from '@material-ui/core';

import AsyncSelect from 'react-select/async'

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    }
}));

export const Autocomplete = (props) => {
    const classes = useStyles();

    // We use lodash debounce to avoid gazillion of request
    // Remember to use the old fashion promise way because react select + debounce 
    // seems to work well only when using promises :(
    //
    // https://github.com/JedWatson/react-select/issues/3075
    //
    const loadOptions = _.debounce(props.loadOptions, 500);

    return (
        <React.Fragment>
            <Typography variant="overline">{props.label}</Typography>
            <AsyncSelect
                {...props}
                isClearable
                loadOptions={loadOptions} 
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}/>
        </React.Fragment>
    );
}