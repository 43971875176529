import React from 'react';

import { useAggregatedState } from '../../../../../helpers';

export const TechInfoContext = React.createContext(null);

const { Provider } = TechInfoContext;
export const TechInfoProvider = (props) => { 
    const [state, setState] = useAggregatedState({
    });

    return (
        <Provider value={{
            state
        }}>
            {props.children}
        </Provider>
    );
}