import React from 'react';

import {
    Grid
} from '@material-ui/core';


import { NewImportForm } from './NewImportForm';



import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: 20
    }
}));

export const NewImportTab = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <NewImportForm /*disabled={disabled} *//>
            </Grid>
        </div>
    );
}


