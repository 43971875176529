import React, { useContext } from 'react';
import {    Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { TransactionRequestContext } from '../TransactionRequestContext';
import { RequestStatus } from '../../../../helpers';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({    root: {    }}));

export const RequestAlerts = (props) => {
    const classes = useStyles();
    const {
        state,
        getTableOptionsWithCode,
    } = useContext(TransactionRequestContext);

    const notifyCommand = (command) => {
        const { onCommand } = props;
        if (onCommand) onCommand(command);
    }

    let alerts = null;
    const getStatusAlerts = () => {

        alerts = [];

        if (state.previousStatus && state.previousStatus.previousStatusNote && state.previousStatus.previousStatusNote !== "") {
            switch (state.previousStatus.previousStatus) {
                case RequestStatus.WaitingForCiso:
                    alerts.push(getInfoStatusAlert(
                        `Note from CISO Office: '${state.previousStatus.previousStatusNote}'`
                    ));
                    break;
                case RequestStatus.WaitingForBusiness:
                    if (state.request.status === RequestStatus.Rework) {
                        alerts.push(getWarningStatusAlert(
                            `Rejection note from Business Team: '${state.previousStatus.previousStatusNote}'`,
                            'scroll down for details'
                        ));
                    } else {
                        alerts.push(getInfoStatusAlert(
                            `Note from Business Team: '${state.previousStatus.previousStatusNote}'`
                        ));
                    }
                    break;
                case RequestStatus.WaitingForApproval:
                    alerts.push(getInfoStatusAlert(
                        `Note from SoD Activity Rework: '${state.previousStatus.previousStatusNote}'`
                    ));
                    break;
                default:
                    alerts.push(getInfoStatusAlert(
                        `Note from previous step: '${state.previousStatus.previousStatusNote}'`
                    ));
                    break;
            }
        }

        switch (state.request.status) {
            default:
                break;
        }

        return alerts;
    }

    const getInfoStatusAlert = (message) => {
        const key = `alert-${alerts.length + 1}`;
        return <Alert key={key} severity="info">{message}</Alert>;
    }

    const getWaitStatusAlert = () => {
        const key = `alert-${alerts.length + 1}`;
        return <Alert key={key} severity="info">Request has been sent — wait others till completion</Alert>;
    }

    const getSendStatusAlert = () => {
        const key = `alert-${alerts.length + 1}`;
        return <Alert key={key} severity="success">Request has been compiled correctly — send it for approval</Alert>;
    }

    const getWarningStatusAlert = (message, hint) => {
        return <Alert key="warning" severity="warning">{message} — {hint}</Alert>;
    }

    const getErrorStatusAlert = (message, hint) => {
        const key = `alert-${alerts.length + 1}`;
        return <Alert key={key} severity="error">{message} — {hint}</Alert>;
    }

    const getCompleteStatusAlert = (message, actionText, action) => {
        const key = `alert-${alerts.length + 1}`;
        return (
            <Alert key={key} severity="warning" action={
                <Button color="inherit" size="small" onClick={action}>
                    {actionText}
                </Button>
            }>
                {message}
            </Alert>
        );
    }

    return (
        <div className={classes.root}>
            {getStatusAlerts()}
        </div>
    );
}
