import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { TechInfoForm } from './TechInfoForm';
import { UserContext } from '../../../../contexts';
import { TransactionRequestContext } from '../../TransactionRequestContext';
import { TabsContext } from '../TabsContext';
import { ApplicationRoles, RequestStatus } from '../../../../../helpers';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: 20
    }
}));

export const TechInfoTab = (props) => {
    const classes = useStyles();

    const {
        userInfo,
        isUserInRole,
    } = useContext(UserContext);

    const {
        state,
        isEditMode
    } = useContext(TransactionRequestContext);

    const {
        values,
        isSubmitting
    } = useContext(TabsContext);

    const [disabled, setDisabled] = useState(false);
    useEffect(() => {
        const canOpenRequest = (state.request.status === RequestStatus.Draft
            && userInfo.externalKey === state.request.ownerKey
            && isUserInRole(ApplicationRoles.Opener));

        const canReworkRequest = (state.request.status === RequestStatus.Rework
            && userInfo.externalKey === state.request.ownerKey
            && isUserInRole(ApplicationRoles.Opener));

        const canApproveRequest = (state.request.status === RequestStatus.WaitingForApproval
            && state.request.transactionRequestId !== 0
            && isUserInRole(ApplicationRoles.Administrator));

        const canEditApprovedRequest = (isUserInRole(ApplicationRoles.Opener) || isUserInRole(ApplicationRoles.Administrator))
            && state.request.status === RequestStatus.Approved
            && isEditMode;

        setDisabled(
            props.disabled
            || isSubmitting
            || !(
                canOpenRequest
                || canReworkRequest
                || canApproveRequest
                || canEditApprovedRequest
            )
        );
    }, [isSubmitting, state.request.status, isEditMode, userInfo.roles])

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <TechInfoForm disabled={disabled} />
            </Grid>
        </div>
    );
}


