import React, { useContext, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
    Paper,
    Avatar,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Chip,
    Button,
    Link,
    Hidden,
    Grid,
    IconButton
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete'

import { RolesContext } from '../RolesContext';
import { NavContext, NavRoutes } from '../../../../contexts';
import { ApplicationRoles } from '../../../../../helpers';

import PersonIcon from '@material-ui/icons/PersonOutlined'
import CloudIcon from '@material-ui/icons/CloudOutlined';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        overflow: 'hidden',
        marginTop: theme.spacing(4)
    },
    user: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(1)
    },
    table: {

    },
    chip: {
        //padding: '0px',  
        margin: '2px'
    }
}));

const LinkRouter = props => <Link {...props} component={RouterLink} />;

export const UserList = (props) => {
    const classes = useStyles();
    const { getRouteTo } = useContext(NavContext)

    const {
        state,
        isRemovingFromRole,
        removeUserFromRole
    } = useContext(RolesContext);

    const removeUserClickHanlder = async (user) => {
        await removeUserFromRole(user.externalKey, state.role.applicationRoleId);
    }

    return (
        <Paper className={classes.root}>
            <Hidden xsDown>
                <Table className={classes.table} arial-label="roles table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Full Name</TableCell>
                            {/*<TableCell>Email</TableCell>*/}
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.role && state.role.members && state.role.members.map((user, index) =>
                            <TableRow hover key={index}>
                                <TableCell component="th" scope="row">
                                    <div className={classes.user}>
                                        <Avatar aria-label="recipe" className={classes.avatar}>
                                            <PersonIcon />
                                        </Avatar>
                                        {state.role.groupAADReference === null ?
                                            <Typography>
                                                <LinkRouter key={index} color="textSecondary" to={getRouteTo(NavRoutes.Admin.EditUserInRole, [state.role.applicationRoleId, user.externalKey])}>
                                                    {user.displayName}
                                                </LinkRouter>
                                            </Typography>
                                            :
                                            <Typography color="textSecondary">
                                                {user.displayName}
                                            </Typography>
                                        }
                                    </div>
                                </TableCell>
                                {/*<TableCell>
                                    <Typography>
                                        {user.email || "(no mail)"}
                                    </Typography>
                                </TableCell>*/}
                                <TableCell align="right">
                                    {state.role.groupAADReference === null ?
                                        <Button
                                            color="secondary"
                                            size="small"
                                            variant="contained"
                                            disabled={isRemovingFromRole}
                                            onClick={() => removeUserClickHanlder(user)}>
                                            Remove
                                    </Button>
                                        :
                                        <Chip
                                            icon={<CloudIcon />}
                                            label="Active Directory"
                                            variant="outlined" />
                                    }
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Hidden>
            <Hidden smUp>
                <Table className={classes.table} arial-label="roles table">
                    <TableHead>
                        <TableRow>
                            {state.role.groupAADReference === null ? <TableCell align="right"></TableCell> : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.role && state.role.members && state.role.members.map((user, index) =>
                            <TableRow hover key={index}>
                                <TableCell component="th" scope="row">
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container justify="space-between">
                                                <Grid item>
                                                    {state.role.groupAADReference === null ?
                                                        <Typography>
                                                            <LinkRouter key={index} color="textSecondary" to={getRouteTo(NavRoutes.Admin.EditUserInRole, [state.role.applicationRoleId, user.externalKey])}>
                                                                {user.displayName}
                                                            </LinkRouter>
                                                        </Typography>
                                                        :
                                                        <Typography color="textSecondary">
                                                            {user.displayName}
                                                        </Typography>
                                                    }
                                                </Grid>
                                                {state.role.groupAADReference === null ?
                                                    null
                                                    :
                                                    <Grid item>
                                                        <CloudIcon />
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="caption">
                                                {/*user.email || "(no mail)"*/}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                {state.role.groupAADReference === null ?
                                    <TableCell align="right">
                                        <IconButton
                                            color="secondary"
                                            size="small"
                                            variant="contained"
                                            disabled={isRemovingFromRole}
                                            onClick={() => removeUserClickHanlder(user)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                    :
                                    null
                                }
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Hidden>
        </Paper>
    );
}