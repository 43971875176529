import React from 'react';
import { Grid } from '@material-ui/core';
import { SodActivityInfoForm } from './SodActivityInfoForm'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: 20
    }
}));

export const SodActivityInfoTab = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <SodActivityInfoForm disabled={true} />
            </Grid>
        </div>
    );
}
