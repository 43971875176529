import { colors, Container, Divider, Grid, Tab, Tabs } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ImportContext } from '../ImportContext';
import { TabsProvider } from './TabsContext';
import { ImportAuditTab } from './importAudit';
import { NewImportTab } from './newImport';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
    return (
        {
            root: {

            },
            tabs: {
                [theme.breakpoints.down('md')]: {
                    width: window.innerWidth - 48
                },
                [theme.breakpoints.down('xs')]: {
                    width: window.innerWidth - 16
                },
            },
            divider: {
                backgroundColor: colors.grey[300]
            },
            alert: {
                marginTop: 3
            },
            content: {
                marginTop: 3
            },
            appBar: {
                top: 'auto',
                bottom: 0,
            },
            cancelButton: {
                marginBottom: 10
            },
            overlay: {
                zIndex: 1
            },
            progress: {
                color: "black"
            },
            tabAsterisk: {
                color: "#ff0000"
            },
            saveButtonMobile: {
                paddingBottom: 50
            }
        }
    )
});

export const TabsContainer = ({ editMode }) => {
    const classes = useStyles();
    const formRef = useRef();
    const readOnly = !editMode;

    const {
        state,
        setActualImport
    } = useContext(ImportContext);

    const {
        errors
    } = state;

    const importAudit = "importAudit";
    const newImport = "newImport";


    const initialTab = importAudit;

    const [currentTab, setCurrentTab] = useState(initialTab);
    const [isNoteOpen, setIsNoteOpen] = useState(false);

    const allTabs = [
        { value: importAudit, label: "Import Audit" },
        { value: newImport, label: "New Import" }
    ];


    const getTabs = () => {
        return allTabs.map((tab, index) => {

            tab.asterisk = false;

            return (
                <Tab
                    key={tab.value}
                    label={
                        <div>
                            {tab.label}
                            {
                                tab.asterisk &&
                                <span className={classes.tabAsterisk}> *</span>
                            }
                        </div>
                    }
                    value={tab.value}
                    {...a11yProps(index)}
                />
            );
        })
    }

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const changeTab = (value) => {
        setCurrentTab(value)
        if (value == 'newImport')
            setActualImport(null);
    }

    const a11yProps = (index) => {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    useEffect(() => {
        //  clearError("Note")
    }, [isNoteOpen]);

    return (
        <>
            <Grid className={classes.header} container justify="space-between">

                <Grid item xs={12} xl={12} md={12}>
                    <Tabs
                        className={classes.tabs}
                        onChange={(event, value) => changeTab(value)}
                        value={currentTab}
                        variant="scrollable">
                        {getTabs()}
                    </Tabs>
                    <Divider className={classes.divider} />
                </Grid>
            </Grid>
            <Container style={{ marginBottom: /*hasActionButtons &&  */isMobile ? 60 : null }}>
                <Formik
                    // initialValues={createInitialValues(state.request)}
                    innerRef={formRef}
                //onSubmit={submitClickHandler}
                >
                    {(props) => {
                        return (
                            <Form noValidate autoComplete="off">
                                <TabsProvider {...props}>
                                    {/*<NoteForm*/}
                                    {/*    disabled={readOnly || isSavingRequest}*/}
                                    {/*    isNoteObligatory={isNoteObligatory}*/}
                                    {/*    isNoteOpen={isNoteOpen}*/}
                                    {/*    setIsNoteOpen={(arg) => setIsNoteOpen(arg)}*/}
                                    {/*    submitForm={props.submitForm}*/}
                                    {/*    saveNoteButton={saveNoteButton}*/}
                                    {/* /> */}
                                    {/*<RequestAlerts onCommand={alertCommandHandler} />*/}
                                    {currentTab === importAudit &&
                                        <ImportAuditTab disabled={readOnly /*|| isSavingRequest*/} />
                                    }
                                    {currentTab === newImport &&
                                        <NewImportTab disabled={readOnly /*|| isSavingRequest*/} />
                                    }
                                </TabsProvider>
                            </Form>
                        );
                    }}
                </Formik>
            </Container>
        </>
    );
}