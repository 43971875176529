import React from 'react';
import { useAggregatedState } from '../../../../../helpers';

export const CisoInfoContext = React.createContext(null);

const { Provider } = CisoInfoContext;
export const CisoInfoProvider = (props) => {
    const [state, setState] = useAggregatedState({
    });

    return (
        <Provider value={{
            state
        }}>
            {props.children}
        </Provider>
    );
}