import React, { useContext, useState, useEffect } from 'react';
import _ from "lodash";

import { 
    Typography
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
    root: {        
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1)
    }
}));

export const FieldError = ({ name, errors, ...props}) => {
    const classes = useStyles(); 

    const [isVisible, setIsVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    useEffect(() => {
        if(errors
            && errors.length > 0
            && name
            && name.length > 0) {            
                    
            for(let i = 0; i < errors.length; i++) {
                
                const error = errors[i];
                let errorId = error.errorId;
                if(errorId.includes(".")) {
                    errorId = errorId.split(".").map(x => _.camelCase(x)).join(".");
                } else {
                    errorId = _.camelCase(errorId);
                }

                let fieldName = name;
                if(fieldName.includes(".")) {
                    fieldName = fieldName.split(".").map(x => _.camelCase(x)).join(".");
                } else {
                    fieldName = _.camelCase(name);
                }
                
                if(errorId === fieldName)
                {
                    setIsVisible(true);
                    setErrorMessage(error.message);
                    return;
                }
            }

            // No error to show
            setIsVisible(false);
            setErrorMessage(null);
            
        } else {
            // No error to show
            setIsVisible(false);
            setErrorMessage(null);
        }
    }, [errors, name])

    return (
        <>
            {isVisible &&
                <Typography variant="caption" color="secondary" component="div" gutterBottom className={classes.root}>
                    {errorMessage}
                </Typography>
            }
        </>
    );
}
