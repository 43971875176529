import React from 'react';
import { AuthPlatformTableNames } from '../../../../../../helpers';
import { Typography, Select, MenuItem, Chip, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import _ from 'lodash';
import { ApiRoutes, FetchContext, HttpMethods, InsightContext } from '../../../../../contexts';

export const getCompensatoryControlColumns = (tableValuesOptions, classes, complexOptions, deUglify) => {
    return [
        {
            title: "Code*",
            field: "code",
            filtering: false,
            editable: "always",
            width: "20%",
            customFilterAndSearch: (value, rowData) => {
                return true;
            },
        },
        {
            title: "Name*",
            field: "name",
            filtering: false,
            editable: "always",
            width: "25%"
        },
        {
            title: "Pred/Detect*",
            field: "predictiveDetective",
            filtering: false,
            editable: "always",
            width: "25%"
        },
        {
            title: "Specific / Monitoring *",
            field: "specificMonitoring",
            filtering: false,
            editable: "always",
            width: "25%"
        },
        {
            title: "Category",
            field: "category",
            filtering: false,
            editable: "always",
            width: "25%"
        },
        {
            title: "Man/Auto/Semi*",
            field: "manualAutomaticSemiautomatic",
            filtering: false,
            editable: "always",
            width: "25%"
        },
        {
            title: "Description*",
            field: "description",
            filtering: false,
            editable: "always",
            width: "80%",
            render: (rowData) => deUglify(rowData.description)
        },
        {
            title: "Min Periodicity",
            field: "minPeriodicity",
            filtering: false,
            editable: "always",
            width: "25%"
        },
        {
            title: "SoD Activities*",
            field: "sodActivities",
            filtering: false,
            searchable: true,
            customFilterAndSearch: (value, rowData) => {
                return true;
            },
            initialEditValue: [],
            hidden: false,
            width: "30%",
            render: (rowData) => {
                const selectedOptions = complexOptions.sodActivity.filter(x => rowData.sodActivities.includes(x.key));

                return (
                    <>
                        {selectedOptions ?
                            <div className={classes.chips}>
                                {selectedOptions.map((option, index) => {
                                    return (
                                        <Chip
                                            key={index}
                                            label={option.value}
                                            className={classes.chip} />
                                    );
                                })}
                            </div>
                            :
                            <Typography>
                                &nbsp;
                            </Typography>}
                    </>
                );
            },
            editComponent: (props) => {
                const data = complexOptions.sodActivity;

                return <>
                    {(data && data.length > 0) ?
                        (<Select
                            className={classes.select}
                            value={props.value || props.columnDef.initialEditValue}
                            multiple={Array.isArray(props.columnDef.initialEditValue)}
                            renderValue={selected => {
                                if (!Array.isArray(selected))
                                    selected = [selected];
                                return (
                                    <div className={classes.chips}>
                                        {selected.map((selectedKey, index) => {
                                            const item = data.find(x => x.key === selectedKey);
                                            return (item ? <Chip key={index} label={item.value} className={classes.chip} /> : null);
                                        })}
                                    </div>
                                );
                            }}
                            onChange={(e) => {
                                props.onChange(e.target.value);
                            }}>
                            {data && data.length > 0 && data.map((item, index) => (
                                <MenuItem key={index} value={item.key}>{item.value}</MenuItem>
                            ))}
                        </Select>)
                        :
                        <Typography>
                            No values available
                        </Typography>}
                </>;
            }
        },
        {
            title: "SoD Risks*",
            field: "risks",
            filtering: false,
            searchable: true,
            customFilterAndSearch: (value, rowData) => {
                return true;
            },
            initialEditValue: [],
            hidden: false,
            width: "30%",
            render: (rowData) => {
                const selectedOptions = complexOptions.risk.filter(x => rowData.risks.includes(x.key));

                return (
                    <>
                        {selectedOptions ?
                            <div className={classes.chips}>
                                {selectedOptions.map((option, index) => {
                                    return (
                                        <Chip
                                            key={index}
                                            label={option.value}
                                            className={classes.chip} />
                                    );
                                })}
                            </div>
                            :
                            <Typography>
                                p;
                            </Typography>}
                    </>
                );
            },
            editComponent: (props) => {
                const data = complexOptions.risk;

                return <>
                    {(data && data.length > 0) ?
                        (<Select
                            className={classes.select}
                            value={props.value || props.columnDef.initialEditValue}
                            multiple={Array.isArray(props.columnDef.initialEditValue)}
                            renderValue={selected => {
                                if (!Array.isArray(selected))
                                    selected = [selected];
                                return (
                                    <div className={classes.chips}>
                                        {selected.map((selectedKey, index) => {
                                            const item = data.find(x => x.key === selectedKey);
                                            return (item ? <Chip key={index} label={item.value} className={classes.chip} /> : null);
                                        })}
                                    </div>
                                );
                            }}
                            onChange={(e) => {
                                props.onChange(e.target.value);
                            }}>
                            {data && data.length > 0 && data.map((item, index) => (
                                <MenuItem key={index} value={item.key}>{item.value}</MenuItem>
                            ))}
                        </Select>)
                        :
                        <Typography>
                            No values available
                        </Typography>}
                </>;
            }
        },
        {
            title: "ERICA Code",
            field: "ericaCode",
            filtering: false,
            editable: "always",
            width: "25%"
        },
        {
            title: "Details",
            field: "details",
            filtering: false,
            editable: "always",
            width: "80%",
            render: (rowData) => deUglify(rowData.details)
        }
    ];
}

export const getCompanyColumns = (classes, deUglify) => {
    return [
        {
            title: "Company Code *",
            field: "code",
            filtering: false,
            editable: "always",
            width: "20%",
            customFilterAndSearch: (value, rowData) => {
                return true;
            },
        },
        {
            title: "Company Name *",
            field: "description",
            filtering: false,
            editable: "always",
            width: "30%",
            render: (rowData) => deUglify(rowData.description)
        },
        {
            title: "Country *",
            field: "country",
            filtering: true,
            editable: "always",
            width: "20%",
            render: (rowData) => deUglify(rowData.country)
        },
        {
            title: "Finance Risk Owner",
            field: "financeRiskOwnerKey",
            filtering: true,
            editable: "always",
            width: "30%",
            render: (rowData) => {
                if (rowData.financeRiskOwnerKey)
                    return `${rowData.financeRiskOwnerDisplayName} - ${rowData.financeRiskOwnerEmail || "no email"}`
            },
            editComponent: (props) => {
                const initialValue = props.rowData.financeRiskOwnerKey && {
                    id: props.rowData.financeRiskOwnerKey,
                    displayName: props.rowData.financeRiskOwnerDisplayName,
                    mail: props.rowData.financeRiskOwnerEmail,
                }
                return <UserSelector
                    initialValue={initialValue}
                    onChangeHandler={(newUser) => props.onChange(newUser?.id)} />;
            }
        }];
}

export const getRiskColumns = (tableValuesOptions, classes, complexOptions, deUglify) => {
    return [
        {
            title: "SoD Risk Code *",
            field: "code",
            filtering: false,
            editable: "always",
            width: "20%",
            customFilterAndSearch: (value, rowData) => {
                return true;
            },
        },
        {
            // multiple choice table value
            title: "Areas *",
            field: "areas",
            filtering: false,
            editable: "always",
            initialEditValue: [],
            width: "20%",
            render: (rowData) => {
                const selectedOptions = rowData.areas
                    && tableValuesOptions
                    && tableValuesOptions.length > 0
                    ? tableValuesOptions.filter(x => rowData.areas.includes(x.tableValueId)) : null;
                return (
                    <>
                        {selectedOptions ?
                            <div className={classes.chips}>
                                {selectedOptions.map((option, index) => {
                                    return (
                                        <Chip
                                            key={index}
                                            label={option.value}
                                            className={classes.chip} />
                                    );
                                })}
                            </div>
                            :
                            <Typography>
                                &nbsp;
                            </Typography>}
                    </>
                );
            },
            editComponent: (props) => {
                const data = tableValuesOptions.filter(x => x.tableDefinitionName === AuthPlatformTableNames.Area);

                return <>
                    {(data && data.length > 0) ?
                        (<Select
                            className={classes.select}
                            value={props.value || props.columnDef.initialEditValue}
                            multiple={Array.isArray(props.columnDef.initialEditValue)}
                            renderValue={selected => {
                                if (!Array.isArray(selected))
                                    selected = [selected];
                                return (
                                    <div className={classes.chips}>
                                        {selected.map((selectedId, index) => {
                                            const item = data.find(x => x.tableValueId === selectedId);
                                            return (item ? <Chip key={index} label={item.value} className={classes.chip} /> : null);
                                        })}
                                    </div>
                                );
                            }}
                            onChange={(e) => {
                                props.onChange(e.target.value);
                            }}>
                            {data && data.length > 0 && data.map((item, index) => (
                                <MenuItem key={index} value={item.tableValueId}>{item.value}</MenuItem>
                            ))}
                        </Select>)
                        :
                        <Typography>
                            No values available
                        </Typography>}
                </>;
            }
        },
        {
            title: "SoD Activity 1 *",
            field: "firstSodActivity",
            filtering: false,
            searchable: true,
            customFilterAndSearch: (value, rowData) => {
                return true;
            },
            initialEditValue: "",
            hidden: false,
            width: "30%",
            render: (rowData) => {
                const selected = complexOptions.sodActivity.find(x => x.key === rowData.firstSodActivity);

                return (
                    <>
                        {selected ?
                            <div className={classes.chips}>
                                {
                                    <Chip
                                        key={selected.key}
                                        label={selected.value}
                                        className={classes.chip} />
                                }
                            </div>
                            :
                            <Typography>
                                &nbsp;
                            </Typography>
                        }
                    </>
                );
            },
            editComponent: (props) => {
                const data = complexOptions.sodActivity;

                return <>
                    {(data && data.length > 0) ?
                        (<Select
                            className={classes.select}
                            value={props.value || props.columnDef.initialEditValue}
                            multiple={Array.isArray(props.columnDef.initialEditValue)}
                            renderValue={selected => {
                                if (!Array.isArray(selected)) selected = [selected];
                                return (
                                    <div className={classes.chips}>
                                        {selected.map((selectedKey, index) => {
                                            const item = data.find(x => x.key === selectedKey);
                                            return (item ? <Chip key={index} label={item.value} className={classes.chip} /> : null);
                                        })}
                                    </div>
                                )
                            }}
                            onChange={(e) => {
                                props.onChange(e.target.value);
                            }}>
                            {data && data.length > 0 && data.map((item, index) => (
                                <MenuItem key={index} value={item.key}>{item.value}</MenuItem>
                            ))}
                        </Select>)
                        :
                        <Typography>
                            No values available
                        </Typography>
                    }
                </>
            }
        },
        {
            title: "SoD Activity 2 *",
            field: "secondSodActivity",
            filtering: false,
            searchable: true,
            customFilterAndSearch: (value, rowData) => {
                return true;
            },
            initialEditValue: "",
            hidden: false,
            width: "30%",
            render: (rowData) => {
                const selected = complexOptions.sodActivity.find(x => x.key === rowData.secondSodActivity);

                return (
                    <>
                        {selected ?
                            <div className={classes.chips}>
                                {
                                    <Chip
                                        key={selected.key}
                                        label={selected.value}
                                        className={classes.chip} />
                                }
                            </div>
                            :
                            <Typography>
                                &nbsp;
                            </Typography>
                        }
                    </>
                );
            },
            editComponent: (props) => {
                const data = complexOptions.sodActivity;

                return <>
                    {(data && data.length > 0) ?
                        (<Select
                            className={classes.select}
                            value={props.value || props.columnDef.initialEditValue}
                            multiple={Array.isArray(props.columnDef.initialEditValue)}
                            renderValue={selected => {
                                if (!Array.isArray(selected)) selected = [selected];
                                return (
                                    <div className={classes.chips}>
                                        {selected.map((selectedKey, index) => {
                                            const item = data.find(x => x.key === selectedKey);
                                            return (item ? <Chip key={index} label={item.value} className={classes.chip} /> : null);
                                        })}
                                    </div>
                                )
                            }}
                            onChange={(e) => {
                                props.onChange(e.target.value);
                            }}>
                            {data && data.length > 0 && data.map((item, index) => (
                                <MenuItem key={index} value={item.key}>{item.value}</MenuItem>
                            ))}
                        </Select>)
                        :
                        <Typography>
                            No values available
                        </Typography>
                    }
                </>
            }
        },
        {
            title: "Impact *",
            field: "impact",
            filtering: false,
            initialEditValue: "",
            width: "30%",
            editComponent: (props) => {
                const data = ["Single", "Cross"];
                return <>
                    {(data && data.length > 0) ?
                        (<Select
                            className={classes.select}
                            value={props.value || props.columnDef.initialEditValue}
                            onChange={(e) => {
                                props.onChange(e.target.value);
                            }}>
                            {data && data.length > 0 && data.map((item, index) => (
                                <MenuItem key={index} value={item}>{item}</MenuItem>
                            ))}
                        </Select>)
                        :
                        <Typography>
                            No values available
                        </Typography>
                    }
                </>
            }
        },
        {
            title: "Impact Application *",
            field: "impactApplication",
            filtering: false,
            initialEditValue: "",
            width: "30%",
            editComponent: (props) => {
                const data = ["Single", "Cross", "ExtraApp"];
                return <>
                    {(data && data.length > 0) ?
                        (<Select
                            className={classes.select}
                            value={props.value || props.columnDef.initialEditValue}
                            onChange={(e) => {
                                props.onChange(e.target.value);
                            }}>
                            {data && data.length > 0 && data.map((item, index) => (
                                <MenuItem key={index} value={item}>{item}</MenuItem>
                            ))}
                        </Select>)
                        :
                        <Typography>
                            No values available
                        </Typography>
                    }
                </>
            }
        },
        {
            title: "Priority *",
            field: "criticality",
            filtering: false,
            initialEditValue: "",
            width: "30%",
            editComponent: (props) => {
                const data = ["Low", "Medium", "High"];
                return <>
                    {(data && data.length > 0) ?
                        (<Select
                            className={classes.select}
                            value={props.value || props.columnDef.initialEditValue}
                            onChange={(e) => {
                                props.onChange(e.target.value);
                            }}>
                            {data && data.length > 0 && data.map((item, index) => (
                                <MenuItem key={index} value={item}>{item}</MenuItem>
                            ))}
                        </Select>)
                        :
                        <Typography>
                            No values available
                        </Typography>
                    }
                </>
            }
        },
        {
            title: "SoD Risk Description *",
            field: "description",
            filtering: false,
            editable: "always",
            width: "80%",
            render: (rowData) => deUglify(rowData.description)
        },
        {
            title: "Grc Risk Id",
            field: "grcRiskId",
            filtering: false,
            editable: "always",
            width: "80%"
        },
        {
            title: "Italian Description",
            field: "italianDescription",
            filtering: false,
            editable: "always",
            width: "80%",
            render: (rowData) => deUglify(rowData.italianDescription)
        }
    ];
}

export const getSodActivityColumns = (tableValuesOptions, classes, complexOptions, deUglify) => {
    return [
        {
            title: "SoD Activity Code *",
            field: "code",
            filtering: false,
            editable: "always",
            width: "20%",
            customFilterAndSearch: (value, rowData) => {
                return true;
            },
        },
        {
            // single choice table value
            title: "Area *",
            field: "area",
            filtering: false,
            initialEditValue: "",
            editable: "always",
            width: "20%",
            render: (rowData) => {
                const selected = rowData.area;
                const selectedOption = selected
                    && tableValuesOptions
                    && tableValuesOptions.length > 0
                    ? tableValuesOptions.find(x => x.tableValueId === selected) : null;
                return (
                    <>
                        {selectedOption ?
                            <div className={classes.chips}>
                                {
                                    <Chip
                                        key={selectedOption.tableValueId}
                                        label={selectedOption.value}
                                        className={classes.chip} />
                                }
                            </div>
                            :
                            <Typography>
                                &nbsp;
                            </Typography>
                        }
                    </>
                );
            },
            editComponent: (props) => {
                const data = tableValuesOptions.filter(x => x.tableDefinitionName === AuthPlatformTableNames.Area);

                return <>
                    {(data && data.length > 0) ?
                        (<Select
                            className={classes.select}
                            value={props.value || props.columnDef.initialEditValue}
                            multiple={Array.isArray(props.columnDef.initialEditValue)}
                            renderValue={selected => {
                                if (!Array.isArray(selected)) selected = [selected];
                                return (
                                    <div className={classes.chips}>
                                        {selected.map((selectedId, index) => {
                                            const item = data.find(x => x.tableValueId === selectedId);
                                            return (item ? <Chip key={index} label={item.value} className={classes.chip} /> : null);
                                        })}
                                    </div>
                                )
                            }}
                            onChange={(e) => {
                                props.onChange(e.target.value);
                            }}>
                            {data && data.length > 0 && data.map((item, index) => (
                                <MenuItem key={index} value={item.tableValueId}>{item.value}</MenuItem>
                            ))}
                        </Select>)
                        :
                        <Typography>
                            No values available
                        </Typography>
                    }
                </>
            }
        },
        {
            title: "SoD Activity Description *",
            field: "description",
            filtering: false,
            editable: "always",
            width: "80%",
            render: (rowData) => deUglify(rowData.description)
        },
        {
            title: "Italian Description *",
            field: "italianDescription",
            filtering: false,
            editable: "always",
            width: "25%",
            render: (rowData) => deUglify(rowData.italianDescription)
        },
        {
            title: "System *",
            field: "sys",
            filtering: false,
            editable: "always",
            width: "25%",
            render: (rowData) => deUglify(rowData.sys)
        },
        {
            title: "Grc Risk Id *",
            field: "grcRiskId",
            filtering: false,
            editable: "always",
            width: "25%"
        },
        {
            title: "Note",
            field: "note",
            filtering: false,
            editable: "always",
            width: "80%",
            render: (rowData) => deUglify(rowData.note)
        }
    ];
}

const UserSelector = ({ initialValue, onChangeHandler }) => {
    const { fetchAuthorized } = React.useContext(FetchContext);
    const { trackError } = React.useContext(InsightContext);

    const [users, setUsers] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    const fetchUserOptions = (inputValue) => {
        setIsLoading(true);
        return fetchAuthorized(ApiRoutes.Graph.GetUsers({ userFullPartialName: inputValue }),
            {
                method: HttpMethods.GET
            })
            .then(r => {
                const items = r.json();
                setIsLoading(false);
                return items;
            })
            .catch(error => {
                trackError(error);
                setIsLoading(false);
            });
    }

    React.useEffect(() => {
        async function loadInitialData() {
            setIsLoading(true);
            const items = await fetchUserOptions();
            setUsers(items);
            setIsLoading(false);
        }
        loadInitialData();
    }, [])

    const handleOnChangeText = async (event, searchText) => {
        // avoid firing search request when rendering initial value
        // and when an option is selected
        if (event && !Object.hasOwn(event, 'pageX')) {
            event.persist();
            setIsLoading(true);
            const items = await fetchUserOptions(searchText);
            setUsers(items);
            setIsLoading(false);
        }
    }

    return <Autocomplete
        options={users}
        disableCloseOnSelect
        loading={isLoading}
        onChange={(_, item) => onChangeHandler(item)}
        onInputChange={_.debounce(handleOnChangeText, 600)}
        defaultValue={initialValue}
        getOptionLabel={(option) => {
            return `${option.displayName} - ${option.mail || "no email"}`;
        }}
        renderOption={(option) => {
            return `${option.displayName} - ${option.mail || "no email"}`;
        }}
        renderInput={(params) => (
            <TextField {...params}
                helperText="Search by name or email..." />
        )} />;
}
