import React, { useEffect, useContext } from 'react';
import { useParams } from "react-router";

import {
    Grid,
    LinearProgress,    
} from '@material-ui/core';

import { HistoryProvider, HistoryContext } from './HistoryContext';
import { NavContext } from '../../contexts';

import {     
    RequestHeader,
    RequestHistory
} from './components';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
    root: {
    }
});

const PageContent = (props) => {
    const classes = useStyles();
    const { setBreadcrumbRoot } = useContext(NavContext);

    const {
        state,
        isLoadingHistory,
        loadHistory    
    } = useContext(HistoryContext);

    const { requestId } = useParams()    
    useEffect(() => {
        setBreadcrumbRoot("Request History")
        if(requestId) loadHistory(requestId);        
    }, []);

    return (
        <>
            {isLoadingHistory &&
                <LinearProgress color="primary" />
            }   
            {state.history && !isLoadingHistory &&
                <Grid container justify="space-between">
                    <Grid item>
                        <RequestHeader />
                    </Grid>
                    <Grid item xl={12} md={12} xs={12}>
                        <RequestHistory />
                    </Grid>
                </Grid>                                             
            } 
        </>       
    );
}

export const HistoryPage = (props) => {

    return (
        <HistoryProvider>
            <PageContent />
        </HistoryProvider>
    );
}