import React, { useEffect, useContext } from 'react';

import { DashboardProvider } from './DashboardContext';
import { NavContext } from '../../../contexts';

import { 
    BlockMenu 
} from './components';

const PageContent = (props) => {
    const { setBreadcrumbRoot } = useContext(NavContext);
    
    useEffect(() => {
        setBreadcrumbRoot("Admin");
    }, []);

    return (
        <BlockMenu />
    );
}

export const DashboardPage = (props) => {

    return (        
        <DashboardProvider>
            <PageContent />
        </DashboardProvider>        
    );
}