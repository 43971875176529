import { Button, CircularProgress, colors, IconButton, Link, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PublishIcon from '@material-ui/icons/Publish';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ApiRoutes, FetchContext, HttpMethods, ToastContext } from '../contexts';
import { ImportContext } from '../pages/import/ImportContext';
const useStyles = makeStyles((theme) => ({
    root: {

    },
    dropZone: {
        border: "1px",
        padding: "8px",
        outline: "none",
        display: "flex",
        flexWrap: "wrap",
        backgroundColor: "#e1e1e1",
        opacity: 1,
        "&:hover": {
            backgroundColor: "#f1f1f1",
            opacity: 1,
            cursor: "pointer"
        }
    },
    dragActive: {
        backgroundColor: colors.grey[50],
        opacity: 0.5
    },
    image: {
        width: 130
    },
    info: {
        marginTop: 1
    },
    list: {
        maxHeight: 320
    },
    actions: {
        marginTop: 2,
        display: "flex",
        justifyContent: "flex-end",
        "& > * + *": {
            marginLeft: 2
        }
    },
    removeButton: {
        marginRight: 10
    }
}));


export const InputFiles = (props) => {
    const classes = useStyles();
    const { fetchAuthorized } = useContext(FetchContext);
    const { showSuccessMessage } = useContext(ToastContext);
    const { setActualImport } = useContext(ImportContext);

    const [files, setFiles] = useState([]);

    const handleDrop = useCallback((acceptedFiles) => {
        if (props.isMassiveImport === true) {
            setFiles(acceptedFiles);
        } else {
            setFiles((prevFiles) => [...prevFiles].concat(acceptedFiles));
        }
    }, []);

    const removeFileClickHandler = (index) => {
        const newFiles = [].concat(files)
        newFiles.splice(index, 1)
        setFiles(newFiles)
    }

    const removeAllFilesClickHandler = () => {
        setFiles([])
    }

    const [isUploading, setIsUploading] = useState(false);
    const uploadAllFilesClickHandler = async () => {

        setIsUploading(true);
        for (const file of files) {
            try {
                const data = new FormData();
                data.append("file", file);
                const r = await fetchAuthorized(ApiRoutes.File.Upload(),
                    {
                        method: HttpMethods.POST,
                        body: data
                    });

                const uploadedFile = await r.json();
                setFiles((prevFiles) => [...prevFiles].filter(x => x.name !== file.name));

                notifyFileUploaded(uploadedFile);

                showSuccessMessage(`File '${file.name}' has been uploaded`);

            } catch (e) {
                console.debug(e);
            }
        }
        setIsUploading(false);
    }

    const uploadImportFileClickHandler = async () => {

        setIsUploading(true);
        for (const file of files) {
            try {
                const data = new FormData();
                data.set("file", file);

                const r = await fetchAuthorized(ApiRoutes.MassiveImport.ImportTransactionRequests(),
                    {
                        method: HttpMethods.POST,
                        body: data
                    });
                const value = await r.json()

                setActualImport(value);
                // showSuccessMessage(`File '${file.name}' has been uploaded`);

            } catch (e) {
                console.debug(e);
            }
        }
        setIsUploading(false);
    }
    const notifyFileUploaded = (storedFile) => {
        const { onFileUploaded } = props;
        if (onFileUploaded) onFileUploaded(storedFile);
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleDrop
    });

    return (
        <div>
            <div
                className={clsx({
                    [classes.dropZone]: true,
                    [classes.dragActive]: isDragActive
                })}
                {...getRootProps()}>
                <input {...getInputProps()} />
                <div>
                    <Typography
                        gutterBottom
                        variant="h6">
                        {props.title}
                    </Typography>
                    <Typography
                        className={classes.info}
                        color="textSecondary"
                        variant="body1">
                        Drop files here or
                        {' '}
                        <Link color="textSecondary" underline="always">browse</Link>
                        {' '}
                        your machine
                    </Typography>
                </div>
            </div>
            {files && files.length > 0 && (
                <>
                    <List className={classes.list}>
                        {files.map((file, i) => (
                            <ListItem
                                divider={i < files.length - 1}
                                key={i}>
                                <ListItemIcon>
                                    {!isUploading ?
                                        <PublishIcon />
                                        :
                                        <CircularProgress size="1.6em" color="primary" />
                                    }
                                </ListItemIcon>
                                <ListItemText
                                    primary={file.name}
                                    primaryTypographyProps={{ variant: 'body1' }} />
                                {!isUploading &&
                                    <Tooltip title="Remove">
                                        <IconButton
                                            color="primary"
                                            edge="end"
                                            onClick={() => removeFileClickHandler(i)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </ListItem>
                        ))}
                    </List>
                    <div className={classes.actions}>
                        <Button
                            color="primary"
                            className={classes.removeButton}
                            size="small"
                            disabled={isUploading}
                            onClick={removeAllFilesClickHandler}>
                            Remove all
                        </Button>
                        <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            disabled={isUploading}
                            onClick={props.isMassiveImport === true ? uploadImportFileClickHandler : uploadAllFilesClickHandler}>
                            Upload file
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
}
