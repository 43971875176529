/* eslint-disable no-use-before-define */
import React from "react";
import { Field } from 'formik'
import { TextField, CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { fieldToTextField } from 'formik-material-ui'
import _ from "lodash";

const FormikAutocomplete = ({ textFieldProps, ...props }) => {
  const {
    form: { setTouched, setFieldValue },
    updateOptions
  } = props
  const { error, helperText, label, variant, ...field } = fieldToTextField(props)
  const { name } = field

  const [options, setOptions] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleOnChangeText = async (event, searchText) => {
    event.persist();
    //console.log(`onInputChange has been fired, input: ${searchText}`);
    setIsLoading(true);
    const items = await updateOptions(searchText);
    setOptions(items);
    setIsLoading(false);
  }

  const prepareOptions = (items) => {
    return items.map((item) => {
      const firstLetter = item.label && item.label[0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...item,
      };
    })
      .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));
  }

  React.useEffect(() => {
    async function loadInitialData() {
      setIsLoading(true);
      const items = await updateOptions();
      setOptions(items);
      setIsLoading(false);
    }
    loadInitialData();
  }, [])

  return (
    <Autocomplete
      {...props}
      {...field}
      options={options.length > 0 && prepareOptions(options)}
      disableCloseOnSelect
      loading={isLoading}
      onInputChange={_.debounce(handleOnChangeText, 600)}
      onChange={(_, item) => setFieldValue(name, item)}
      onBlur={() => setTouched({ [name]: true })}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(item, current) => item.value === current.value}
      groupBy={(option) => option.firstLetter}
      limitTags={2}
      renderInput={innerProps => (
        <TextField
          {...innerProps}
          {...textFieldProps}
          helperText={helperText}
          error={error}
          label={label}
          variant={variant}
          InputProps={{
            ...innerProps.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {innerProps.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

export function AsyncAutocomplete(props) {

  return (
    <Field
      multiple
      name={props.name}
      component={FormikAutocomplete}
      {...props}
    />);
}