import React, { useContext, useEffect } from 'react';

import {    
    Grid,
    Typography,
    Button,
} from '@material-ui/core';

import { RolesContext } from '../RolesContext';
import { NavContext, NavRoutes } from '../../../../contexts/NavContext';

import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendarOutlined'

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
    },
}));

export const RoleHeader = (props) => {
    const classes = useStyles();
    const { navigateTo } = useContext(NavContext);
    
    const { 
        state,
        isLoadingUser,
        isRemovingFromRole
    } = useContext(RolesContext);

    const addUserClickHandler = () => {
        navigateTo(NavRoutes.Admin.AddUserInRole, [state.role.applicationRoleId]);
    }
    
    return (       
        <Grid container alignItems="flex-end" justify="space-between" spacing={3} className={classes.root}>
            <Grid item>
                <Typography component="h2" gutterBottom variant="overline">
                    Role
                </Typography>
                <Typography>
                    {state.role.groupName}
                </Typography>
            </Grid>
            <Grid item>
                {state.role.groupAADReference === null &&
                    <Button                        
                        variant="contained"
                        disabled={isLoadingUser || isRemovingFromRole}
                        onClick={addUserClickHandler}>
                        Add User
                    </Button>
                }
            </Grid>
        </Grid> 
    );
}