import React, { useEffect, useContext } from 'react';

import {
    Hidden,
    LinearProgress,
    Grid
} from '@material-ui/core';

import { TablesProvider, TablesContext, TableValueType } from './TablesContext';
import { NavContext } from '../../../contexts';

import {
    TableList,
    TableSelector,
    TableValueList,
    ComplexTable
} from './components';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
    },
    progress: {
        marginBottom: theme.spacing(2)
    }
}));

const PageContent = (props) => {
    const { addBreadcrumbToken } = useContext(NavContext);
    const classes = useStyles();

    const {
        state,
        isLoadingTableDefs,
        isLoadingRelTable,
        getTableDefinitions,
        getComplexTableDefinitions,
        getRelatedTable,
        resetRelatedTable,
        //isLoadingTableValues,
        getTableValues,
    } = useContext(TablesContext);


    const {
        isSelectedTableComplex
    } = state;

    const loadMoreHandler = (tableId, searchText, showObsolete, pageIndex, pageSize) => {

        if (tableId) {
            const table = state.tables.find(x => x.tableDefinitionId === tableId);
            if (table) {
                if (table.relatedTable) {
                    getRelatedTable(table.relatedTable.tableDefinitionId);
                } else {
                    resetRelatedTable();
                }

                getTableValues(tableId, searchText, showObsolete, pageIndex, pageSize);
            }
        }
    }

    useEffect(() => {
        addBreadcrumbToken("Local Tables")
        getTableDefinitions();
        getComplexTableDefinitions();
    }, []);

    return (
        <>
            {(isLoadingTableDefs
                // || isLoadingTableValues
                || isLoadingRelTable) &&
                <LinearProgress color="primary" className={classes.progress} />
            }
            {state.tables.length > 0 &&
                <Grid container spacing={2}>
                    <Hidden smDown>
                        <Grid item lg={2} >
                            <TableList tableValueType={TableValueType.Local} />
                        </Grid>
                        <Grid item lg={10} >
                            {isSelectedTableComplex ?
                                <ComplexTable />
                                :
                                <TableValueList
                                    tableValueType={TableValueType.Local}
                                    onLoadMore={loadMoreHandler}
                                />}
                        </Grid>
                    </Hidden>
                    <Hidden mdUp>
                        <Grid item xs={12}>
                            <TableSelector tableValueType={TableValueType.Local} />
                        </Grid>
                        <Grid item xs={12}>
                            {isSelectedTableComplex ?
                                <ComplexTable />
                                :
                                <TableValueList
                                    tableValueType={TableValueType.Local}
                                    onLoadMore={loadMoreHandler}
                                />}
                        </Grid>
                    </Hidden>
                </Grid>
            }
        </>
    );
}

export const LocalTablesPage = (props) => {

    return (
        <TablesProvider>
            <PageContent />
        </TablesProvider>
    );
}