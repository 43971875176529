import React, { useContext, useEffect } from 'react';

import {
    Paper,
    Avatar,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Hidden,
    Button
} from '@material-ui/core';

import { RolesContext } from '../RolesContext';
import { NavContext, NavRoutes } from '../../../../contexts/NavContext';

import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendarOutlined'

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        overflow: 'hidden',
    },
    author: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(1)
    },
    table: {

    }
}));

export const RoleList = (props) => {
    const classes = useStyles();
    const { navigateTo } = useContext(NavContext);

    const {
        state
    } = useContext(RolesContext);

    const editRoleClickHandler = (role) => {
        navigateTo(NavRoutes.Admin.EditRole, [role.applicationRoleId]);
    }
    const sortRoles = (roles) => {
        var itemsOrdered = [];
        var theOrder = ["Opener", "Business", "Ciso", "Administrator"];

        for (var i = 0; i < theOrder.length; i++) {
            if (roles.indexOf(theOrder[i]) > -1) {
                itemsOrdered.push(theOrder[i]);
            }
        }
        return itemsOrdered;
    }

    return (
        <Paper className={classes.root}>
            <Table className={classes.table} arial-label="role table">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <Hidden mdDown><TableCell>Description</TableCell></Hidden>
                        <TableCell align="right">Members</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {state.roles && state.roles
                        .sort((a, b) => a.order - b.order)
                        .map((role, index) =>
                            <TableRow hover key={index}>
                                <TableCell component="th" scope="row">
                                    <div className={classes.author}>
                                        <Avatar aria-label="recipe" className={classes.avatar}>
                                            <PermContactCalendarIcon />
                                        </Avatar>
                                        <Typography>{role.groupName}</Typography>
                                    </div>
                                </TableCell>
                                <Hidden mdDown>
                                    <TableCell>
                                        {role.description}
                                    </TableCell>
                                </Hidden>
                                <TableCell align="right">
                                    <Typography>{role.membersCount}</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        onClick={() => editRoleClickHandler(role)}>
                                        View
                                </Button>
                                </TableCell>
                            </TableRow>
                        )}
                </TableBody>
            </Table>
        </Paper>
    );
}