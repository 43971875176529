import React, { useEffect, useContext } from 'react';
import { LinearProgress } from '@material-ui/core';
import { TransactionRequestsContext, TransactionRequestsProvider } from './TransactionRequestsContext';
import { NavContext } from '../../contexts';
import { RequestsTable } from './components';

const PageContent = (props) => {
    const { setBreadcrumbRoot, getCurrentLocation } = useContext(NavContext);

    const {
        state,
        isLoadingRequests,
        loadRequests,
        setFilter
    } = useContext(TransactionRequestsContext);

    useEffect(() => {
        const location = getCurrentLocation();
        const filter = {
            pageIndex: 0,
            pageSize: 30
        };
        if (location.pathname.includes("/draft")) {
            setBreadcrumbRoot("Draft Transactions");
            filter.category = 0;
        } else if (location.pathname.includes("/running")) {
            setBreadcrumbRoot("Running Requests");
            filter.category = 1;
        } else if (location.pathname.includes("/completed")) {
            setBreadcrumbRoot("Transactions in Catalogue");
            filter.category = 2;
            filter.pageSize = 50;
        } else if (location.pathname.includes("/repository")) {
            setBreadcrumbRoot("TCodeMap Repository");
            filter.category = 6;
            filter.pageSize = 50;
        } else if (location.pathname.includes("/all")) {
            setBreadcrumbRoot("All Transactions");
            filter.category = 3;
            filter.pageSize = 50;
        } else if (location.pathname.includes("/reworked")) {
            setBreadcrumbRoot("Reworked Transactions");
            filter.category = 5;
        } else if (location.pathname.includes("/rework")) {
            setBreadcrumbRoot("Rework Requests");
            filter.category = 4;
        }

        setFilter({ pageSize: filter.pageSize });
        loadRequests(filter);
    }, []);

    return (
        <>
            {isLoadingRequests &&
                <LinearProgress color="primary" />
            }
            {state.requests && !isLoadingRequests &&
                <RequestsTable />
            }
        </>
    );
}

export const TransactionRequestsPage = () => {
    return (
        <TransactionRequestsProvider>
            <PageContent />
        </TransactionRequestsProvider>
    );
}