import React, { useContext } from 'react';
import { useSnackbar } from 'notistack';

import { AuthContext } from './AuthContext';

export const HttpMethods = {

    GET: 'GET',
    POST: 'POST',
    DELETE: 'DELETE',
    PATCH: 'PATCH'
}

const compileRoute = (route, params) => {
    if (params) {
        const props = Object.getOwnPropertyNames(params);
        if (props && props.length > 0) {
            route = `${route}?`
            props.filter(x => params[x] !== null && params[x] !== undefined).forEach(prop => route = `${route}${prop}=${params[prop]}&`);
            route = route.substr(0, route.length - 1);
        }
    }
    return route;
}

export const ApiRoutes = {
    Graph: {
        GetUsers: (params) => compileRoute("/api/Graph/GetUsers", params),
    },
    Role: {
        GetRoles: (params) => compileRoute("/api/Role/GetRoles", params),
        GetRole: (params) => compileRoute("/api/Role/GetRole", params),
    },
    User: {
        GetMe: (params) => compileRoute("/api/User/GetMe", params),
        GetUser: (params) => compileRoute("/api/User/GetUser", params),
        GetOpeners: (params) => compileRoute("/api/User/GetOpeners", params),
        GetUserTodos: (params) => compileRoute("/api/User/GetUserTodos", params),
        GetUserStatistics: (params) => compileRoute("/api/User/GetUserStatistics", params),
        AddUserInRole: () => "/api/User/AddUserInRole",
        UpdateUserInRole: () => "/api/User/UpdateUserInRole",
        RemoveUserFromRole: (params) => compileRoute("/api/User/RemoveUserFromRole", params),
    },
    Data: {
        GetComplexTableValues: (entityName, params) => compileRoute(`/api/Data/${entityName}`, params), // GET
        GetComplexTableValue: (entityName, id) => `/api/Data/${entityName}/${id}`, // GET
        AddComplexTableValue: (entityName) => `/api/Data/${entityName}`, // POST
        UpdateComplexTableValue: (entityName) => `/api/Data/${entityName}`, // PATCH
        DeleteComplexTableValue: (entityName, id) => `/api/Data/${entityName}/${id}`, // DELETE
        GetComplexOptions: (entityName) => `/api/Data/${entityName}/options`, // GET
    },
    Table: {
        GetTableDefinitions: (params) => compileRoute("/api/Table/GetTableDefinitions", params),
        GetChoosenTableDefinitions: (params) => compileRoute("/api/Table/GetChoosenTableDefinitions", params),
        GetTableDefinition: (params) => compileRoute("/api/Table/GetTableDefinition", params),
        GetTableValues: (params) => compileRoute("/api/Table/GetTableValues", params),
        SearchTableValueOptions: (params) => compileRoute("/api/Table/SearchTableValueOptions", params),
        AddTableValue: () => "/api/Table/AddTableValue",
        UpdateTableValue: () => "/api/Table/UpdateTableValue",
        DeleteTableValue: (params) => compileRoute("/api/Table/DeleteTableValue", params),
    },
    Request: {
        GetFilteredRequests: () => "/api/Request/GetFilteredRequests",
        GetFilters: () => "/api/Request/GetRequestFilters",

        GetCompletedRequestOptions: (params) => compileRoute("/api/Request/GetCompletedRequestOptions", params),

        CheckUniquenessInCatalogue: (params) => compileRoute("/api/Request/CheckUniquenessInCatalogue", params),
        GetRequest: (params) => compileRoute("/api/Request/GetRequest", params),
        GetTransactionRequestHistory: (params) => compileRoute("/api/Request/GetTransactionRequestHistory", params),

        SaveTransactionRequestDraft: () => "/api/Request/SaveTransactionRequestDraft",
        SendTransactionRequestDraft: () => "/api/Request/SendTransactionRequestDraft",
        DeleteTransactionRequestDraft: (params) => compileRoute("/api/Request/DeleteTransactionRequestDraft", params),

        UpdateBusinessInfo: () => "/api/Request/UpdateBusinessInfo",
        SendBusinessInfo: () => "/api/Request/SendBusinessInfo",

        UpdateCisoInfo: () => "/api/Request/UpdateCisoInfo",
        SendCisoInfo: () => "/api/Request/SendCisoInfo",

        UpdateRequest: () => "/api/Request/UpdateRequest",
        ApproveRequest: () => "/api/Request/ApproveRequest",

        ReassignRequest: () => "/api/Request/ReassignRequest",
        SkipToApproval: () => "/api/Request/SkipToApproval",

        SaveRework: () => "/api/Request/SaveRework",
        SendRework: () => "/api/Request/SendRework",
        
        GetPreviousStatus: (params) => compileRoute("/api/Request/GetPreviousStatus", params),

        AbortRequest: (params) => compileRoute("/api/Request/AbortRequest", params),
        AbortRequestWithNote: (params) => compileRoute("/api/Request/AbortRequestWithNote", params),

        GetRequestHistoryExcel: (params) => compileRoute("/api/Request/GetRequestHistoryExcel", params)
    },
    File: {
        Upload: () => "api/File/Upload",
        Download: (params) => compileRoute("/api/File/Download", params)
    },
    MassiveImport: {
        DownloadMassiveImportTemplate: () => "api/MassiveImport/DownloadMassiveImportTemplate",
        ImportTransactionRequests: () => "api/MassiveImport/ImportTransactionRequests",     
        GetStoredMassiveImports: () => "api/MassiveImport/GetStoredMassiveImports",
        DownloadStep1: (params) => compileRoute("/api/MassiveImport/DownloadStep1", params),
        DownloadStep2: (params) => compileRoute("/api/MassiveImport/DownloadStep2", params)
    },
    Export: {
        ExportRequests: () => "api/Export/ExportRequests"
    }
}

export const FetchContext = React.createContext(null);

const { Provider } = FetchContext;
export const FetchProvider = (props) => {
    const { fetchAdal } = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();

    const defaultOptions = {
        autoHideDuration: 7000,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
        }
    }
    const snackOptions = { variant: "error", ...defaultOptions };

    const createError = (code, message) => {
        const error = new Error(message);
        error.code = code;
        return error;
    }

    // source: https://dev.to/ycmjason/javascript-fetch-retry-upon-failure-3p6g
    const fetchRetry = (retries, api, cfg) => {
        return fetch(api, cfg).catch(function (error) {
            if (retries === 1) throw error;
            return fetchRetry(api, cfg, retries - 1);
        });
    }

    const fetchOk = (api, cfg) => {

        var isIE = !!window.MSInputMethodContext && !!document.documentMode;

        if (cfg.headers) {
            if (cfg.body && (typeof cfg.body === 'string' || cfg.body instanceof String)) {
                cfg.headers["Content-Type"] = "application/json"
            }
            cfg.headers["Cache-Controll"] = "no-cache";
            cfg.headers["Pragma"] = "no-cache";
            cfg.headers["Expires"] = "0";
        }
        else {
            cfg = {
                ...cfg,
                cache: 'no-cache',
                headers: new Headers({
                    //"Content-Type": `application/json`,
                    "Cache-Control": "no-cache",
                    "Pragma": "no-cache",
                    "Expires": "0"
                })
            };
        }

        return fetch(api, cfg)
            .then(r => {
                if (r.ok) {
                    return r;
                } else {
                    switch (r.status) {
                        // Bad Request
                        case 400:
                            return r.json()
                                .then(data => {
                                    if (data.errors) {
                                        const errorKeys = Object.keys(data.errors);
                                        if (cfg.collectErrorDetails) {
                                            const error = new Error("Invalid data supplied – Please fill in all mandatory fields, then submit again!");
                                            error.details = errorKeys.map(x => ({ errorId: x, message: data.errors[x][0] }));
                                            error.code = r.status;
                                            return Promise.reject(error);
                                        } else {
                                            errorKeys.forEach(errorKey => {
                                                const error = data.errors[errorKey][0];
                                                enqueueSnackbar(error, snackOptions);
                                            });
                                        }
                                    }
                                    return Promise.reject(createError(r.status, "Invalid data supplied"));
                                });
                        // Unauthorized
                        case 401:
                            return Promise.reject(createError(r.status, "User is not authorized"));
                        // Forbidden
                        case 403:
                            return Promise.reject(createError(r.status, "User is not allowed"));
                        // Not Found
                        case 404:
                            return Promise.reject(createError(r.status, "Resource not found"));
                        // Server Error
                        case 500:
                            return r.json()
                                .then(data => {
                                    let message = null;
                                    if (data.message) message = data.message;
                                    else message = "An internal error occured";
                                    return Promise.reject(createError(r.status, message));
                                });
                        // Not handled
                        default:
                            return Promise.reject(createError(r.status, "Something went bad"));
                    }
                }
            })
            .catch(error => {
                if (!cfg.silent)
                    enqueueSnackbar(error.message, snackOptions);
                return Promise.reject(error);
            });
    }

    const fetchAuthorized = (api, cfg) => {

        return fetchAdal(fetchOk, api, cfg);

        // Used for debug purpose
        //return delay(5000).then(() => fetchOk(api, cfg));
    }

    // Used for debug purpose
    const delay = (duration) => {
        return new Promise((resolve) => setTimeout(resolve, duration));
    }

    return (
        <Provider value={{
            fetchRetry,
            fetchOk,
            fetchAuthorized
        }}>
            {props.children}
        </Provider>
    );
}