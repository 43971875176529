import React, { useContext, useEffect, useRef } from 'react';
import { TabsContext } from './TabsContext';
import { TransactionRequestContext } from '../TransactionRequestContext';
import _ from "lodash";

// This component serves as "common point" where to react
// when data from formik changes (useEffect + values)
// In this component you can create useEffect cascade effects
// to populate dependant widgets with values

export const DataContainer = ({ ...props }) => {

    const {
        checkTransactionNameUniqueness,
        isRejectedProp,
        setIsRejectedProp
    } = useContext(TransactionRequestContext);

    const {
        values,
        setFieldValue
    } = useContext(TabsContext);


    // transaction name uniqueness check
    const checkUniqueness = (newValue) => checkTransactionNameUniqueness(newValue);
    const debouncedCheck = useRef(_.debounce(checkUniqueness, 1000))
    useEffect(() => {
        if (values.transactionName) {
            debouncedCheck.current(values.transactionName)
        }
    }, [values.transactionName]);

    useEffect(() => {
        if (values.transactionScopeClick ||
            values.relevantParametersClick ||
            values.transactionRunResultClick ||
            values.outputClick ||
            values.outputExportClick ||
            values.sapCodeDescriptionClick ||
            values.hasSimilarTransactionReferenceClick ||
            values.similarTransactionReferencesClick ||
            values.similarTransactionFunctionalDifferenceClick ||
            values.transactionModelClick ||
            values.relatedTransactionsClick
            ) { 
            setIsRejectedProp(true);
        }
        else {
            setIsRejectedProp(false);
        }
    }, [values.transactionScopeClick,
        values.relevantParametersClick,
        values.transactionRunResultClick,
        values.outputClick,
        values.outputExportClick,
        values.sapCodeDescriptionClick ,
        values.hasSimilarTransactionReferenceClick ,
        values.similarTransactionReferencesClick ,
        values.similarTransactionFunctionalDifferenceClick,
        values.transactionModelClick,
            values.relatedTransactionsClick]); 

    useEffect(() => {
        if (values.hasAuthorityCheck === false) {
            setFieldValue("authorityCheckSource", null);
        }
    }, [values.hasAuthorityCheck]);

    useEffect(() => {
        const cleaned = values.transactionName && values.transactionName.replace(/\s/g, "");
        setFieldValue("transactionName", cleaned);
    }, [values.transactionName]);

    return (
        <div>
            {props.children}
        </div>
    )
}
