import React from 'react';

import { useAggregatedState } from '../../../../../helpers';

export const SodActivityInfoContext = React.createContext(null);

const { Provider } = SodActivityInfoContext;
export const SodActivityInfoProvider = (props) => {
    const [state, setState] = useAggregatedState({
    });

    return (
        <Provider value={{
            state
        }}>
            {props.children}
        </Provider>
    );
}