import React from 'react';

import { useAggregatedState } from '../../../../../helpers';

export const BusinessInfoContext = React.createContext(null);

const { Provider } = BusinessInfoContext;
export const BusinessInfoProvider = (props) => {
    const [state, setState] = useAggregatedState({
    });

    return (
        <Provider value={{
            state
        }}>
            {props.children}
        </Provider>
    );
}