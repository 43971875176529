import React, { useEffect, useContext } from 'react';

import {
    LinearProgress
} from '@material-ui/core';

import { RolesProvider, RolesContext } from './RolesContext';
import { NavContext } from '../../../contexts';

import {
    RoleList
} from './components';

const PageContent = (props) => {
    const { addBreadcrumbToken } = useContext(NavContext);
    
    const { 
        state,
        isLoadingRoles,
        getRoles
    } = useContext(RolesContext);

    useEffect(() => {
        addBreadcrumbToken("Roles")
    }, []);

    useEffect(() => {
        getRoles();
    }, [])

    return (
        <>            
            {isLoadingRoles &&
                <LinearProgress color="primary" />
            }        
            {state.roles && !isLoadingRoles &&
                <RoleList />                
            }
        </>        
    );
}

export const RolesPage = (props) => {
    return (
        <RolesProvider>           
            <PageContent /> 
        </RolesProvider>
    );
}
