import React, { useContext, useEffect, useState, useRef, forwardRef } from 'react';

import {
    Paper,
    Typography,
    TablePagination,
} from '@material-ui/core';

import MaterialTable, { MTableToolbar } from 'material-table';
import { TablesContext } from '../../TablesContext';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { makeStyles } from '@material-ui/core/styles';
import { ComplexEntityNames } from '../../../../../../helpers';
import { getCompensatoryControlColumns, getSodActivityColumns, getRiskColumns, getCompanyColumns } from './ComplexTableColumns';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: "100%",
        overflowX: "auto",
        margin: 4,
        "&& th": {
            backgroundColor: "red"
        }
    },
    showObsolete: {
        marginLeft: theme.spacing(1)
    },
    notable: {
        padding: "16px"
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    }
}));

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export const ComplexTable = (props) => {
    const classes = useStyles();

    const {
        state,
        isLoadingTableValues: isLoadingValues,
        isLoadingComplexOptions,
        getComplexTableValues,
        addComplexTableValue,
        updateComplexTableValue,
        deleteComplexTableValue,
        getComplexOptions,
        deUglifyLongString
    } = useContext(TablesContext);

    const {
        selectedTableId,
        complexTables,
        complexOptions,
        tableValuesOptions
    } = state;

    const getColumns = () => {
        // eslint-disable-next-line default-case
        switch (selectedTableId) {
            case ComplexEntityNames.Company:
                return getCompanyColumns(classes, deUglifyLongString);
            case ComplexEntityNames.CompensatoryControl:
                return getCompensatoryControlColumns(tableValuesOptions, classes, complexOptions, deUglifyLongString);
            case ComplexEntityNames.SodActivity:
                return getSodActivityColumns(tableValuesOptions, classes, complexOptions, deUglifyLongString);
            case ComplexEntityNames.Risk:
                return getRiskColumns(tableValuesOptions, classes, complexOptions, deUglifyLongString);
        }
    };

    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState(null);
    const [tableState, setTableState] = useState({

        columns: getColumns(),

        data: []
    });

    const getCurrentTableName = () => {
        if (selectedTableId) {
            const table = complexTables.find(x => x.tableDefinitionId === selectedTableId);
            return table.name;
        } else {
            return "No table selected";
        }
    }

    const table = state.complexTables.find(x => x.tableDefinitionId === state.selectedTableId);

    useEffect(() => {
        if (state.selectedTableId) {
            setTableState(prevState => {
                const newData = table ? [].concat(state.tableValues) : [];
                return {
                    ...prevState,
                    columns: getColumns(),
                    data: newData,
                    page: pageIndex,
                    totalCount: state.totalValues
                }
            });
        }
    }, [state.tables, state.selectedTableId, state.tableValues]);

    useEffect(() => {
        if (state.selectedTableId) {
            tableRef.current.dataManager.changeSearchText("");
            tableRef.current.dataManager.changeApplySearch(false);
            tableRef.current.dataManager.changeRowEditing(false);
            setPageIndex(0);
            setSearchText(null);
        }
    }, [state.selectedTableId])

    useEffect(() => {
        getComplexOptions(
            ComplexEntityNames.SodActivity,
            ComplexEntityNames.Risk,
            ComplexEntityNames.CompensatoryControl);
    }, []);

    useEffect(() => {
        // call api to list table values only after we retrieved options
        if (!isLoadingComplexOptions) {
            getComplexTableValues(state.selectedTableId, searchText, pageIndex, pageSize);
        }
    }, [state.selectedTableId, searchText, pageIndex, pageSize, isLoadingComplexOptions]);

    const tableRef = useRef(null)
    return (
        <>
            {table ?
                <MaterialTable
                    tableRef={tableRef}
                    className={classes.root}
                    isLoading={isLoadingValues || isLoadingComplexOptions}
                    title={getCurrentTableName()}
                    icons={tableIcons}
                    columns={tableState.columns}
                    data={tableState.data}
                    options={{
                        search: true,
                        sorting: false,
                        paging: true,
                        filtering: false,
                        debounceInterval: 1000,
                        pageSize: pageSize,
                        pageSizeOptions: [10, 20, 40, 60],
                        emptyRowsWhenPaging: false   //to make page size fluid in case of less data rows
                    }}
                    onSearchChange={text => {
                        setPageIndex(0);
                        setSearchText(encodeURIComponent(text));
                    }}
                    editable={
                            {
                                onRowAdd: async (newData) => {
                                    await addComplexTableValue(
                                        {
                                            ...newData,
                                            tableDefinitionId: table.tableDefinitionId
                                        }
                                    );
                                },
                                onRowUpdate: async (newData) => {
                                    await updateComplexTableValue(
                                        {
                                            ...newData,
                                            tableDefinitionId: table.tableDefinitionId
                                        }
                                    );
                                },
                                onRowDelete: async (oldData) => {
                                    await deleteComplexTableValue(oldData.id, table.tableDefinitionId);
                                }

                            }
                    }
                    components={{
                        Toolbar: (props) => (
                            <div>
                                <MTableToolbar {...props} />
                            </div>
                        ),
                        Pagination: (props) => (
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 40, 60]}
                                count={state.totalValues}
                                rowsPerPage={pageSize}
                                page={pageIndex}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={(e, page) => {
                                    setPageIndex(page);
                                    tableRef.current.dataManager.changeCurrentPage(page);

                                }}
                                onChangeRowsPerPage={e => {
                                    const newPageSize = parseInt(e.target.value, 10);
                                    setPageIndex(0);
                                    setPageSize(newPageSize);
                                    tableRef.current.dataManager.changePageSize(newPageSize);
                                }}
                            />
                        )
                    }}
                />
                :
                <Paper className={classes.notable}>
                    <Typography variant="h6">
                        No table selected
                    </Typography>
                    <Typography variant="body1" style={{ marginTop: 8 }}>
                        Select a table from the list to see what values are stored
                    </Typography>
                </Paper>
            }
        </>
    );
}