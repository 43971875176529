import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core';

import {
    InsightProvider,
    ToastProvider,
    AuthProvider,
    FetchProvider,
    UserProvider,
    NavProvider,
} from './components/contexts';

import {
    Layout,
    Routes
} from './components/common';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#c2a07b"
        },
        secondary: {
            main: "#ff0000"
        },
        success: {
            main: "#4caf50"
        },
        warning: {
            main: "#ff9800"
        },
        text: {
            secondary: "#c2a07b"
        }
    },
    overrides: {
        MuiStepIcon: {
            root: {
                '&$active': {
                    color: '#c2a07b',
                },
                '&$completed': {
                    color: '#d1d1d1',
                },
            },
        },
        MuiSwitch: {
        },
        MuiTabs: {
            indicator: {
                backgroundColor: "#c2a07b"
            }
        }
    }
});

const useStyles = makeStyles((theme) => ({
    variantSuccess: {
        fontSize: '22px !important;',
    },
    variantError: {
        fontSize: '22px !important;',
    },
    variantWarning: {
        fontSize: '22px !important;',
    },
    variantInfo: {
        fontSize: '22px !important;',
    }
}));

const App = (props) => {
    const classes = useStyles();

    return (
        <BrowserRouter basename={props.baseUrl}>
            <InsightProvider>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider
                        preventDuplicate
                        maxSnack={5}
                        classes={classes}
                    >
                        <ToastProvider>
                            <AuthProvider>
                                <FetchProvider>
                                    <UserProvider>
                                        <NavProvider>
                                            <Layout>
                                                <Routes />
                                            </Layout>
                                        </NavProvider>
                                    </UserProvider>
                                </FetchProvider>
                            </AuthProvider>
                        </ToastProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </InsightProvider>
        </BrowserRouter>
    );
}

export default App;