import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
    Chip,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Hidden,
    Button,
    Link,
    Tooltip,
    Grid,
    CircularProgress,
    Drawer
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings'
import SpeedIcon from '@material-ui/icons/Speed'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'

import { NavContext, NavRoutes, UserContext } from '../contexts';

import { RequestStatus, getRequestPerformanceText, getRequestStatusText } from '../../helpers';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    skeleton: {
        margin: theme.spacing(1)
    },
    viewall: {
        marginLeft: theme.spacing(2)
    },
    blackHeader: {
        backgroundColor: '#333333',
    },
    whiteHeaderLabel: {
        color: "white"
    },
    chip: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    buttonIcon: {
        marginRight: theme.spacing(1)
    },
    drawerHeight: {
        height: "70px"
    },
    drawerButton: {
        height: "100%",
        width: "100%"
    },
}));

const LinkRouter = props => <Link {...props} component={RouterLink} />;

export const RequestsList = (props) => {
    const classes = useStyles();

    const { getRouteTo, getCurrentLocation } = useContext(NavContext);

    const [selectedRequest, setSelectedRequest] = useState(null);
    const rowClickHandler = (index) => {
        if (index !== null) {
            if (index === selectedRequest) {
                setSelectedRequest(null)
            } else {
                setSelectedRequest(index)
            }
        }
    }

    const deleteClickHandler = async (index) => {
        if (index !== null || index !== undefined) {
            await props.deleteHandler(props.requests[index])
        } else {
            await props.deleteHandler(props.requests[selectedRequest])
            setSelectedRequest(null)
        }
    }

    const [showCompletedTable, setShowCompletedTable] = useState(false);
    const [showAllTable, setShowAllTable] = useState(false);

    useEffect(() => {
        const location = getCurrentLocation();

        if (location.pathname.includes("/completed")) {
            setShowCompletedTable(true);
            setShowAllTable(false);
        }
        if (location.pathname.includes("/all")) {
            setShowAllTable(true);
            setShowCompletedTable(false);
        }
        else {
            setShowCompletedTable(false);
            setShowAllTable(false);
        }
    }, []);

    const renderChips = (values) => {
        if (values.length > 2) {
            const excess = values.length - 2;
            values.length = 2; // truncates the array

            let chips = values.map((code, index) => {
                return (
                    <Chip
                        key={index}
                        label={code}
                        color="primary"
                        variant="outlined"
                        className={classes.chip} />
                );
            });
            chips.push(<Chip
                key="static_chip"
                label={`+${excess}`}
                color="primary"
                variant="outlined"
                className={classes.chip} />)
            return chips;
        }
        return values.map((code, index) => {
            return (
                <Chip
                    key={index}
                    label={code}
                    variant="outlined"
                    className={classes.chip} />
            );
        });
    }

    const renderSodActivityChips = (items) => {
        if (items.length > 2) {
            const excess = items.length - 2;
            items.length = 2; // truncates the array

            let chips = items.map((item, index) => {
                return (<Tooltip title={item.description}>
                    <Chip
                        key={index}
                        label={item.code}
                        color="primary"
                        variant="outlined"
                        className={classes.chip} />
                </Tooltip>);
            });
            chips.push(<Chip
                key="static_chip"
                label={`+${excess}`}
                color="primary"
                variant="outlined"
                className={classes.chip} />)
            return chips;
        }
        return items.map((item, index) => {
            return (<Tooltip title={item.description}>
                <Chip
                    key={index}
                    label={item.code}
                    variant="outlined"
                    className={classes.chip} />
            </Tooltip>);
        });
    }

    // Transaction Code
    // SAP Code Description (Business Step)
    // Transaction Short Description  (Business Step)
    // Business Process  (Business Step)
    // Transaction Mode  (Opener Step)
    // Similar Transaction Reference (Opener Step)
    // Program (Opener Step)
    // SoD Activity à da mostrare solo a ADMIN & CISO (abbiamo una vista diversa sulla base dell’utente che accede? Se si, allora implementiamo, altrimenti lasciamo stare)

    const renderTable = () => {
        if (showCompletedTable)
            return <Table>
                <TableHead>
                    <TableRow className={props.blackHeader ? classes.blackHeader : null}>
                        {props.requestId && (
                            <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>#</TableCell>
                        )}
                        <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Transaction Name</TableCell>
                        <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Status</TableCell>
                        <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>SAP Code Description</TableCell>
                        <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Transaction Short Description</TableCell>
                        <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Transaction Function</TableCell>
                        <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Similar Transaction Reference</TableCell>
                        <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Business Process</TableCell>
                        <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Business SubProcess</TableCell>
                        <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>SoD Activity</TableCell>
                        {props.delete && <TableCell></TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.requests && props.requests.length > 0 &&
                        props.requests.map((request, index) => {
                            return (
                                <TableRow key={index}>
                                    {props.requestId && (
                                        <TableCell component="th" scope="row">
                                            <Typography>
                                                {request.transactionRequestId}
                                            </Typography>
                                        </TableCell>
                                    )}
                                    <TableCell component="th" scope="row">
                                        <Typography>
                                            <LinkRouter key={index} color="textSecondary" to={getRouteTo(NavRoutes.EditRequest, [request.transactionRequestId])}>
                                                {request.transactionName}
                                            </LinkRouter>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Chip
                                            icon={<SettingsIcon />}
                                            label={getRequestStatusText(request)} />
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2">
                                            {request.sapCodeDescription}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2">
                                            {request.transactionShortDescription}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2">
                                            {request.transactionFunction}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2">
                                            {renderChips(request.similarTransactionReference)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2">
                                            {request.businessProcess}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2">
                                            {request.businessSubProcess}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2">
                                            {renderSodActivityChips(request.sodActivity)}
                                        </Typography>
                                    </TableCell>
                                    {props.delete && props.deleteHandler && (
                                        <TableCell align="right">
                                            <Button
                                                color="secondary"
                                                disabled={props.isDisabled || request.alreadyApprovedOnce}
                                                size="small"
                                                variant="contained"
                                                onClick={() => deleteClickHandler(index)}>
                                                Delete
                                            </Button>
                                        </TableCell>
                                    )}
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>

        if (showAllTable)
            return <Table>
                <TableHead>
                    <TableRow className={props.blackHeader ? classes.blackHeader : null}>
                        {props.requestId && (
                            <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>#</TableCell>
                        )}
                        <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Transaction Name</TableCell>
                        <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Status</TableCell>
                        <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Opener</TableCell>
                        <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>SoD Activity</TableCell>
                        <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Similar Transaction Reference</TableCell>
                        <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Transaction Mode</TableCell>
                        <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Release to Users</TableCell>
                        <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Dedicated Role</TableCell>
                        {props.delete && <TableCell></TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.requests && props.requests.length > 0 &&
                        props.requests.map((request, index) => {
                            return (
                                <TableRow key={index}>
                                    {props.requestId && (
                                        <TableCell component="th" scope="row">
                                            <Typography>
                                                {request.transactionRequestId}
                                            </Typography>
                                        </TableCell>
                                    )}
                                    <TableCell component="th" scope="row">
                                        <Typography>
                                            <LinkRouter key={index} color="textSecondary" to={getRouteTo(NavRoutes.EditRequest, [request.transactionRequestId])}>
                                                {request.transactionName}
                                            </LinkRouter>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Chip
                                            icon={<SettingsIcon />}
                                            label={getRequestStatusText(request)} />
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2">
                                            {request.ownerFullName}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2">
                                            {renderSodActivityChips(request.sodActivity)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2">
                                            {renderChips(request.similarTransactionReference)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2">
                                            {request.transactionMode}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2">
                                            {request.releaseToUsers}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2">
                                            {request.dedicatedRole}
                                        </Typography>
                                    </TableCell>
                                    {/*{props.delete && props.deleteHandler && (*/}
                                    {/*    <TableCell align="right">*/}
                                    {/*        <Button*/}
                                    {/*            color="secondary"*/}
                                    {/*            disabled={props.isDisabled || request.alreadyApprovedOnce}*/}
                                    {/*            size="small"*/}
                                    {/*            variant="contained"*/}
                                    {/*            onClick={() => deleteClickHandler(index)}>*/}
                                    {/*            Delete*/}
                                    {/*        </Button>*/}
                                    {/*    </TableCell>*/}
                                    {/*)}*/}
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>

        return <Table>
            <TableHead>
                <TableRow className={props.blackHeader ? classes.blackHeader : null}>
                    {props.requestId && (
                        <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>#</TableCell>
                    )}
                    <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Transaction Name</TableCell>
                    <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Opener</TableCell>
                    <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Status</TableCell>
                    <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Duration</TableCell>
                    {props.attachments && (
                        <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Attachments</TableCell>
                    )}
                    {props.delete && <TableCell></TableCell>}
                </TableRow>
            </TableHead>
            <TableBody>
                {props.requests && props.requests.length > 0 &&
                    props.requests.map((request, index) => {
                        return (
                            <TableRow key={index}>
                                {props.requestId && (
                                    <TableCell component="th" scope="row">
                                        <Typography>
                                            {request.transactionRequestId}
                                        </Typography>
                                    </TableCell>
                                )}
                                <TableCell component="th" scope="row">
                                    <Typography>
                                        <LinkRouter key={index} color="textSecondary" to={getRouteTo(NavRoutes.EditRequest, [request.transactionRequestId])}>
                                            {request.transactionName}
                                        </LinkRouter>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {request.ownerFullName}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Chip
                                        icon={<SettingsIcon />}
                                        label={getRequestStatusText(request)} />
                                </TableCell>
                                <TableCell>
                                    <Chip
                                        color="primary"
                                        variant="outlined"
                                        icon={<SpeedIcon />}
                                        label={getRequestPerformanceText(request.status, request.performance)} />
                                </TableCell>
                                {props.attachments && (
                                    <TableCell>
                                        {request.sharepointFolderUrl ?
                                            <Link key={index} color="textSecondary" href={request.sharepointFolderUrl} target="_blank" rel="noopener">
                                                <Typography>See attachments</Typography>
                                            </Link>
                                            :
                                            <Typography variant="subtitle1">Not Available</Typography>}
                                    </TableCell>
                                )}
                                {props.delete && props.deleteHandler && (
                                    <TableCell align="right">
                                        <Button
                                            color="secondary"
                                            disabled={props.isDisabled || request.alreadyApprovedOnce}
                                            size="small"
                                            variant="contained"
                                            onClick={() => deleteClickHandler(index)}>
                                            Delete
                                        </Button>
                                    </TableCell>
                                )}
                            </TableRow>
                        );
                    })}
            </TableBody>
        </Table>;
    }

    return (
        <>
            <Hidden smDown>
                {renderTable()}
            </Hidden>
            <Hidden mdUp>
                <Table>
                    <TableHead>
                        <TableRow className={props.blackHeader ? classes.blackHeader : null}>
                            <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Request</TableCell>
                            <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Info</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.requests && props.requests.length > 0 &&
                            props.requests.map((request, index) => {
                                return (
                                    <TableRow
                                        key={index}
                                        hover
                                        onClick={(e) => rowClickHandler(index)}
                                        selected={selectedRequest === index && props.delete && props.deleteHandler}>
                                        <TableCell component="th" scope="row">
                                            <Typography>
                                                <LinkRouter key={index} color="textSecondary" to={getRouteTo(NavRoutes.EditRequest, [request.transactionRequestId])}>
                                                    {request.customerName}
                                                </LinkRouter>
                                            </Typography>
                                            <Typography>
                                                {request.ownerFullName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Chip
                                                        icon={<SettingsIcon />}
                                                        label={getRequestStatusText(request)} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Chip
                                                        color="primary"
                                                        variant="outlined"
                                                        icon={<SpeedIcon />}
                                                        label={getRequestPerformanceText(request.performance)} />
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
                {props.delete && props.deleteHandler && (
                    <Drawer
                        anchor="bottom"
                        open={selectedRequest !== null}
                        PaperProps={{ elevation: 1 }}
                        variant="persistent">
                        <Grid className={classes.drawerHeight} container justify="center" alignItems="center">
                            <Grid className={classes.drawerButton} item>
                                <Button
                                    disabled={props.isDisabled}
                                    onClick={() => deleteClickHandler()}
                                    className={classes.drawerButton}>
                                    <DeleteIcon className={classes.buttonIcon} />
                                    Delete
                                </Button>
                            </Grid>
                        </Grid>
                    </Drawer>
                )}
            </Hidden>
        </>
    );
}

