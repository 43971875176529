import React, { useState, useContext } from 'react';

import {
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Tooltip,
    CircularProgress
} from '@material-ui/core';

import { HttpMethods, FetchContext, ApiRoutes, ToastContext } from '../contexts';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/CloudDownload';

import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        padding: 6,
    },
    image: {
        width: 130
    },
    info: {
        marginTop: 1
    },
    list: {
        maxHeight: 320
    },
}));

export const FileList = (props) => {
    const classes = useStyles();
    const { fetchAuthorized } = useContext(FetchContext);
    const { showSuccessMessage } = useContext(ToastContext);

    const [fileDownloadStatus, setFileDownloadStatus] = useState(
        (props.files || []).map((file) => ({ storedFileId: file.storedFileId, isDownloading: false })));

    const getFileDownloadStatus = (storedFileId) => {
        const file = fileDownloadStatus.find(x => x.storedFileId === storedFileId);
        return file ? file.isDownloading : false;        
    }

    const downloadFileClickHandler = async (storedFile) => {

        try {            
            
            const storedFileId = storedFile.storedFileId;
            setFileDownloadStatus((prevState) => {
                let file = prevState.find(x => x.storedFileId === storedFileId);
                if(!file) {
                    file = { storedFileId, isDownloading: true };
                    prevState.push(file);
                } else {
                    file.isDownloading = true;
                }
                return prevState;
            });

            const r = await fetchAuthorized(ApiRoutes.File.Download({ storedFileId }),
            {                    
                method: HttpMethods.GET
            });            

            const blob = await r.blob();
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = storedFile.fileName;
            document.body.appendChild(a); 
            a.click();    
            a.remove();

            notifyFileDownload(storedFile);
            
            showSuccessMessage(`File ${storedFile.fileName} is now downloading`);

            setFileDownloadStatus((prevState) => {
                prevState.find(x => x.storedFileId === storedFileId).isDownloading = false;
                return prevState;
            });

        } catch(e) {
            console.debug(e);
        }
    }

    const notifyFileDownload = (storedFile) => {
        const { onFileDownload } = props;
        if(onFileDownload) onFileDownload(storedFile);
    }

    const removeFileClickHandler = (storedFile) => {
        notifyFileRemoved(storedFile);
    }

    const notifyFileRemoved = (storedFile) => {
        const { onFileRemoved } = props;
        if(onFileRemoved) onFileRemoved(storedFile);
    }
    
    return (
        <div className={classes.root}>
            <Typography
                gutterBottom
                variant="h5">
                {props.title}
            </Typography>       
            {props.files && props.files.length > 0 ?  
                <List className={classes.list}>
                    {(props.files || []).map((storedFile, i) => (
                        <ListItem
                            divider={i < (props.files || []).length - 1}
                            key={i}>
                            <ListItemIcon>
                                <FileCopyIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={storedFile.fileName}
                                primaryTypographyProps={{ variant: 'h6' }} />
                            {getFileDownloadStatus(storedFile.storedFileId) ?
                                <CircularProgress size="1.6em" color="primary" />
                                :
                                <>
                                    <Tooltip title="Download">
                                        <IconButton                                
                                            color="primary"
                                            edge="end"
                                            onClick={() => downloadFileClickHandler(storedFile)}>
                                            <DownloadIcon />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            }
                            {!props.disabled &&
                                <Tooltip title="Remove">
                                    <IconButton                                
                                        color="primary"
                                        edge="end"
                                        disabled={props.disabled}
                                        onClick={() => removeFileClickHandler(storedFile)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        </ListItem>
                    ))}
                </List>

                :

                <Typography
                    color="textSecondary"
                    variant="body1">
                        No files attached
                </Typography>
            }
        </div>
    );
}