import React, { useEffect, useContext } from 'react';
import { useParams} from "react-router";

import {
    LinearProgress,
    Container
} from '@material-ui/core';

import { RolesProvider, RolesContext } from './RolesContext';
import { NavContext } from '../../../contexts';

import {    
    RoleHeader,
    UserList    
} from './components';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
    root: {
    }
});

const PageContent = (props) => {    
    const { addBreadcrumbToken } = useContext(NavContext);    
    
    const { 
        state,
        getRole,
        isLoadingRole
    } = useContext(RolesContext);
    
    const { roleId } = useParams();
    useEffect(() => {        
        getRole(roleId);
    }, [roleId])

    useEffect(() => {                
        if(state.role) {
            addBreadcrumbToken(state.role.groupName);
        }
    }, [state.role]);

    return (
        <>
            {isLoadingRole &&
                <LinearProgress color="primary" />
            }           
            {state.role &&
                <Container maxWidth="lg">            
                    <React.Fragment>
                        <RoleHeader />
                        <UserList />
                    </React.Fragment>
                </Container>
            }
        </>
    );
}

export const RolePage = (props) => {
    return (
        <RolesProvider>
            <PageContent />
        </RolesProvider>
    );
}