import React, { useContext, useEffect, useState } from 'react';

import {
    Stepper,
    Step,
    StepLabel,
    Link,
    Hidden,
    Grid,
    Button,
    Collapse
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { TransactionRequestContext } from '../TransactionRequestContext';
import { NavContext, NavRoutes, UserContext } from '../../../contexts';

import { ApplicationRoles, RequestStatus } from '../../../../helpers';

import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    history: {
        marginBottom: theme.spacing(1)
    },
    attachments: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    button: {
        width: "100%"
    },
    actionsContainer: {
        display: "flex",
        direction: "row"
    }
}));

export const RequestStepper = () => {
    const classes = useStyles();
    const { navigateTo } = useContext(NavContext);

    const {
        state
    } = useContext(TransactionRequestContext);

    const {
        isUserInRole,
    } = useContext(UserContext);

    const [isCollapseOpen, setIsCollapseOpen] = useState(false)

    const allSteps = [
        { title: "Draft", status: RequestStatus.Draft },
        { title: "Rework", status: RequestStatus.Rework },
        { title: "Ciso Office", status: RequestStatus.WaitingForCiso },
        { title: "Business Team", status: RequestStatus.WaitingForBusiness },
        { title: "TCodeMap Repository", status: RequestStatus.WaitingForApproval },
        { title: "TCodeCatalogue", status: RequestStatus.Approved }
    ];

    const filterSteps = () => {

        /* 
         * Remember to remove steps backward
         * in this way every remove is not affected
         * by the index change on removal
         * 
         */

        let steps = [...allSteps];
        switch (state.request.status) {

            case RequestStatus.Draft:
            case RequestStatus.WaitingForApproval:
            case RequestStatus.Approved:
                steps.splice(1, 1); // remove rework
                break;

            case RequestStatus.WaitingForCiso:
            case RequestStatus.WaitingForBusiness:
                if (!state.request.reworkInProgress)
                    steps.splice(1, 1); // remove rework
                break;

            default:
                break;
        }

        return steps;
    }

    const getSteps = () => {
        return filterSteps().map((step) =>
            <Step key={step.title} color="primary">
                <StepLabel>{step.title}</StepLabel>
            </Step>
        );
    }

    const [activeStep, setActiveStep] = useState(0);
    useEffect(() => {
        let target = state.request.status;

        const index = filterSteps().findIndex((step) => step.status === target);

        setActiveStep(index);
    }, [state.request.status]);

    const isOpener = isUserInRole(ApplicationRoles.Opener);
    const isBusiness = isUserInRole(ApplicationRoles.Business);
    const isCiso = isUserInRole(ApplicationRoles.Ciso);
    const isAdmin = isUserInRole(ApplicationRoles.Administrator);

    return (
        <div className={classes.root}>
            <div className={classes.actionsContainer}>

                {(isOpener || isBusiness || isCiso || isAdmin) &&
                    (state.request.status !== RequestStatus.Draft) &&
                    <Link
                        component="button"
                        variant="caption"
                        className={classes.history}
                        onClick={() => {
                            navigateTo(NavRoutes.RequestHistory, [state.request.transactionRequestId])
                        }}>
                        View request history
                    </Link>
                }
                {state.request.sharepointFolderUrl &&
                    <Link
                        target="_blank"
                        rel="noopener"
                        variant="caption"
                        className={classes.attachments}
                        href={state.request.sharepointFolderUrl}>
                        See attachments
                    </Link>
                }
            </div>
            <Hidden mdUp>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <Button
                            className={classes.button}
                            variant="outlined"
                            color="primary"
                            onClick={() => setIsCollapseOpen(!isCollapseOpen)}
                            endIcon={isCollapseOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}>
                            Request status
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Collapse in={isCollapseOpen}>
                            <Stepper orientation="vertical" activeStep={activeStep}>
                                {getSteps()}
                            </Stepper>
                        </Collapse>
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden smDown>
                <Stepper alternativeLabel activeStep={activeStep}>
                    {getSteps()}
                </Stepper>
            </Hidden>
        </div>
    );
}
