import React, { useContext, useMemo } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { TransactionRequestsContext } from '../TransactionRequestsContext';
import { Grid, ButtonGroup, Button, IconButton, Divider, LinearProgress } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { TextField } from 'formik-material-ui';
import { Select, } from '../../../common'
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: 20
    },
    field: {
        marginBottom: 10,
        marginTop: 10,
        width: '100%'
    }
}));

// Form with an editable list of filterproperty object.
// Next to each input are buttons for insert and remove.
// If the list is empty, there is a button to add an item.
export const FilterList = ({ onSubmit }) => {
    const classes = useStyles();

    const {
        state,
        isLoadingPropertyList
    } = useContext(TransactionRequestsContext);

    const propertyOptions = useMemo(() => state.propertyList
        .map((item) =>
            ({ value: item.name, label: item.displayName })
        ), [state.propertyList])

    const { filter } = state;
    const getInitialValues = () => {
        if (filter && filter.propertyFilters && filter.propertyFilters.length > 0)
            return {
                filters: filter.propertyFilters
            }
        const defaultPropertyFilters = ["Code", "Description", "RelevantParameters", "Output"];
        return {
            filters: state.propertyList
                .filter(p => defaultPropertyFilters.includes(p.name))
                .map(({ displayName, ...rest }) => rest)
        }
    };

    const renderFilterValueField = (filter, index) => {
        return <Field
            name={`filters.${index}.value`}
            type="text"
            className={classes.field}
            variant="outlined"
            label="Value to filter against"
            component={TextField}
            size="small" />
    }

    return (
        <div>
            {isLoadingPropertyList && (
                <LinearProgress color="primary" />
            )}
            <Formik
                initialValues={getInitialValues()}
                onSubmit={(values) => {
                    return onSubmit(values);
                }}
            >
                {({ values }) => {
                    return (
                        <Form noValidate autoComplete="off">
                            <FieldArray
                                name="filters"
                                render={(arrayHelpers) => (
                                    <div>
                                        {values.filters && (
                                            values.filters.map((filter, index) => (
                                                <div key={index}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={4}>
                                                            <Select
                                                                name={`filters.${index}.name`}
                                                                label="Property"
                                                                size="small"
                                                                className={classes.field}
                                                                options={propertyOptions}>
                                                            </Select>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {renderFilterValueField(filter, index)}
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <ButtonGroup
                                                                variant="text"
                                                                color="primary"
                                                                aria-label="text primary button group"
                                                                className={classes.field}
                                                            >
                                                                <IconButton
                                                                    onClick={() => arrayHelpers.remove(index)} // remove a filter from the list
                                                                    aria-label="delete">
                                                                    <DeleteIcon fontSize="small" />
                                                                </IconButton>
                                                                <IconButton
                                                                    onClick={() => arrayHelpers.insert(index + 1, "")} // insert empty filter at a position
                                                                    aria-label="add">
                                                                    <AddIcon fontSize="small" />
                                                                </IconButton>
                                                            </ButtonGroup>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider variant="middle" />
                                                </div>
                                            ))
                                        )}
                                        {values.filters && values.filters.length === 0 &&
                                            <Grid container spacing={3}>
                                                <Grid item xs={4}>
                                                    <Button
                                                        size="small"
                                                        type="button"
                                                        className={classes.saveButton}
                                                        onClick={() => arrayHelpers.push({ name: "", value: "" })}>
                                                        Add a property filter
                                                </Button>
                                                </Grid>
                                            </Grid>}
                                        <Grid container spacing={3}>
                                            <Grid item xs={4}>
                                                <Button
                                                    size="small"
                                                    type="submit"
                                                    className={classes.saveButton}>
                                                    Submit
                                            </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )} />
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};
