import React, { Fragment, useContext, useEffect } from 'react';

import {
    Paper,
    List,
    ListSubheader,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@material-ui/core';

import { TablesContext } from '../TablesContext';

import ListAltIcon from '@material-ui/icons/ListAltOutlined'

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    root: {
    },
    list: {
        height: "100%",
        overflow: "auto"
    },
    notables: {
        padding: "16px"
    }
}));

export const TableList = (props) => {
    const classes = useStyles();

    const {
        state,
        setSelectedTableId
    } = useContext(TablesContext);

    const {
        tables,
        complexTables
    } = state;

    const getTables = () => tables.filter(x => x.valueType === props.tableValueType);

    const itemSelectHandler = (tableId) => {
        setSelectedTableId(tableId);
    }

    const tableListOrder = [
        "Applications",
        "Areas",
        "Processes",
        "Sub Processes",
        "Companies",
        // "SoD Activities",
        // "Risks",
        "Sensitive Activities",
        // "Compensatory Controls",
        "Release to Users",
        "Tables"];

    const renderListItems = () => {

        const complexItems = complexTables && complexTables.map((table, index) => (
            <ListItem button key={table.name} selected={state.selectedTableId === table.tableDefinitionId}
                onClick={() => itemSelectHandler(table.tableDefinitionId)}>
                <ListItemIcon>
                    <ListAltIcon />
                </ListItemIcon>
                <ListItemText primary={table.name} />
            </ListItem>
        ));

        const items = getTables() && getTables().map((table, index) => (
            <ListItem button key={table.name} selected={state.selectedTableId === table.tableDefinitionId}
                onClick={() => itemSelectHandler(table.tableDefinitionId)}>
                <ListItemIcon>
                    <ListAltIcon />
                </ListItemIcon>
                <ListItemText primary={table.name} />
            </ListItem>
        ))

        let all = [...complexItems, ...items];

        return all.slice().sort(function (a, b) {
            return tableListOrder.indexOf(a.key) - tableListOrder.indexOf(b.key);
        });
    }

    return (
        <>
            {state.tables.length > 0 ?
                <Paper className={classes.root}>
                    <List
                        className={classes.list}>
                        {renderListItems()}
                    </List>
                </Paper>
                :
                <Paper className={classes.notables}>
                    <Typography variant="h6">
                        No tables available
                    </Typography>
                    <Typography variant="body1" style={{ marginTop: 8 }}>
                        There's no tables avaiable at this time
                    </Typography>
                </Paper>
            }
        </>
    );
}