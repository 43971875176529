import React, { useContext } from 'react';
import { Pie } from 'react-chartjs-2';

import { 
    Card,
    Grid,
    CardHeader,
    Typography,
    CardContent
 } from '@material-ui/core';

import {HomeContext} from '../HomeContext'
import {mapColorsToLabels} from '../../../../helpers'

import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    skeleton:{
        margin: theme.spacing(1)
    }
}));

export const AreaRequests = (props) => {
    const classes = useStyles();

    const { 
        state,    
        isLoadingStats,    
    } = useContext(HomeContext);

    const getData = () => {

        let labels = [];
        let data = [];

        if(state.stats && state.stats.totals){
            state.stats.totals.forEach(x => {
               if(x.type === "area"){
                   labels.push(x.groupName)
                   data.push(x.total)
               }
           });
        }

        return { labels, datasets: [ { data, backgroundColor: mapColorsToLabels(labels) } ]};
    }

    return (
        <Card>
            <CardContent>
                {isLoadingStats && (
                    <Grid container justify="center">
                        <Grid item xs={12} xl={12}>
                            <Skeleton className={classes.skeleton} variant="rect" width="100%" height={50} />
                        </Grid>
                        <Grid item >
                            <Skeleton className={classes.skeleton} variant="circle" width={100} height={100} />
                        </Grid>
                        <Grid item xs={12} xl={12}>
                            <Skeleton className={classes.skeleton} variant="text" />
                        </Grid>
                    </Grid>
                )}
                {!isLoadingStats && (
                    <>
                    <CardHeader title={`Requests by area - ${new Date().getFullYear()}`}/>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography align="center" variant="body2">
                                Data about Transactions in Catalogue only
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Pie legend={{ "position": "bottom" }} data={getData()} />
                        </Grid>
                    </Grid>
                    </>
                )}
            </CardContent>
        </Card>
    );
}