import React, { useContext, useEffect, useState } from 'react';
import clonedeep from 'lodash.clonedeep'

import {
    Card,
    CardHeader,
    CardContent,
    Grid,
    Button,
    CardActions,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Avatar,
    Typography,
    Chip
} from '@material-ui/core';

import { SearchUser } from '../../../../common';

import { RolesContext } from '../RolesContext';
import { NavContext, NavRoutes, FetchContext, ApiRoutes, HttpMethods } from '../../../../contexts';
import { ApplicationRoles, SapTableNames, LocalTableNames, } from '../../../../../helpers';

import PersonIcon from '@material-ui/icons/PersonOutlined'

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    formGroup: {
        width: '100%',
    },
    select: {
        width: '100%'
    },
    user: {
        display: 'flex',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1)
    },
    email: {
        marginLeft: theme.spacing(1)
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    changeButton: {
        marginLeft: theme.spacing(3)
    }   
}));

export const RoleAssigmentForm = (props) => {
    const classes = useStyles();
    const { fetchAuthorized } = useContext(FetchContext);
    const { navigateTo } = useContext(NavContext);

    const {
        state,
        isAddingInRole,
        addUserInRole,
        isUpdatingInRole,
        updateUserInRole,
        getTableOptionsWithCode,
        getTable,
        getTableValues
    } = useContext(RolesContext);

    const loadUsers = (inputValue, callback) => {
        return fetchAuthorized(ApiRoutes.Graph.GetUsers({ userFullPartialName: inputValue }),
            {
                method: HttpMethods.GET
            })
            .then(r => r.json())
            .then(users => callback(users))
            .catch(error => {
                console.error(error);
                callback([]);
            });
    }

    const getTableValuesItems = (tableName) => {
        if (state.tables && state.tables.length > 0) {
            const table = getTable(tableName);
            if (table && table.childValues) {
                return table.childValues.map(
                    (item, index) => (<MenuItem key={index} value={item.tableValueId}>{item.value}</MenuItem>));
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    const getTableValuesItemsCode = (tableName) => {
        if (state.tables && state.tables.length > 0) {
            const table = getTable(tableName);
            if (table && table.childValues) {
                return table.childValues.map(
                    (item, index) => (<MenuItem key={index} value={item.tableValueId}>{item.externalKey} - {item.value}</MenuItem>));
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    const getTableItemValue = (tableName, valueId) => {
        const table = getTable(tableName);
        if (table && table.childValues) {
            const item = table.childValues.find(x => x.tableValueId === valueId);
            if (item) {
                return item.externalKey + " - " + item.value;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    const [userKey, setUserKey] = useState(null);
    const userChangeHandler = (user) => {
        setUserKey(user ? user.id : null);
    }

    const [salesGroupIds, setSalesGroupIds] = useState([]);
    const salesGroupSelectHandler = (e) => {
        setSalesGroupIds(e.target.value);
    }

    const [businessCategoryId, setBusinessCategoryId] = useState("");
    const businessCategorySelectHandler = (e) => {
        setBusinessCategoryId(e.target.value);
    }

    const [companiesIds, setCompaniesIds] = useState([]);
    const [companiesNumber, setCompaniesNumber] = useState(0);
    const companiesSelectHandler = (e) => {
        setCompaniesIds(e.target.value);
    }

    const [salesOrgIds, setSalesOrgIds] = useState([]);
    const salesOrgsSelectHandler = (e) => {
        setSalesOrgIds(e.target.value);
    }

    const [customerGroupsIds, setCustomerGroupsIds] = useState([]);
    const customerGroupsSelectHandler = (e) => {
        setCustomerGroupsIds(e.target.value);
    }

    const [countriesIds, setCountriesIds] = useState([]);
    const countriesSelectHandler = (e) => {
        setCountriesIds(e.target.value);
    }

    const saveClickHandler = async () => {

        let valueIds = [];
        switch (state.role.name) {

            default:
                break;
        }

        let success = false;
        if (!props.editMode) {
            success = await addUserInRole({
                userKey: userKey,
                roleId: state.role.applicationRoleId,
                valueIds
            });
        } else {
            success = await updateUserInRole({
                userKey: state.user.externalKey,
                roleId: state.role.applicationRoleId,
                valueIds
            });
        }

        if (success)
            navigateTo(NavRoutes.Admin.EditRole, [state.role.applicationRoleId]);
    }

    useEffect(() => {
        if (companiesIds) {
            if (companiesIds.length === 0) {
                setSalesOrgIds([])
            } else {
                const salesOrgs = getTableValues(SapTableNames.SalesOrganizations, companiesIds).map(x => x.tableValueId);
                let newSalesOrgs = clonedeep(salesOrgIds);
                newSalesOrgs = newSalesOrgs.filter(id => salesOrgs.includes(id));
                setSalesOrgIds(newSalesOrgs)
            }
        }

    }, [companiesIds])

    useEffect(() => {
        if (state.tables.length > 0
            && state.role != null
            && state.user != null) {
            switch (state.role.name) {

                default:
                    break;
            }
        }
    }, [state.tables, state.role, state.user])

    const [allowSave, setAllowSave] = useState(false);
    useEffect(() => {
        if (state.role != null) {
            switch (state.role.name) {

                default:
                    setAllowSave(userKey !== null);
                    break;
            }
        }
    }, [state.role, state.user, userKey, salesGroupIds, businessCategoryId, customerGroupsIds, countriesIds, companiesIds, salesOrgIds])

    const [disabled, setDisabled] = useState(false);
    useEffect(() => {
        setDisabled(isAddingInRole || isUpdatingInRole);
    }, [isAddingInRole, isUpdatingInRole])

    return (
        <Card className={classes.root}>
            <CardHeader title={!state.user ?
                `Add user in the '${state.role ? state.role.groupName : "unknown"}' group` : `Edit user in the '${state.role ? state.role.groupName : "unknown"}' group`} />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {props.editMode && state.user ?
                            <>
                                <Typography variant="overline" className={classes.label}>Target User</Typography>
                                <div className={classes.user}>
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <PersonIcon />
                                    </Avatar>
                                    <Typography>
                                        {state.user.displayName}
                                    </Typography>
                                    <Typography className={classes.email}>
                                        ({state.user.email || "no email"})
                                    </Typography>
                                </div>
                            </>
                            :
                            <div className={classes.formGroup}>
                                <SearchUser
                                    label="Target user"
                                    getOptionLabel={option => `${option.displayName} (${option.mail})`}
                                    getOptionValue={option => option.id}
                                    loadOptions={loadUsers}
                                    disabled={disabled}
                                    onChange={userChangeHandler} />
                            </div>
                        }
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={!allowSave || disabled}
                    onClick={saveClickHandler}>
                    {props.editMode ? "Save Changes" : "Add User"}
                </Button>
            </CardActions>
        </Card>
    );
}