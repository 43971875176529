import React, { useEffect, useContext, useState } from 'react';
import { useParams } from "react-router";
import { useLocation } from 'react-router-dom';

import {
    LinearProgress
} from '@material-ui/core';

import { TransactionRequestContext, TransactionRequestProvider as TransactionRequestProvider } from './TransactionRequestContext';
import { NavContext, NavRoutes, UserContext } from '../../contexts';

import { RequestStatus, ComplexEntityNames, ApplicationRoles } from '../../../helpers'

import {
    TabsContainer,
} from './components';

const PageContent = ({ readOnly }) => {

    const {
        setBreadcrumbTokens,
        setBreadcrumbRoot,
        navigateTo
    } = useContext(NavContext);

    const {
        userInfo,
        isUserInRole,
        clonedRequest
    } = useContext(UserContext);

    const location = useLocation();

    const {
        state,
        isLoadingDefs,
        getChoosenTableDefinitions,
        loadRequest,
        setRequest,
        createEmptyRequest,
        isLoadingComplexOptions,
        loadComplexOptions,
        isLoadingCompletedRequestOptions,
        loadCompletedRequestOptions,
        getPreviousStatus,
        isLoadingPreviousStatus
    } = useContext(TransactionRequestContext);

    const {
        request
    } = state;

    const [isLoadingData, setIsLoadingData] = useState(true);
    useEffect(() => {
        setIsLoadingData(isLoadingDefs
            || isLoadingComplexOptions
            || isLoadingCompletedRequestOptions
            || isLoadingPreviousStatus
            || request === null);

    }, [isLoadingDefs,
        isLoadingComplexOptions,
        isLoadingCompletedRequestOptions,
        request]);

    const { requestId } = useParams()
    useEffect(() => {
        getChoosenTableDefinitions();
        loadComplexOptions(
            ComplexEntityNames.SodActivity,
            ComplexEntityNames.Risk,
            ComplexEntityNames.Company
        );
        loadCompletedRequestOptions();
        if (requestId) {
            getPreviousStatus(requestId);
            loadRequest(requestId);
        }
        else if (clonedRequest) {
            setRequest(clonedRequest);
        }
        else createEmptyRequest();
    }, [requestId]);

    useEffect(() => {
        if (request != null) {

            const finalToken = requestId ? { title: "Edit Request", url: location.pathname } : { title: "New Request", url: location.pathname }
            if (requestId) {
                if (request) {
                    if (request.status === RequestStatus.Draft) {
                        setBreadcrumbTokens([{ title: "Draft Transactions", url: NavRoutes.DraftRequests }, finalToken])
                    } else if (request.status === RequestStatus.Approved) {
                        setBreadcrumbTokens([{ title: "TCodeMap Repository", url: NavRoutes.UndeletedRequests }, finalToken])
                    } else if (request.status === RequestStatus.Rework) {
                        setBreadcrumbTokens([{ title: "Rework Requests", url: NavRoutes.ReworkRequests }, finalToken])
                    } else if (request.status !== RequestStatus.Rework && request.reworkInProgress) {
                        setBreadcrumbTokens([{ title: "Reworked Transactions", url: NavRoutes.ReworkedRequests }, finalToken])
                    } else {
                        setBreadcrumbTokens([{ title: "Running Requests", url: NavRoutes.RunningRequests }, finalToken])
                    }
                }
            } else {
                setBreadcrumbRoot("New Request");
            }
        }

    }, [request]);

    const isUserOpener = isUserInRole(ApplicationRoles.Opener);
    const isUserTheOwner = request && request.ownerKey === userInfo.externalKey;
    const isUserOpenerAndOwner = isUserOpener && isUserTheOwner;
    const isNewDraft = request && !request.isInCatalogue && request.status === RequestStatus.Draft;
    if (!readOnly && isNewDraft && !isUserOpenerAndOwner) {
        navigateTo(NavRoutes.Forbidden);
    }

    return (
        <>
            {isLoadingData &&
                <LinearProgress color="primary" />
            }
            {state.request && !isLoadingData &&
                <TabsContainer
                    editMode={!readOnly} />
            }
        </>
    );
}

export const TransactionRequestPage = () => {
    return (
        <TransactionRequestProvider>
            <PageContent readOnly={false} />
        </TransactionRequestProvider>
    );
}

export const TransactionRequestPageReadOnly = () => {
    return (
        <TransactionRequestProvider>
            <PageContent readOnly={true} />
        </TransactionRequestProvider>
    );
}