import React, { useContext, useEffect, useState } from 'react';

import {
    Paper,
    LinearProgress,
    TextField,
    Grid,
    IconButton,
    TablePagination,
    Tooltip
} from '@material-ui/core';

import { TransactionRequestsContext } from '../TransactionRequestsContext';
import { NavContext, UserContext } from '../../../contexts';

import { RequestsList } from '../../../common'
import { ApplicationRoles, RequestStatus } from '../../../../helpers';
import { FilterList } from './FilterList';

import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import FilterListIcon from '@material-ui/icons/FilterList';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        overflow: 'hidden',
    },
    author: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(1)
    },
    table: {

    },
    status: {
    },
    performance: {
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        width: "100%"
    },
    searchField: {
        float: "right",
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: "100%"
    }
}));

export const RequestsTable = (props) => {
    const classes = useStyles();

    const {
        getCurrentLocation
    } = useContext(NavContext);

    const {
        isUserInRole,
        userInfo,
        setDraftRequests,
        draftRequests
    } = useContext(UserContext);

    const {
        state,
        deleteDraftRequest,
        abortRequest,
        loadRequests,
        isSearching,
        isDeletingRequest,
        isAbortingRequest,
        isExporting,
        getExport,
        setFilter,
        loadPropertyList
    } = useContext(TransactionRequestsContext);

    const { filter } = state;

    const [isSearchingMode, setIsSearchingMode] = useState(false)

    const deleteRequestClickHanlder = async (request) => {
        if (request) {
            if (isUserInRole(ApplicationRoles.Opener) && request.status === RequestStatus.Draft && request.ownerKey === userInfo.externalKey) {
                const success = await deleteDraftRequest(request.transactionRequestId);
                if (success) {
                    setDraftRequests(draftRequests - 1);
                }
            } else if (isUserInRole(ApplicationRoles.Administrator)) {
                await abortRequest(request.transactionRequestId)
            }
        }
    }

    const canDelete = () => {
        const location = getCurrentLocation();
        return location.pathname.includes("/draft")
            || (isUserInRole(ApplicationRoles.Administrator)
                && !location.pathname.includes("/completed"));
    }

    const isLoading = () => {
        return isSearching || isAbortingRequest || isDeletingRequest;
    }

    const getRequests = (passedFilter) => {
        const text = passedFilter.searchText;

        if (text && text !== "") {
            setIsSearchingMode(true)
        } else {
            setIsSearchingMode(false)
        }

        loadRequests(passedFilter);
    }

    const getExportHandler = () => {
        getExport(filter);
    }

    const keyPressHandler = (e) => {
        if (e.key === 'Enter') {
            resetPageIndex();
            getRequests(filter);
        }
    }
    const setFilterAndGetRequests = (filterUpdates) => {
        setFilter(filterUpdates);
        getRequests({ ...filter, ...filterUpdates });
    }

    const closeSearchHandler = () => {
        setFilterAndGetRequests({ searchText: "" });
    }

    const filtersSubmitHandler = (values) => {
        setFilterAndGetRequests({ propertyFilters: values.filters });
    };

    const changePageHandeler = (event, newPage) => {
        setFilterAndGetRequests({ pageIndex: newPage });
    };

    const resetPageIndex = () => {
        setFilterAndGetRequests({ pageIndex: 0 });
    }

    const changePageSizeHandeler = event => {
        setFilterAndGetRequests({
            pageIndex: 0,
            pageSize: parseInt(event.target.value, 10)
        });
    };

    const [showInChargeOf, setShowInChargeOf] = useState(false);
    const defaultRowsPerPage = [10, 20, 30];
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

    useEffect(() => {
        const location = getCurrentLocation();

        if (location.pathname.includes("/draft")) {
            setFilter({ category: 0 });
            setShowInChargeOf(false);
            setRowsPerPage(defaultRowsPerPage);
        } else if (location.pathname.includes("/running")) {
            setFilter({ category: 1 });
            setShowInChargeOf(true);
            setRowsPerPage(defaultRowsPerPage);
        } else if (location.pathname.includes("/completed")) {
            const pSize = filter.pageSize === 10 ? 50 : filter.pageSize;
            setFilter({ category: 2, pageSize: pSize }); // not deleted, in transaction
            setShowInChargeOf(false);
            setRowsPerPage([50, 100, 200]);
        } else if (location.pathname.includes("/repository")) {
            const pSize = filter.pageSize === 10 ? 50 : filter.pageSize;
            setFilter({ category: 6, pageSize: pSize }); // mapped, all not deleted
            setShowInChargeOf(false);
            setRowsPerPage([50, 100, 200]);
        } else if (location.pathname.includes("/all")) {
            const pSize = filter.pageSize === 10 ? 50 : filter.pageSize;
            setFilter({ category: 3, pageSize: pSize });
            setShowInChargeOf(true);
            setRowsPerPage([50, 100, 200]);
        } else if (location.pathname.includes("/reworked")) {
            setFilter({ category: 5 });
            setShowInChargeOf(true);
            setRowsPerPage(defaultRowsPerPage);
        } else if (location.pathname.includes("/rework")) {
            setFilter({ category: 4 });
            setShowInChargeOf(true);
            setRowsPerPage(defaultRowsPerPage);
        }

        loadPropertyList();
    }, []);

    const [showFilterList, setShowFilterList] = useState(false);
    return (
        <>
            <Grid container justify="flex-end">
                <Grid item xl={5} xs={12}>
                    <Paper className={classes.searchField}>
                        <Grid spacing={2} container alignItems="center" justify="space-between">
                            <Grid item xl={9} md={9} xs={9}>
                                <TextField
                                    onChange={(e) => setFilter({ searchText: e.target.value })}
                                    disabled={isLoading()}
                                    value={filter.searchText}
                                    onKeyPress={(e) => keyPressHandler(e)}
                                    className={classes.input}
                                    placeholder="Search Requests"
                                    inputProps={{ 'aria-label': 'search request' }}
                                />
                            </Grid>
                            <Grid item xl={1} md={1} xs={1}>
                                {isSearchingMode && (
                                    <IconButton
                                        disabled={isLoading()}
                                        onClick={() => closeSearchHandler()}
                                        className={classes.iconButton}
                                        aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                )}
                                {!isSearchingMode && (
                                    <IconButton
                                        disabled={isLoading()}
                                        onClick={() => getRequests(filter)}
                                        className={classes.iconButton}
                                        aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                )}
                            </Grid>
                            <Grid item xl={1} md={1} xs={1}>
                                <Tooltip title="Download export" aria-label="download-export">
                                    <IconButton
                                        disabled={isExporting}
                                        onClick={() => getExportHandler()}
                                        color="primary"
                                        aria-label="export"
                                        variant="outlined"
                                        className={classes.iconButton}
                                    >
                                        <GetAppIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xl={1} md={1} xs={1}>
                                <Tooltip title="Advanced filters" aria-label="advanced-filters">
                                    <IconButton
                                        onClick={() => setShowFilterList(!showFilterList)}
                                        color="primary"
                                        aria-label="toggle-filter-list"
                                        variant="outlined"
                                        className={classes.iconButton}
                                    >
                                        <FilterListIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            {showFilterList &&
                                <Grid item xs={12}>
                                    <FilterList onSubmit={values => filtersSubmitHandler(values)} />
                                </Grid>}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            {isLoading() && (
                <LinearProgress color="primary" />
            )}
            <Paper className={classes.root}>
                <TablePagination
                    rowsPerPageOptions={rowsPerPage}
                    component="div"
                    count={state.totalItems}
                    page={filter.pageIndex}
                    rowsPerPage={filter.pageSize}
                    onChangePage={changePageHandeler}
                    onChangeRowsPerPage={changePageSizeHandeler}
                />
                <RequestsList
                    requestId
                    attachments
                    isDisabled={isLoading()}
                    delete={canDelete()}
                    deleteHandler={deleteRequestClickHanlder}
                    requests={state.requests}
                    inChargeOf={showInChargeOf}
                />
            </Paper>
        </>
    );
}