import React, { useContext, useState } from 'react';
import clonedeep from 'lodash.clonedeep'

import {
    HttpMethods,
    FetchContext,
    NavContext,
    ApiRoutes,
    ToastContext,
    UserContext,
    InsightContext,
    NavRoutes
} from '../../contexts';

import {
    useAggregatedState,
    RequestStatus,
    ApplicationRoles,
    AuthPlatformTableNames,
    ComplexEntityNames
} from '../../../helpers';

import { defaultTransactionRequest } from '../../../content';

export const TransactionRequestContext = React.createContext(null);

const { Provider } = TransactionRequestContext;
export const TransactionRequestProvider = (props) => {
    const { fetchAuthorized } = useContext(FetchContext);
    const { showSuccessMessage, showWarningMessage, showErrorMessage } = useContext(ToastContext);
    const { trackError, trackEvent } = useContext(InsightContext)

    const [state, setState] = useAggregatedState({
        tables: [],

        // This data depends from the owner of the request
        salesGroups: [],
        businessCategories: [],
        companies: [],

        // This data depeneds from other fields
        respAreas: [],
        salesOrgs: [],
        salesOffices: [],
        custGroupsWithAccGroup: [],
        recoAccounts: [],
        accClerks: [],
        deliveringPlants: [],
        taxCategories: [],

        request: null,
        existingTransactionIdWithSameName: null,
        errors: [],

        submitAction: null,

        isBankInfoRequired: true,
        noSendToSap: false,
        complexOptions: {
            company: [],
            compensatoryControl: [],
            risk: [],
            sodActivity: []
        },
        completedRequestOptions: [],
        previousStatus: null,
        rejectedFields: [],

    });

    const {
        userInfo,
        isUserInRole
    } = useContext(UserContext);

    const {
        navigateTo
    } = useContext(NavContext);

    const createEmptyRequest = async () => {
        const request = { ...defaultTransactionRequest };
        request.transactionRequestId = 0;
        request.status = RequestStatus.Draft;
        request.ownerKey = isUserInRole(ApplicationRoles.Opener) ? userInfo.externalKey : null;
        request.ownerFullName = isUserInRole(ApplicationRoles.Opener) ? userInfo.displayName : null;

        // only Business has IsInCatalogue flag enabled
        request.isInCatalogue = isUserInRole(ApplicationRoles.Business);

        // only Ciso has isMappingCompleted flag enabled
        request.isMappingCompleted = isUserInRole(ApplicationRoles.Ciso);

        setState({ request });
    }

    const resetRequestOwner = (ownerKey, ownerFullName) => {
        const request = { ...state.request };
        request.ownerKey = ownerKey;
        request.ownerFullName = ownerFullName;

        setState({
            request
        });
    }

    const setIsBankInfoRequired = (isBankInfoRequired) => {
        setState({ isBankInfoRequired });
    }

    const setNoSendToSap = (noSendToSap) => {
        setState({ noSendToSap });
    }

    const [isLoadingRequest, setIsLoadingRequest] = useState(false);
    const loadRequest = async (transactionRequestId) => {
        try {
            setIsLoadingRequest(true);

            const r = await fetchAuthorized(ApiRoutes.Request.GetRequest({ transactionRequestId }),
                {
                    method: HttpMethods.GET,
                });

            const request = await r.json();
            let currentRequest = request; // otherwise I cannot use the request obj

            // map manually techFieldNotes
            let techFieldNotes = [];
            if (currentRequest.techFieldNotes) {

                // remove the prefix from the note:
                for (let i = 0; i < currentRequest.techFieldNotes.length; i++) {

                    let oldText = currentRequest.techFieldNotes[i].note;
                    let currentNoteText = (oldText.startsWith("Note from Business Team: ")) ?
                        oldText.substring(25) :
                        oldText.note;

                    techFieldNotes.push({
                        fieldName: currentRequest.techFieldNotes[i].fieldName,
                        note: currentNoteText
                    });
                }
                currentRequest.techFieldNotes = techFieldNotes;
                let obj = Object.assign({}, ...currentRequest.techFieldNotes.map(
                    item => {
                        //let note = (item.note.startWith('Note from Business Team: ') ? item.note.substring(25) : item.note);
                        return (
                            {
                                [item.fieldName + 'Note']: item.note,
                                [item.fieldName + 'Click']: true
                            })
                    }
                ))
                //console.log('obj');
                //console.log(obj);
                //console.log('merge');
                //console.log({ request: { ...currentRequest, ...obj } });
                setState({ request: { ...currentRequest, ...obj } });
            }
            else {
                setState({ request });
            }

            // map errors in state
            let newErrors = [];

            let filedsToReject = currentRequest.techFieldNotes;

            for (var obj of filedsToReject) {
                //console.log('obj:');
                //console.log(obj);
                newErrors.push({ errorId: obj.fieldName, message: obj.note });
            }

            setState({ errors: newErrors });

            setIsLoadingRequest(false);

        } catch (e) {
            console.debug(e);
            trackError(e);
            setIsLoadingRequest(false);
            if (e.code === 403) {
                navigateTo(NavRoutes.Forbidden);
            }
        }
    }

    const setRequest = (data) => {
        setState({ request: data });
    }

    const [isSavingRequest, setIsSavingRequest] = useState(false);
    const [isRejectedProp, setIsRejectedProp] = useState(false);

    const saveDraftRequest = async (data, send = false) => {
        try {
            setIsSavingRequest(true);

            const r = await fetchAuthorized(
                send ? ApiRoutes.Request.SendTransactionRequestDraft() : ApiRoutes.Request.SaveTransactionRequestDraft(),
                {
                    method: HttpMethods.POST,
                    body: JSON.stringify(data),
                    collectErrorDetails: true
                });

            if (send) {
                trackEvent("SendTransactionRequestDraft", data)
            } else {
                trackEvent("SaveTransactionRequestDraft", data)
            }

            let request = await r.json();
            request = { ...data, ...request, };
            setState({ request });

            if (send) {
                showSuccessMessage("The transaction request has been sent for approval");
            } else {
                showSuccessMessage("A draft copy of the transaction request has been saved");
            }

            setState({ errors: [] });
            setIsSavingRequest(false);
            return true;

        } catch (e) {
            console.debug(e);
            trackError(e);
            setState({ errors: e.details && e.details.length > 0 ? e.details : [] });
            setIsSavingRequest(false);
            return false;
        }
    }

    const saveReworkRequest = async (data, send = false) => {
        try {
            setIsSavingRequest(true);

            const r = await fetchAuthorized(
                send ? ApiRoutes.Request.SendRework() : ApiRoutes.Request.SaveRework(),
                {
                    method: HttpMethods.POST,
                    body: JSON.stringify(data),
                    collectErrorDetails: true
                });

            if (send) {
                trackEvent("SendRework", data)
            } else {
                trackEvent("SaveRework", data)
            }

            let request = await r.json();
            request = { ...data, ...request, };
            setState({ request });

            if (send) {
                showSuccessMessage("The rework of the transaction request has been sent for approval");
            } else {
                showSuccessMessage("Changes to the rework of the transaction request has been saved");
            }

            setState({ errors: [] });
            setIsSavingRequest(false);
            return true;

        } catch (e) {
            console.debug(e);
            trackError(e);
            setState({ errors: e.details && e.details.length > 0 ? e.details : [] });
            setIsSavingRequest(false);
            return false;
        }
    }

    const updateBusinessInfo = async (data, send = false) => {
        let techFieldNotes = [];

        if (data) {
            const messagePrefix = 'Note from Business Team: ';
            if (data.transactionScopeNote &&
                data.transactionScopeNote != undefined)
                techFieldNotes.push({ fieldName: 'transactionScope', note: messagePrefix + data.transactionScopeNote });
            if (data.relevantParametersNote &&
                data.relevantParametersNote != undefined)
                techFieldNotes.push({ fieldName: 'relevantParameters', note: messagePrefix + data.relevantParametersNote });
            if (data.transactionRunResultNote &&
                data.transactionRunResultNote != undefined)
                techFieldNotes.push({ fieldName: 'transactionRunResult', note: messagePrefix + data.transactionRunResultNote });
            if (data.relatedTransactionsNote &&
                data.relatedTransactionsNote != undefined)
                techFieldNotes.push({ fieldName: 'relatedTransactions', note: messagePrefix + data.relatedTransactionsNote });
            if (data.outputNote &&
                data.outputNote != undefined)
                techFieldNotes.push({ fieldName: 'output', note: messagePrefix + data.outputNote });
            if (data.outputExportNote &&
                data.outputExportNote != undefined)
                techFieldNotes.push({ fieldName: 'outputExport', note: messagePrefix + data.outputExportNote });
            if (data.hasSimilarTransactionReferenceNote &&
                data.hasSimilarTransactionReferenceNote != undefined)
                techFieldNotes.push({ fieldName: 'hasSimilarTransactionReference', note: messagePrefix + data.hasSimilarTransactionReferenceNote });
            if (data.sapCodeDescriptionNote &&
                data.sapCodeDescriptionNote != undefined)
                techFieldNotes.push({ fieldName: 'sapCodeDescription', note: messagePrefix + data.sapCodeDescriptionNote });
            if (data.similarTransactionFunctionalDifferenceNote &&
                data.similarTransactionFunctionalDifferenceNote != undefined)
                techFieldNotes.push({ fieldName: 'similarTransactionFunctionalDifference', note: messagePrefix + data.similarTransactionFunctionalDifferenceNote });
            if (data.transactionModelNote &&
                data.transactionModelNote != undefined)
                techFieldNotes.push({ fieldName: 'transactionModel', note: messagePrefix + data.transactionModelNote });
            if (data.similarTransactionReferencesNote &&
                data.similarTransactionReferencesNote != undefined)
                techFieldNotes.push({ fieldName: 'similarTransactionReferences', note: messagePrefix + data.similarTransactionReferencesNote });

            data.techFieldNotes = techFieldNotes;
        }

        try {
            setIsSavingRequest(true);
            const r = await fetchAuthorized(
                send ? ApiRoutes.Request.SendBusinessInfo() : ApiRoutes.Request.UpdateBusinessInfo(),
                {
                    method: HttpMethods.POST,
                    body: JSON.stringify(data),
                    collectErrorDetails: true
                });

            if (send) {
                trackEvent("SendBusinessInfo", data)
            } else {
                trackEvent("UpdateBusinessInfo", data)
            }

            let request = await r.json();
            request = { ...data, ...request };
            setState({ request });

            let success = true;
            if (send) {
                showSuccessMessage("The transaction request has been updated and sent to the next step");
            } else {
                showSuccessMessage("Changes to the transaction request has been saved");
            }

            setState({ errors: [] });
            setIsSavingRequest(false);
            return success;

        } catch (e) {
            console.debug(e);
            trackError(e);
            setState({ errors: e.details && e.details.length > 0 ? e.details : [] });
            setIsSavingRequest(false);
            return false;
        }
    }

    const updateCisoInfo = async (data, send = false) => {
        try {
            setIsSavingRequest(true);
            const r = await fetchAuthorized(
                send ? ApiRoutes.Request.SendCisoInfo() : ApiRoutes.Request.UpdateCisoInfo(),
                {
                    method: HttpMethods.POST,
                    body: JSON.stringify(data),
                    collectErrorDetails: true
                });

            if (send) {
                trackEvent("SendCisoInfo", data)
            } else {
                trackEvent("UpdateCisoInfo", data)
            }

            let request = await r.json();
            request = { ...data, ...request };
            setState({ request });

            let success = true;
            if (send) {
                showSuccessMessage("The transaction request has been updated and sent to the next step");
            } else {
                showSuccessMessage("Changes to the transaction request has been saved");
            }

            setState({ errors: [] });
            setIsSavingRequest(false);
            return success;

        } catch (e) {
            console.debug(e);
            trackError(e);
            setState({ errors: e.details && e.details.length > 0 ? e.details : [] });
            setIsSavingRequest(false);
            return false;
        }
    }

    const approveRequest = async (data, send = false) => {
        try {
            setIsSavingRequest(true);
            const r = await fetchAuthorized(
                send ? ApiRoutes.Request.ApproveRequest() : ApiRoutes.Request.UpdateRequest(),
                {
                    method: HttpMethods.POST,
                    body: JSON.stringify(data),
                    collectErrorDetails: true
                });

            if (send) {
                trackEvent("ApproveRequest", data)
            } else {
                trackEvent("UpdateRequest", data)
            }

            let request = await r.json();
            request = { ...data, ...request };
            setState({ request });

            let success = true;
            if (send) {
                showSuccessMessage("The transaction request has been updated and approved");
            } else {
                showSuccessMessage("Changes to the transaction request has been saved");
            }

            setState({ errors: [] });
            setIsSavingRequest(false);
            return success;

        } catch (e) {
            console.debug(e);
            trackError(e);
            setState({ errors: e.details && e.details.length > 0 ? e.details : [] });
            setIsSavingRequest(false);
            return false;
        }
    }

    const abortRequest = async (data) => {
        try {

            setIsSavingRequest(true);
            const r = await fetchAuthorized(
                ApiRoutes.Request.AbortRequestWithNote(),
                {
                    method: HttpMethods.POST,
                    body: JSON.stringify(data),
                    collectErrorDetails: true
                });

            trackEvent("AbortRequestWithNote", data)

            let request = await r.json();
            request = { ...state.request, ...request, };
            setState({ request });

            showSuccessMessage("The transaction request has been rejected and deleted");

            setState({ errors: [] });
            setIsSavingRequest(false);
            return true;

        } catch (e) {
            console.debug(e);
            trackError(e);
            setState({ errors: e.details && e.details.length > 0 ? e.details : [] });
            setIsSavingRequest(false);
            return false;
        }
    }

    const reassignRequest = async (data) => {
        try {
            setIsSavingRequest(true);
            const r = await fetchAuthorized(
                ApiRoutes.Request.ReassignRequest(),
                {
                    method: HttpMethods.POST,
                    body: JSON.stringify(data),
                    collectErrorDetails: true
                });

            trackEvent("ReassignRequest", data)

            let request = await r.json();
            request = { ...state.request, ...request, };
            setState({ request });

            showSuccessMessage("The transaction request has been re-assigned");

            setState({ errors: [] });
            setIsSavingRequest(false);
            return true;

        } catch (e) {
            console.debug(e);
            trackError(e);
            setState({ errors: e.details && e.details.length > 0 ? e.details : [] });
            setIsSavingRequest(false);
            return false;
        }
    }

    const skipToApproval = async (data) => {
        try {
            setIsSavingRequest(true);
            const r = await fetchAuthorized(
                ApiRoutes.Request.SkipToApproval(),
                {
                    method: HttpMethods.POST,
                    body: JSON.stringify(data),
                    collectErrorDetails: true
                });

            trackEvent("SkipToApproval", data)

            let request = await r.json();
            request = { ...state.request, ...request, };
            setState({ request });

            showSuccessMessage("The transaction request is now waiting for a final review");

            setState({ errors: [] });
            setIsSavingRequest(false);
            return true;

        } catch (e) {
            console.debug(e);
            trackError(e);
            setState({ errors: e.details && e.details.length > 0 ? e.details : [] });
            setIsSavingRequest(false);
            return false;
        }
    }

    const [isLoadingPreviousStatus, setIsLoadingPreviousStatus] = useState(false);
    const getPreviousStatus = async (transactionRequestId) => {

        try {
            setIsLoadingPreviousStatus(true);
            const r = await fetchAuthorized(ApiRoutes.Request.GetPreviousStatus({ transactionRequestId }),
                {
                    method: HttpMethods.GET
                });

            const data = await r.json();
            setState({
                previousStatus: {
                    previousStatus: data.previousStatus,
                    previousStatusNote: data.previousStatusNote,
                    techFieldNotes: data.techFieldNotes
                }
            });
            setIsLoadingPreviousStatus(false);
        } catch (e) {
            console.debug(e)
            trackError(e);
            setIsLoadingPreviousStatus(false);
        }
    }

    const [isLoadingDefs, setIsLoadingDefs] = useState(false);
    const getChoosenTableDefinitions = async () => {

        try {

            const tableNames = [
                AuthPlatformTableNames.Area,
                AuthPlatformTableNames.Process,
                AuthPlatformTableNames.SubProcess,
                AuthPlatformTableNames.Application,
                AuthPlatformTableNames.SensitiveActivity,
                // AuthPlatformTableNames.Table, this one is loaded with searcha and debounce cause it's very big 
                AuthPlatformTableNames.ReleaseToUsers
            ];

            setIsLoadingDefs(true);
            const r = await fetchAuthorized(ApiRoutes.Table.GetChoosenTableDefinitions({ nameList: tableNames, withRelatedTable: false, withValues: true, isObsolete: false }),
                {
                    method: HttpMethods.GET
                });

            const tables = await r.json();
            setState({ tables });
            setIsLoadingDefs(false);
        } catch (e) {
            console.debug(e)
            trackError(e);
            setIsLoadingDefs(false);
        }
    }

    const getTable = (tableName) => {
        if (state.tables && state.tables.length > 0) {
            const table = state.tables.find(x => x.name === tableName);
            return table;
        } else {
            return null;
        }
    }

    const getTableValues = (tableName, relatedValueId) => {
        if (state.tables && state.tables.length > 0) {
            const table = getTable(tableName);
            if (table && table.childValues) {
                let values = [].concat(table.childValues);
                if (relatedValueId) {
                    values = values.filter(x => x.relatedValueIds.find(id => id === relatedValueId) !== undefined);
                    if (values.length === 0) {
                        return [];
                    }
                }
                return values;
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    const getTableOptions = (tableName, relatedValueId, allowEmpty) => {
        const values = getTableValues(tableName, relatedValueId);
        if (values && values.length > 0) {
            let options = values.map(x => ({ value: x.tableValueId, label: x.value }));
            if (allowEmpty) options = [{ value: "", label: "@None" }].concat(options)
            return options;
        } else {
            return [{ value: "", label: "No available values" }];
        }
    }

    const getTableOptionsWithCode = (tableName, relatedValueId, allowEmpty) => {
        const values = getTableValues(tableName, relatedValueId);
        if (values && values.length > 0) {
            let options = values.map(x => ({ value: x.tableValueId, label: `${x.externalKey} - ${x.value}` }));
            if (allowEmpty) options = [{ value: "", label: "@None" }].concat(options)
            return options;
        } else {
            return [{ value: "", label: "No available values" }];
        }
    }

    const [isLoadingComplexOptions, setisLoadingComplexOptions] = useState(true);
    const loadComplexOptions = async (...entityNames) => {

        try {
            setisLoadingComplexOptions(true);

            const localComplexOptions = {
                company: [],
                compensatoryControl: [],
                risk: [],
                sodActivity: []
            };

            await Promise.all(entityNames.map(async entityName => {
                const r = await fetchAuthorized(ApiRoutes.Data.GetComplexOptions(entityName),
                    {
                        method: HttpMethods.GET
                    });

                const options = await r.json();

                switch (entityName) {
                    case ComplexEntityNames.Company:
                        localComplexOptions.company = options;
                        break;
                    case ComplexEntityNames.CompensatoryControl:
                        localComplexOptions.compensatoryControl = options;
                        break;
                    case ComplexEntityNames.Risk:
                        localComplexOptions.risk = options;
                        break;
                    case ComplexEntityNames.SodActivity:
                        localComplexOptions.sodActivity = options;
                        break;
                    default:
                        break;
                }
            }));

            setState({
                complexOptions: localComplexOptions
            });

            setisLoadingComplexOptions(false);

        } catch (e) {
            console.debug(e)
            trackError(e);
            setisLoadingComplexOptions(false);
        }
    }

    const getComplexOptionsByEntity = (entityName, allowEmpty) => {
        entityName = entityName.charAt(0).toLowerCase() + entityName.slice(1);

        const values = state.complexOptions[entityName];
        if (values && values.length > 0) {
            let options = values.map(x => ({ value: x.key, label: x.value }));
            if (allowEmpty) options = [{ value: "", label: "@None" }].concat(options)
            return options;
        } else {
            return [{ value: "", label: "No available values" }];
        }
    }

    const getComplexOptionsWithDescriptionByEntity = (entityName, allowEmpty) => {
        entityName = entityName.charAt(0).toLowerCase() + entityName.slice(1);

        const values = state.complexOptions[entityName];
        if (values && values.length > 0) {
            let options = values.map(x => ({ value: x.key, label: x.description ? x.value + ' - ' + x.description : x.value }));
            if (allowEmpty) options = [{ value: "", label: "@None" }].concat(options)
            return options;
        } else {
            return [{ value: "", label: "No available values" }];
        }
    }

    const [isLoadingCompletedRequestOptions, setIsLoadingCompletedRequestOptions] = useState(false);
    const loadCompletedRequestOptions = async () => {
        try {
            setIsLoadingCompletedRequestOptions(true);
            const r = await fetchAuthorized(ApiRoutes.Request.GetCompletedRequestOptions(),
                {
                    method: HttpMethods.GET,
                });

            const response = await r.json();

            setState({
                completedRequestOptions: response.options
            });


            setIsLoadingCompletedRequestOptions(false);

        } catch (e) {
            console.debug(e);
            trackError(e);
            setIsLoadingCompletedRequestOptions(false);
            if (e.code === 403) {
                navigateTo(NavRoutes.Forbidden);
            }
        }
    }

    const [isSearchingCompletedRequestOptions, setIsSearchingCompletedRequestOptions] = useState(false);
    const searchCompletedRequestOptions = async (searchText, allowEmpty) => {
        try {

            setIsSearchingCompletedRequestOptions(true);
            const r = await fetchAuthorized(ApiRoutes.Request.GetCompletedRequestOptions({ searchText }),
                {
                    method: HttpMethods.GET,
                });

            const response = await r.json();

            setIsSearchingCompletedRequestOptions(false);

            if (response.options && response.options.length > 0) {
                let options = response.options.map(x => ({ value: x.key, label: x.value }));
                if (allowEmpty) options = [{ value: "", label: "@None" }].concat(options)
                return options;
            } else {
                return [{ value: "", label: "No available values" }];
            }

        } catch (e) {
            console.debug(e);
            trackError(e);
            setIsSearchingCompletedRequestOptions(false);
            if (e.code === 403) {
                navigateTo(NavRoutes.Forbidden);
            }
        }
    }

    const searchCompletedRequestOptionsTree = async (searchText, allowEmpty) => {
        try {
            const includeTreeStructure = true;
            setIsSearchingCompletedRequestOptions(true);
            const r = await fetchAuthorized(ApiRoutes.Request.GetCompletedRequestOptions({ searchText, includeTreeStructure }),
                {
                    method: HttpMethods.GET,
                });

            const response = await r.json();

            setIsSearchingCompletedRequestOptions(false);

            if (response.options && response.options.length > 0) {
                let options = response.options.map(x => ({ value: x.key, label: x.value }));
                if (allowEmpty) options = [{ value: "", label: "@None" }].concat(options)
                return options;
            } else {
                return [{ value: "", label: "No available values" }];
            }

        } catch (e) {
            console.debug(e);
            trackError(e);
            setIsSearchingCompletedRequestOptions(false);
            if (e.code === 403) {
                navigateTo(NavRoutes.Forbidden);
            }
        }
    }

    const [isLoadingTableOptions, setIsLoadingTableOptions] = useState(false);
    const searchTableOptions = async (tableName, searchText, showObsolete, allowEmpty) => {
        try {
            setIsLoadingTableOptions(true);
            const r = await fetchAuthorized(ApiRoutes.Table.SearchTableValueOptions({
                tableName,
                searchText,
                isObsolete: showObsolete
            }),
                {
                    method: HttpMethods.GET
                });

            const response = await r.json();

            setIsLoadingTableOptions(false);

            if (response.options && response.options.length > 0) {
                let options = response.options.map(x => ({ value: x.key, label: x.value }));
                if (allowEmpty) options = [{ value: "", label: "@None" }].concat(options)
                return options;
            } else {
                return [{ value: "", label: "No available values" }];
            }
        } catch (e) {
            console.debug(e);
            trackError(e);
            setIsLoadingTableOptions(false);
            if (e.code === 403) {
                navigateTo(NavRoutes.Forbidden);
            }
        }
    }

    const [isCheckingTransactionNameUniqueness, setisCheckingTransactionNameUniqueness] = useState(false);
    const checkTransactionNameUniqueness = async (transactionName) => {
        try {
            setisCheckingTransactionNameUniqueness(true);
            const r = await fetchAuthorized(ApiRoutes.Request.CheckUniquenessInCatalogue({ transactionName }),
                {
                    method: HttpMethods.GET,
                });

            const existingTransactionIdWithSameName = await r.json();
            setState({ existingTransactionIdWithSameName });
            setisCheckingTransactionNameUniqueness(false);

            // if (existingTransactionIdWithSameName
            //     && existingTransactionIdWithSameName != state.request.transactionRequestId) {
            //     setState({ errors: [{ errorId: "transactionName", message: "non univoco!!" }] });
            // }

        } catch (e) {
            console.debug(e);
            trackError(e);
            setisCheckingTransactionNameUniqueness(false);
            if (e.code === 403) {
                navigateTo(NavRoutes.Forbidden);
            }
        }
    }

    const clearError = (errorId) => {
        const newErrors = clonedeep(state.errors);

        if (newErrors && newErrors.length > 0) {
            const index = newErrors.findIndex(x => x.errorId === errorId);
            if (index !== -1) {
                newErrors.splice(index, 1);
                setState({ errors: newErrors })
            }
        }
    }

    const [isEditMode, setIsEditMode] = useState(false);
    const setEditMode = (value) => {
        setIsEditMode(value);
    }

    const isChecked = (data) => {
        let fields = state.rejectedFields;

        let oldValue = fields.find(e => e.fieldName === data);

        if (oldValue === null || oldValue === undefined) {
            // the value is not selected
            return false;
        }

        // the value is checked
        return true;

    }

    return (
        <Provider value={{

            state,

            isLoadingRequest,
            loadRequest,
            setRequest,

            isSavingRequest,
            saveDraftRequest,
            saveReworkRequest,
            updateBusinessInfo,
            updateCisoInfo,
            approveRequest,
            abortRequest,
            reassignRequest,
            skipToApproval,

            createEmptyRequest,
            resetRequestOwner,

            isCheckingTransactionNameUniqueness,
            checkTransactionNameUniqueness,

            isLoadingDefs,
            getChoosenTableDefinitions,
            getTable,
            getTableValues,
            getTableOptions,
            getTableOptionsWithCode,
            isLoadingTableOptions,
            searchTableOptions,

            clearError,

            setIsBankInfoRequired,
            setNoSendToSap,

            isLoadingComplexOptions,
            loadComplexOptions,
            getComplexOptionsByEntity,
            getComplexOptionsWithDescriptionByEntity,

            isLoadingCompletedRequestOptions,
            loadCompletedRequestOptions,
            isSearchingCompletedRequestOptions,
            searchCompletedRequestOptions,

            isEditMode,
            setEditMode,

            isLoadingPreviousStatus,
            getPreviousStatus,

            searchCompletedRequestOptionsTree,
            isChecked,
            isRejectedProp,
            setIsRejectedProp
        }}>
            {props.children}
        </Provider>
    );
}