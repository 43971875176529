import React, { useContext } from 'react';
import './../../../form.css'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { Switch } from '../../../../common/formik';

import {
    Grid,
    Card,
    CardContent,
    CardHeader
} from '@material-ui/core';

import {
    Select,
} from '../../../../common'

import {
    FieldError
} from '../../../../common'

import { TransactionRequestContext } from '../../TransactionRequestContext';
import { TabsContext } from '../TabsContext';

import { AuthPlatformTableNames, ComplexEntityNames } from '../../../../../helpers';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
    },
    field: {
        width: '100%',
        "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.6)" // (default alpha is 0.38)
        }
    },
}));

export const SodActivityInfoForm = (props) => {
    const classes = useStyles();

    const {
        state,
        getComplexOptionsWithDescriptionByEntity
    } = useContext(TransactionRequestContext)

    const {
        isSavingRequest
    } = useContext(TabsContext);

    return (
        <>
            <Grid item xl={12} xs={12} md={12}>
                <Card>
                    <CardHeader title="SoD Activity" />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xl={12} md={12} xs={12}>
                                <Select
                                    multiple
                                    name="cisoSodActivities"
                                    label="SoD activity"
                                    disabled={true}
                                    className={classes.field}
                                    options={getComplexOptionsWithDescriptionByEntity(ComplexEntityNames.SodActivity)}
                                />
                                <FieldError name="cisoSodActivities" errors={state.errors} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}