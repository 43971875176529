import React, { useContext } from 'react';

import { Field } from 'formik'
import { TextField } from 'formik-material-ui';

import {
    Button,
    Grid,
    Drawer,
    List,
    ListItem,
    Typography,
    CircularProgress
} from '@material-ui/core';

import {
    FieldError
} from '../../../common';

import { TransactionRequestContext } from '../TransactionRequestContext';
import { TabsContext } from './TabsContext';

import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';

import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
    root: {

    },
    list: {
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
        [theme.breakpoints.up('md')]: {
            width: "500px"
        }
    },
    field: {
        width: "100%"
    },
    error: {
        marginLeft: theme.spacing(1)
    },
    progress: {
        color: "black"
    },
    drawerPaper: {
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
    },
}));

export const NoteForm = (props) => {
    const classes = useStyles();
    const sendOrSave = props.saveNoteButton ? 'Save':'Send';
    const {
        state
    } = useContext(TransactionRequestContext);

    const {
        isSubmitting
    } = useContext(TabsContext);

    return (
        <Drawer classes={{ paper: classes.drawerPaper }} anchor="right" open={props.isNoteOpen} className={classes.root}>
            <List className={classes.list}>
                <ListItem>
                    <Typography variant="h5">
                        Comment and {sendOrSave}
                    </Typography>
                </ListItem>
                <ListItem>
                    <div className={classes.field}>
                        <Field
                            multiline
                            variant="outlined"
                            key="note"
                            helperText={props.isNoteObligatory ?
                                "Before "+ sendOrSave +" you have to explain your motivations here (this text will be visible by all Actors involved in this Transaction Request)" :
                                "Insert optional note (this text will be visible by all Actors involved in this Transaction Request)"}
                            type="text"
                            name="note"
                            label={props.isNoteObligatory ? "Note *" : "Note"}
                            component={TextField}
                            className={classes.field}
                        />
                        <FieldError name="note" errors={state.errors} />
                    </div>
                </ListItem>
                <ListItem>
                    <Grid spacing={3} container justify="flex-end">
                        <Grid item>
                            <Button
                                disabled={props.disabled}
                                key="close"
                                type="button"
                                variant="contained"
                                onClick={() => props.setIsNoteOpen(false)}>
                                <CloseIcon />&nbsp;Close
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={props.disabled}
                                key="send"
                                type="button"
                                variant="contained"
                                onClick={props.submitForm}>
                                {
                                    isSubmitting ?
                                    <><CircularProgress size={20} className={classes.progress} />&nbsp;&nbsp;Send</>
                                    :
                                    <><SendIcon />&nbsp;{sendOrSave}</>
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </ListItem>
            </List>

        </Drawer>
    );
}