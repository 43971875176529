import React from 'react';
export const TabsContext = React.createContext(null);

const { Provider } = TabsContext;
export const TabsProvider = (props) => {
    return (
        <Provider value={{}}>
            {props.children}
        </Provider>
    );
}