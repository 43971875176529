import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { 
    NavContext 
} from '../contexts';

import {     
    Breadcrumbs, 
    Link, 
    Typography 
} from '@material-ui/core';

const LinkRouter = props => <Link {...props} component={RouterLink} />;

export const CrumbsBar = (props) => {
    const { breadcrumbs } = useContext(NavContext);

    return (
        <Breadcrumbs aria-label="breadcrumb" color="inherit">
            {breadcrumbs && breadcrumbs.length > 0 && breadcrumbs.map((token, index) => {
                if(index < breadcrumbs.length - 1) {
                    return (                        
                        <LinkRouter key={index} color="inherit" to={token.url}>
                            {token.title}
                        </LinkRouter>
                    )
                } else {
                    return <Typography key={index} color="inherit">{token.title}</Typography>;
                }             
            })}            
        </Breadcrumbs>                
    );
}