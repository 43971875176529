import React, { useContext, useEffect } from 'react';

import { HttpMethods, FetchContext } from '../../../contexts';
import { useAggregatedState } from '../../../../helpers';

export const DashboardContext = React.createContext(null);

const { Provider } = DashboardContext;
export const DashboardProvider = (props) => {
    const { fetchAuthorized } = useContext(FetchContext);
    const [ state, setState ] = useAggregatedState({});

    return (
        <Provider value={{
            // Add here what to expose
        }}>
            {props.children}
        </Provider>
    );
}