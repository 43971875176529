import React, { useContext, useEffect, useState } from 'react';

import { HttpMethods, FetchContext, ApiRoutes, InsightContext } from '../../../contexts';
import { useAggregatedState, SapTableNames, LocalTableNames } from '../../../../helpers';

export const RolesContext = React.createContext(null);

const { Provider } = RolesContext;
export const RolesProvider = (props) => {
    const { fetchAuthorized } = useContext(FetchContext);
    const { trackError } = useContext(InsightContext);
    const [state, setState] = useAggregatedState({
        roles: [],
        role: null,
        user: null,
        tables: []
    });

    const [isLoadingDefs, setIsLoadingDefs] = useState(false);
    const getTableDefinitions = async () => {

        try {

            setIsLoadingDefs(true);
            const nameList = [
                SapTableNames.SalesGroups,
                SapTableNames.CustomerGroups,
                SapTableNames.Countries,
                SapTableNames.SalesOrganizations,
                LocalTableNames.BusinessCategories,
                SapTableNames.Companies
            ].join();
            const r = await fetchAuthorized(ApiRoutes.Table.GetChoosenTableDefinitions({ nameList, withRelatedTable: false, withValues: true, isObsolete: false }),
                {
                    method: HttpMethods.GET
                });


            const tables = await r.json();
            setState({ tables });
            setIsLoadingDefs(false);

        } catch (e) {
            console.debug(e)
            trackError(e);
            setState({ isBusy: false });
            setIsLoadingDefs(false);
        }
    }


    const [isLoadingRoles, setIsLoadingRoles] = useState(false);
    const getRoles = async () => {

        try {

            setIsLoadingRoles(true);
            const r = await fetchAuthorized(ApiRoutes.Role.GetRoles(),
                {
                    method: HttpMethods.GET
                });

            let roles = await r.json();
            setState({ roles });
            setIsLoadingRoles(false);

        } catch (e) {
            console.debug(e)
            trackError(e);
            setIsLoadingRoles(false);
        }
    }

    const [isLoadingRole, setIsLoadingRole] = useState(false);
    const getRole = async (roleId) => {

        try {

            setIsLoadingRole(true);
            const r = await fetchAuthorized(ApiRoutes.Role.GetRole({ roleId, withUsers: true }),
                {
                    method: HttpMethods.GET
                });

            const role = await r.json();
            setState({ role });
            setIsLoadingRole(false);

        } catch (e) {
            console.debug(e)
            trackError(e);
            setIsLoadingRole(false);
        }
    }

    const [isLoadingUser, setIsLoadingUser] = useState(false);
    const getUser = async (userKey) => {

        try {

            setIsLoadingUser(true);
            const r = await fetchAuthorized(ApiRoutes.User.GetUser({ userKey, withValues: true }),
                {
                    method: HttpMethods.GET
                });

            const user = await r.json();
            setState({ user });
            setIsLoadingUser(false);

        } catch (e) {
            console.debug(e)
            trackError(e);
            setIsLoadingUser(false);
        }
    }

    const [isAddingInRole, setIsAddingInRole] = useState(false);
    const addUserInRole = async (data) => {
        try {

            setIsAddingInRole(true);
            const r = await fetchAuthorized(ApiRoutes.User.AddUserInRole(),
                {
                    method: HttpMethods.POST,
                    body: JSON.stringify(data)
                });

            const user = await r.json();
            const role = { ...state.role };
            role.members.push(user);
            setState({ role });
            setIsAddingInRole(false);
            return true;
        } catch (e) {
            console.debug(e)
            trackError(e);
            setIsAddingInRole(false);
            return false;
        }
    }

    const [isUpdatingInRole, setIsUpdatingInRole] = useState(false);
    const updateUserInRole = async (data) => {
        try {

            setIsUpdatingInRole(true);
            const r = await fetchAuthorized(ApiRoutes.User.UpdateUserInRole(),
                {
                    method: HttpMethods.POST,
                    body: JSON.stringify(data)
                });

            const user = await r.json();
            const role = { ...state.role };
            const members = [].concat(role.members);
            members[members.findIndex(x => x.externalKey === user.externalKey)] = user;
            setState({ role });
            setIsUpdatingInRole(false);
            return true;
        } catch (e) {
            console.debug(e)
            trackError(e);
            setIsUpdatingInRole(false);
            return false;
        }
    }

    const [isRemovingFromRole, setIsRemovingFromRole] = useState(false);
    const removeUserFromRole = async (userKey, roleId) => {
        try {

            setIsRemovingFromRole(true);
            const r = await fetchAuthorized(ApiRoutes.User.RemoveUserFromRole({ userKey, roleId }),
                {
                    method: HttpMethods.DELETE
                });

            const role = { ...state.role };
            role.members = role.members.filter(x => x.externalKey !== userKey);
            setState({ role });
            setIsRemovingFromRole(false);
            return true;
        } catch (e) {
            console.debug(e)
            trackError(e);
            setIsRemovingFromRole(false);
            return false;
        }
    }

    const getTable = (tableName) => {
        if (state.tables && state.tables.length > 0) {
            const table = state.tables.find(x => x.name === tableName);
            return table;
        } else {
            return null;
        }
    }

    const getTableValues = (tableName, relatedValueIds) => {
        if (state.tables && state.tables.length > 0) {
            const table = getTable(tableName);
            if (table && table.childValues) {
                let values = [].concat(table.childValues);
                if (relatedValueIds && relatedValueIds.length > 0) {
                    values = values.filter(x => x.relatedValueIds.find(id => relatedValueIds.includes(id)) !== undefined);
                    if (values.length === 0) {
                        return [];
                    }
                }
                return values;
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    const getTableOptions = (tableName, relatedValueIds) => {
        if (!relatedValueIds || relatedValueIds.length === 0) {
            return [{ value: "", label: "No available values" }];
        }
        const values = getTableValues(tableName, relatedValueIds);
        if (values && values.length > 0) {
            return values.map(x => ({ value: x.tableValueId, label: x.value }));
        } else {
            return [{ value: "", label: "No available values" }];
        }
    }

    const getTableOptionsWithCode = (tableName, relatedValueIds) => {
        if (!relatedValueIds || relatedValueIds.length === 0) {
            return [{ value: "", label: "No available values" }];
        }
        const values = getTableValues(tableName, relatedValueIds);
        if (values && values.length > 0) {
            return values.map(x => ({ value: x.tableValueId, label: `${x.externalKey} - ${x.value}` }));
        } else {
            return [{ value: "", label: "No available values" }];
        }
    }

    return (
        <Provider value={{
            state,
            isLoadingDefs,
            getTableDefinitions,
            isLoadingRoles,
            getRoles,
            isLoadingRole,
            getRole,
            isLoadingUser,
            getUser,
            addUserInRole,
            isAddingInRole,
            isUpdatingInRole,
            updateUserInRole,
            isRemovingFromRole,
            removeUserFromRole,

            getTableOptions,
            getTableOptionsWithCode,
            getTable,
            getTableValues
        }}>
            {props.children}
        </Provider>
    );
}