import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router';

import {
    LinearProgress,
    Container    
} from '@material-ui/core';

import { RolesProvider, RolesContext } from './RolesContext';
import { NavContext } from '../../../contexts';

import {
    RoleAssigmentForm
} from './components';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
    },
    progress: {
        marginBottom: theme.spacing(2)
    }
}));

const PageContent = (props) => {
    const { addBreadcrumbToken } = useContext(NavContext);
    const classes = useStyles();
    
    const { 
        state,                
        isLoadingDefs,
        getTableDefinitions,
        isLoadingRole,
        getRole,
        isLoadingUser,
        getUser,
        isAddingInRole,
        isUpdatingInRole,
    } = useContext(RolesContext);

    useEffect(() => {
        getTableDefinitions();
    }, []);

    const { roleId } = useParams();
    useEffect(() => {           
        getRole(roleId);   
    }, [roleId])

    const { userKey } = useParams();
    useEffect(() => {
        addBreadcrumbToken(userKey ? "Edit User" : "Add User")
        if(userKey) getUser(userKey);
    }, [userKey]);

    return (
        <>
            {(isLoadingDefs || isLoadingRole || isLoadingUser || isAddingInRole || isUpdatingInRole) &&
                <LinearProgress color="primary" className={classes.progress} />
            }
            {state.role && !isLoadingUser &&
                <Container maxWidth="lg">
                    <RoleAssigmentForm editMode={userKey !== undefined} />
                </Container>       
            }
        </>
    );
}

export const UserInRolePage = () => {
    return (
        <RolesProvider>
            <PageContent />
        </RolesProvider>
    );
}