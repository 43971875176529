import React, { useContext, useEffect, useState } from 'react';

import { HttpMethods, FetchContext, ApiRoutes, InsightContext } from '../../contexts';
import { useAggregatedState } from '../../../helpers';

export const HistoryContext = React.createContext(null);

const { Provider } = HistoryContext;
export const HistoryProvider = (props) => {
    const { fetchAuthorized } = useContext(FetchContext);
    const { trackError } = useContext(InsightContext);

    const [ state, setState ] = useAggregatedState({
        history: null
    });

    const [isLoadingHistory, setIsLoadingHistory] = useState(false);
    const loadHistory = async (transactionRequestId) => {
        try {

            setIsLoadingHistory(true);
            const r = await fetchAuthorized(ApiRoutes.Request.GetTransactionRequestHistory({ transactionRequestId }),
                {
                    method: HttpMethods.GET,
                });
            
            const history = await r.json();
            setState({ history });

            setIsLoadingHistory(false);

        } catch(e) {
            trackError(e);
            console.debug(e);
            setIsLoadingHistory(false);
        }
    }

    const [isDownloading, setIsDownloading] = useState(false);
    const downloadHistory = async (transactionRequestId) => {
        try {

            setIsDownloading(true);
            const r = await fetchAuthorized(ApiRoutes.Request.GetRequestHistoryExcel({ transactionRequestId }),
                {
                    method: HttpMethods.GET,
                });

            const blob = await r.blob();
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "history.xlsx";
            document.body.appendChild(a); 
            a.click();    
            a.remove();
            
            setIsDownloading(false);

        } catch(e) {
            console.debug(e);
            trackError(e);
            setIsDownloading(false);
        }
    }

    return (
        <Provider value={{  
            state,
            isLoadingHistory,
            loadHistory,
            
            downloadHistory,
            isDownloading
        }}>
            {props.children}
        </Provider>
    );
}