import React, { useContext, useEffect, useState, useRef, forwardRef } from 'react';
import _ from "lodash";

import {
    Paper,
    Typography,
    Select,
    MenuItem,
    Chip,
    TablePagination,
    Grid,
    Switch,
    FormControlLabel
} from '@material-ui/core';

import MaterialTable, { MTableToolbar } from 'material-table';

import {
    UserContext
} from '../../../../contexts';

import { TablesContext, TableValueType } from '../TablesContext';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import { makeStyles } from '@material-ui/core/styles';
import { ApplicationRoles, AllAuthPlatformTableNames, AuthPlatformTableNames } from '../../../../../helpers';
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: "100%",
        overflowX: "auto",
        margin: 4,
        "&& th": {
            backgroundColor: "red"
        }
    },
    showObsolete: {
        marginLeft: theme.spacing(1)
    },
    notable: {
        padding: "16px"
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    }
}));

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export const TableValueList = (props) => {
    const classes = useStyles();

    const {
        userInfo,
        isUserInRole,
        isUserInRoles,
    } = useContext(UserContext);

    const {
        state,
        isLoadingTableValues,
        addTableValue,
        updateTableValue,
        deleteTableValue,
        deUglifyLongString
    } = useContext(TablesContext);

    const {
        tables,
        relatedTable,
        selectedTableId,
        isSelectedTableComplex
    } = state;

    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState(null);

    const tableWithRelatedEditable = [
        ...AllAuthPlatformTableNames
    ];

    const [tableState, setTableState] = useState({

        columns: [
            // Dummy Column1
            {
                title: "",
                searchable: false,
                hidden: false,
                width: 'auto'
            },
            {
                title: "External Key",
                field: "externalKey",
                filtering: false,
                hidden: false,
                editable: "always",
                width: 'auto',
                render: (rowData) => deUglifyLongString(rowData.externalKey)
            },
            {
                title: "Description",
                field: "value",
                filtering: false,
                editable: "always",
                width: 'auto',
                render: (rowData) => deUglifyLongString(rowData.value)

            },
            {
                title: "Related Value",
                field: "relatedValueIds",
                filtering: false,
                searchable: true,
                customFilterAndSearch: (value, rowData) => {
                    return true;
                },
                initialEditValue: "",
                hidden: true,
                width: 'auto',
                render: (rowData) => {
                    return (
                        <>
                            {rowData.relatedTable && !isSelectedTableComplex ?
                                <div className={classes.chips}>
                                    {rowData.relatedValueIds.map((id, index) => {
                                        const tableValue = rowData.relatedTable.childValues.find(x => x.tableValueId === id);
                                        return (
                                            tableValue && !tableValue.isObsolete ? <Chip key={index} label={tableValue.externalKey + " - " + tableValue.value} className={classes.chip} /> : null
                                        );
                                    })}
                                </div>
                                :
                                <Typography>
                                    &nbsp;
                                </Typography>

                            }
                        </>
                    );
                },
                editComponent: (props) => {
                    const { rowData } = props;
                    const { data } = props.columnDef.lookup;

                    return (
                        <>
                            {(data && data.children.length > 0) ?
                                <Select
                                    className={classes.select}
                                    value={props.value || props.columnDef.initialEditValue}
                                    multiple={Array.isArray(props.columnDef.initialEditValue)}
                                    renderValue={selected => {
                                        if (!Array.isArray(selected)) selected = [selected];
                                        return (
                                            <div className={classes.chips}>
                                                {selected.map((id, index) => {
                                                    const child = data.children.find(x => x.valueId === id);
                                                    return (child ? <Chip key={index} label={child.text} className={classes.chip} /> : null);
                                                })}
                                            </div>
                                        )
                                    }}
                                    onChange={(e) => {
                                        props.onChange(e.target.value);
                                    }}>
                                    {data && data.children.length > 0 && data.children.map((child, index) => (
                                        <MenuItem key={index} value={child.valueId}>{child.text}</MenuItem>
                                    ))}
                                </Select>
                                :
                                <Typography>
                                    No values available
                                        </Typography>
                            }
                        </>
                    );
                }
            },
            {
                title: "Is Obsolete",
                field: "isObsolete",
                searchable: false,
                filtering: false,
                type: "boolean",
                headerStyle: { textAlign: "center" },
                cellStyle: { padding: 10, textAlign: "center" },
                width: 'auto'
            }
        ],

        data: []
    });

    const getCurrentTableName = () => {
        if (selectedTableId) {
            const table = tables.find(x => x.tableDefinitionId === selectedTableId);
            return table.name;
        } else {
            return "No table selected";
        }
    }

    const [showObsolete, setShowObsolete] = useState(false);
    const obsoleteSwitchHandler = (show) => {
        tableRef.current.dataManager.changeRowEditing(false);
        setPageIndex(0);
        setShowObsolete(show);
        setSearchText("");
    }

    const getCodeColumnName = (tableName) => {
        switch (tableName) {

            case AuthPlatformTableNames.Area:
                return "Area Code *";
            case AuthPlatformTableNames.Process:
                return "Process Code *";
            case AuthPlatformTableNames.SubProcess:
                return "Sub-Process Code *";
            case AuthPlatformTableNames.Application:
                return "Application Code *";
            case AuthPlatformTableNames.SensitiveActivity:
                return "Sens. Act. Code *";
            case AuthPlatformTableNames.Table:
                return "Table Name *";
            default:
                return "Code *";
        }
    }

    const getDescriptionColumnName = (tableName) => {
        switch (tableName) {

            case AuthPlatformTableNames.Area:
                return "Area Description *";
            case AuthPlatformTableNames.Process:
                return "Process Description *";
            case AuthPlatformTableNames.SubProcess:
                return "Sub-Process Description *";
            case AuthPlatformTableNames.Application:
                return "Application Name *";
            case AuthPlatformTableNames.SensitiveActivity:
                return "Sens. Act. Description *";
            case AuthPlatformTableNames.Table:
                return "Table Description *";
            default:
                return "Description *";
        }
    }

    const table = state.tables.find(x => x.tableDefinitionId === state.selectedTableId);

    useEffect(() => {
        if (state.selectedTableId && !state.isSelectedTableComplex) {

            setTableState((prevState) => {
                // Customize what columns we want to show
                const columns = [].concat(prevState.columns);
                if (state.tables && state.tables.length > 0) {
                    const table = state.tables.find(x => x.tableDefinitionId === selectedTableId);
                    // Check if the current table has related tables associated to it
                    // If that is the case, create the column.lookup object
                    // That lookup will be used to fill the relatedValuedIds value
                    const columnRltdId = columns.find(x => x.field === "relatedValueIds");
                    // We use dummy columns to adjust column layout
                    const columnDummy1 = columns[0];
                    // Show the columns
                    // Column "related values" is hidden when there's no related table                     
                    const hasRelatedTables = table.relatedTable !== null;
                    columnRltdId.hidden = !hasRelatedTables;
                    // Column "dummy1" is hidden when there is a related table (only for sap tables)
                    columnDummy1.hidden = hasRelatedTables;
                    // Set initial values
                    columnRltdId.initialEditValue = table.hasMultipleRelatedValues ? [] : "";
                    // Create the lookup object
                    if (hasRelatedTables && relatedTable) {
                        columnRltdId.title = relatedTable.name;
                        let lookup = {};
                        const data = {
                            tableId: relatedTable.tableDefinitionId,
                            name: relatedTable.name,
                            children: []
                        };
                        relatedTable.childValues.filter(x => !x.isObsolete).forEach(x => {
                            data.children.push(
                                {
                                    valueId: x.tableValueId,
                                    value: x.value,
                                    text: relatedTable.valueType !== TableValueType.Local
                                        ? `${x.externalKey} - ${x.value}`
                                        : x.value
                                });
                        });
                        lookup.data = data;
                        columnRltdId.lookup = lookup;
                    }
                    // Column "Code" has some special behavior, we rename it for each table
                    const columnCode = columns.find(x => x.field === "externalKey");
                    columnCode.title = getCodeColumnName(table.name);

                    // Column "Description" has some special behavior, we rename it for each table
                    const columnDescription = columns.find(x => x.field === "value");
                    columnDescription.title = getDescriptionColumnName(table.name);
                    // Check if the current table is a local table
                    // If that is the case, set default value for the external key
                    // which is not supplied by the ui
                    // columnCode.emptyValue = "@LOCAL";
                }

                const newData = table && (table.relatedTable === null || relatedTable) ? [].concat(state.tableValues) : [];
                newData.forEach(x => x.relatedTable = relatedTable);

                return {
                    ...prevState,
                    column: columns,
                    data: newData,
                    page: pageIndex,
                    totalCount: state.totalValues
                };
            });
        }
    }, [state.tables, state.selectedTableId, state.tableValues, state.relatedTable]);

    useEffect(() => {
        if (state.selectedTableId) {
            tableRef.current.dataManager.changeSearchText("");
            tableRef.current.dataManager.changeApplySearch(false);
            tableRef.current.dataManager.changeRowEditing(false);
            setPageIndex(0);
            setShowObsolete(false);
            setSearchText(null);
        }
    }, [state.selectedTableId])

    useEffect(() => {
        if (props.onLoadMore)
            props.onLoadMore(state.selectedTableId, searchText, showObsolete, pageIndex, pageSize);
    }, [state.selectedTableId, searchText, showObsolete, pageIndex, pageSize]);

    const tableRef = useRef(null)
    return (
        <>
            {table ?
                <MaterialTable
                    tableRef={tableRef}
                    className={classes.root}
                    isLoading={isLoadingTableValues}
                    title={getCurrentTableName()}
                    icons={tableIcons}
                    columns={tableState.columns}
                    data={tableState.data}
                    options={{
                        search: true,
                        sorting: false,
                        paging: true,
                        filtering: false,
                        debounceInterval: 1000,
                        pageSize: pageSize,
                        pageSizeOptions: [10, 20, 40, 60],
                        emptyRowsWhenPaging: false   //to make page size fluid in case of less data rows
                    }}
                    onSearchChange={text => {
                        setPageIndex(0);
                        setSearchText(encodeURIComponent(text));
                    }}
                    editable={
                        {
                            onRowAdd: async (newData) => {
                                await addTableValue(
                                    {
                                        ...newData,
                                        tableDefinitionId: table.tableDefinitionId,
                                        relatedValueIds: table.relatedTable ? Array.isArray(newData.relatedValueIds) ? newData.relatedValueIds : [newData.relatedValueIds] : null,
                                        externalKey: newData.externalKey
                                    }
                                );
                            },
                            onRowUpdate: async (newData, oldData) => {
                                await updateTableValue(
                                    {
                                        ...newData,
                                        tableDefinitionId: table.tableDefinitionId,
                                        relatedValueIds: table.relatedTable ? Array.isArray(newData.relatedValueIds) ? newData.relatedValueIds : [newData.relatedValueIds] : null
                                    }
                                );
                            },
                            onRowDelete: async (oldData) => {
                                await deleteTableValue(oldData.tableValueId);
                            }
                        }
                    }
                    components={{
                        Toolbar: (props) => (
                            <div>
                                <MTableToolbar {...props} />
                                <FormControlLabel
                                    className={classes.showObsolete}
                                    label="Show obsolete values"
                                    control={
                                        <Switch
                                            checked={showObsolete}
                                            color="primary"
                                            onChange={(e) => obsoleteSwitchHandler(e.target.checked)}
                                        />
                                    }
                                />
                            </div>
                        ),
                        Pagination: (props) => (
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 40, 60]}
                                count={state.totalValues}
                                rowsPerPage={pageSize}
                                page={pageIndex}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={(e, page) => {
                                    setPageIndex(page);
                                    tableRef.current.dataManager.changeCurrentPage(page);

                                }}
                                onChangeRowsPerPage={e => {
                                    const newPageSize = parseInt(e.target.value, 10);
                                    setPageIndex(0);
                                    setPageSize(newPageSize);
                                    tableRef.current.dataManager.changePageSize(newPageSize);
                                }}
                            />
                        )
                    }}
                />
                :
                <Paper className={classes.notable}>
                    <Typography variant="h6">
                        No table selected
                    </Typography>
                    <Typography variant="body1" style={{ marginTop: 8 }}>
                        Select a table from the list to see what values are stored
                    </Typography>
                </Paper>
            }
        </>
    );
}