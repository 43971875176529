import React from 'react';
import { ImportProvider } from './ImportContext';

import { TabsContainer } from './components';

const PageContent = ({ readOnly }) => {
    return (
        <TabsContainer
            editMode={!readOnly} />
    );
}

export const ImportPage = () => {
    return (
        <ImportProvider>
            <PageContent readOnly={false} />
        </ImportProvider>
    );
}