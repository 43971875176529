import React, { Fragment, useContext, useEffect } from 'react';

import {
    Paper,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';

import { TablesContext } from '../TablesContext';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    selector: {
        width: '100%'
    },
    notables: {
        padding: "16px"
    }
}));

export const TableSelector = (props) => {
    const classes = useStyles();

    const {
        state,
        setSelectedTableId
    } = useContext(TablesContext);

    const {
        tables,
        complexTables
    } = state;

    const getTables = () => tables.filter(x => x.valueType === props.tableValueType);

    const itemSelectHandler = (e) => {
        const tableId = e.target.value;
        setSelectedTableId(tableId);
    }

    const renderListItems = () => {
        if (getTables().length === 0 && complexTables.length === 0) {
            return <Paper className={classes.notables}>
                <Typography variant="h6">
                    No tables available.
            </Typography>
                <Typography variant="body1">
                    There's no tables avaiable at this time.
            </Typography>
            </Paper>
        } else {

            const complexItems = complexTables && complexTables.map((table, index) => (
                <MenuItem key={table.name} value={table.tableDefinitionId}>{table.name}</MenuItem>
            ))

            const items = getTables() && getTables().map((table, index) => (
                <MenuItem key={table.name} value={table.tableDefinitionId}>{table.name}</MenuItem>
            ));

            const all = [...complexItems, ...items];

            return <Paper className={classes.root}>
                <FormControl className={classes.selector}>
                    <InputLabel>All tables</InputLabel>
                    <Select value={state.selectedTableId} onChange={itemSelectHandler}>
                        {all.sort((a, b) => a.key.localeCompare(b.key))}
                    </Select>
                </FormControl>
            </Paper>
        }
    }

    return (
        <Fragment>
            {renderListItems()}
        </Fragment>
    );
}