import React, { useContext, useEffect } from 'react';

import { NavContext } from '../../contexts';

import {
    Typography
} from '@material-ui/core';

import EmojiPeopleIcon from '@material-ui/icons/EmojiPeopleOutlined'

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 150,
        padding: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    image: {
        display: 'inline-block',
        marginTop: 50,
        width: '200px',
        height: '200px'
    }
}));

export const NotFoundPage = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography
                align="center"
                variant="h2">
                404: The page you are looking for isn’t here
            </Typography>
            <br />
            <Typography
                align="center"
                variant="body1">
                You either tried some shady route or you came here by mistake. Whichever
                it is, try using the navigation
            </Typography>
            <br />
            <EmojiPeopleIcon className={classes.image} />
        </div>
    );
}