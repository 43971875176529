import React, { useContext, useEffect, useState } from 'react';
import { TransactionRequestContext } from '../TransactionRequestContext';

export const TabsContext = React.createContext(null);

const { Provider } = TabsContext;
export const TabsProvider = (props) => {

    const { 
        values,
        setFieldValue,
        resetForm,
        isSubmitting,
    } = props;

    const {                
        isSavingRequest
    } = useContext(TransactionRequestContext)

    return (
        <Provider value={{
            values,
            setFieldValue,
            resetForm,
            isSubmitting,
            isSavingRequest
        }}>
            {props.children}
        </Provider>
    );
}