import React from 'react';

export const DateTime = (props) => {

    const formatDate = (value) => {
        return (
            <>
                {
                    `${(new Intl.DateTimeFormat("en-US", {
                        day: "numeric"
                    })).format(value ? Date.parse(value) : new Date())} ${(new Intl.DateTimeFormat("en-US", {
                        month: "short"
                    })).format(value ? Date.parse(value) : new Date())} ${(new Intl.DateTimeFormat("en-US", {
                        year: "numeric"
                    })).format(value ? Date.parse(value) : new Date())}`
                }
            </>
        );
    }    
    
    const formaTime = (value) => {
        return (
            <>        
                {(new Intl.DateTimeFormat("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    //second: 'numeric'
                })).format(value ? Date.parse(value) : new Date())}
            </>
        );
    }

    return(
        <>
            {props.date && formatDate(props.value)}
            {props.time && formaTime(props.value)}
        </>
    )
}