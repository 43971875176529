import React, { useContext, useEffect } from 'react';

import { NavContext } from '../../contexts';

import {
    Typography
} from '@material-ui/core';

import SettingsIcon from '@material-ui/icons/Settings';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 150,
        padding: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    image: {
        display: 'inline-block',
        marginTop: 50,
        width: '200px',
        height: '200px'
    }
}));

export const NoSettingsPage = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography
                align="center"
                variant="h2">
                Application configuration is not complete
          </Typography>
            <br />
            <Typography
                align="center"
                variant="body1">
                Contact an administration to solve this issue
          </Typography>
            <br />
            <SettingsIcon className={classes.image} />
        </div>
    );
}