import React, { useEffect, useContext, useState, useRef } from 'react';
import { Form, Formik } from 'formik';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
    Tabs,
    Tab,
    Toolbar,
    colors,
    Button,
    Container,
    Grid,
    AppBar,
    Typography,
    Hidden,
    Divider,
    CircularProgress
} from '@material-ui/core';

import {
    RequestHeader,
    RequestStepper,
    RequestAlerts,
    NoteForm,
    DataContainer,
    OpenerSelector
} from './'

import { TechInfoProvider, TechInfoTab } from './techInfo';
import { BusinessInfoProvider, BusinessInfoTab } from './businessInfo';
import { SodActivityInfoProvider, SodActivityInfoTab } from './sodActivityInfo';
import { CisoInfoProvider, CisoInfoTab } from './cisoInfo';
import { TransactionRequestContext } from '../TransactionRequestContext';
import { TabsProvider } from './TabsContext';
import { NavContext, NavRoutes, UserContext } from '../../../contexts';
import { RequestStatus, ApplicationRoles, AuthPlatformTableNames } from '../../../../helpers';

import FileCopy from '@material-ui/icons/FileCopyOutlined';
import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import LoopIcon from '@material-ui/icons/Loop';
import EditIcon from '@material-ui/icons/Edit';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => {
    return (
        {
            root: {

            },
            tabs: {
                [theme.breakpoints.down('md')]: {
                    width: window.innerWidth - 48
                },
                [theme.breakpoints.down('xs')]: {
                    width: window.innerWidth - 16
                },
            },
            divider: {
                backgroundColor: colors.grey[300]
            },
            alert: {
                marginTop: 3
            },
            content: {
                marginTop: 3
            },
            appBar: {
                top: 'auto',
                bottom: 0,
            },
            cancelButton: {
                marginBottom: 10
            },
            overlay: {
                zIndex: 1
            },
            progress: {
                color: "black"
            },
            tabAsterisk: {
                color: "#ff0000"
            },
            saveButtonMobile: {
                paddingBottom: 50
            }
        }
    )
});

export const TabsContainer = ({ editMode }) => {
    const classes = useStyles();
    const formRef = useRef();
    const readOnly = !editMode;

    const {
        navigateTo
    } = useContext(NavContext);

    const {
        userInfo,
        isUserInRole,
        draftRequests,
        setDraftRequests,
        reworkRequests,
        setReworkRequests,
        cloneRequest
    } = useContext(UserContext);

    const {
        state,
        getTableValues,
        isSavingRequest,
        saveDraftRequest,
        saveReworkRequest,
        updateBusinessInfo,
        updateCisoInfo,
        approveRequest,
        abortRequest,
        reassignRequest,
        skipToApproval,
        clearError,
        isEditMode,
        setEditMode,
        isRejectedProp
    } = useContext(TransactionRequestContext);

    const {
        errors
    } = state;

    const techInfoTab = "techInformation";
    const businessInformationTab = "businessInformation";
    const sodActivityTab = "sodActivity";
    const cisoInformationTab = "cisoInformation";

    const initialTab = isUserInRole(ApplicationRoles.Opener)
        ? techInfoTab : isUserInRole(ApplicationRoles.Business)
            ? businessInformationTab : isUserInRole(ApplicationRoles.Ciso)
                ? cisoInformationTab : techInfoTab;

    const [currentTab, setCurrentTab] = useState(initialTab);
    const [isNoteOpen, setIsNoteOpen] = useState(false);
    const [submitAction, setSubmitAction] = useState(null);
    const [isNoteObligatory, setNoteObligatory] = useState(false);
    const [loadingButton, setLoadingButton] = useState(null);
    const [techInfoError, setTechInfoError] = useState(null);
    const [businessInformationError, setBusinessInformationError] = useState(null);
    const [cisoInformationError, setCisoInformationError] = useState(null);
    const [saveNoteButton, setSaveNoteButton] = useState(false);

    const allTabs = [
        { value: techInfoTab, label: "Technical information" },
        { value: businessInformationTab, label: "Business information" },
        { value: sodActivityTab, label: "SoD Activity" },
        { value: cisoInformationTab, label: "CISO information" }
    ];

    const filterTabs = (tabs) => {
        if (!isUserInRole(ApplicationRoles.Ciso) && !isUserInRole(ApplicationRoles.Administrator)) {
            const index = tabs.findIndex(tab => tab.value === cisoInformationTab);
            tabs.splice(index, 1);
        }

        return tabs;
    }

    const getTabs = () => {
        return filterTabs(allTabs).map((tab, index) => {
            if (tab.value === techInfoTab) {
                if (techInfoError)
                    tab.asterisk = true;
                else
                    tab.asterisk = false;
            }

            else if (tab.value === businessInformationTab) {
                if (businessInformationError)
                    tab.asterisk = true;
                else
                    tab.asterisk = false;
            }

            else if (tab.value === cisoInformationTab) {
                if (cisoInformationError)
                    tab.asterisk = true;
                else
                    tab.asterisk = false;
            }

            return (
                <Tab
                    key={tab.value}
                    label={
                        <div>
                            {tab.label}
                            {
                                tab.asterisk &&
                                <span className={classes.tabAsterisk}> *</span>
                            }
                        </div>
                    }
                    value={tab.value}
                    {...a11yProps(index)}
                />
            );
        })
    }

    const createInitialValues = (request) => {
        const values = { ...request };
        // Ensure all ref fields are translated from null -> ""
        setNullIdsAsEmptyStrings(values);

        if (values.applicationId === "") {
            const defaultApplication = getTableValues(AuthPlatformTableNames.Application)
                .find(x => x.externalKey === "BU5");
            if (defaultApplication)
                values.applicationId = defaultApplication.tableValueId;
        }

        // ensure YYYY-MM-DD
        if (values.goLiveDate)
            values.goLiveDate = new Date(values.goLiveDate).toLocaleDateString('en-CA');

        return values;
    }

    // This method is used to force null->"" conversion on ref fields (eg. CountryId)
    // used in select form fields. Material will complain if you bind a null value to a select
    const setNullIdsAsEmptyStrings = (obj) => {

        const isObject = val =>
            val !== null && (typeof val === 'object' || Array.isArray(val));

        const check = (obj = {}) => {
            Object.entries(obj)
                .forEach(([key, value]) => {
                    if (isObject(value)) {
                        check(value);
                    } else {
                        if (key.endsWith("Id") && (value === null || value === undefined)) {
                            obj[key] = "";
                        }
                    }
                });
        }

        check(obj);
    }

    const getActionButtons = () => {

        const buttons = [];

        switch (state.request.status) {

            case RequestStatus.Draft:
                if (isUserInRole(ApplicationRoles.Opener)) {
                    buttons.push(getActionButton(<SaveIcon />, "SAVE IN DRAFT",
                        () => {
                            setLoadingButton("SAVE IN DRAFT");
                            setSubmitAction("SaveDraft");
                            if (formRef.current)
                                formRef.current.handleSubmit();
                        }));
                    if (state.request.transactionRequestId > 0) {
                        buttons.push(getActionButton(<FileCopy />, "COPY",
                            () => {
                                if (formRef.current) {
                                    cloneRequest(formRef.current.values);
                                    navigateTo(NavRoutes.NewRequest);
                                }
                            }));
                    }
                    buttons.push(getActionButton(<SendIcon />, "SUBMIT REQUEST",
                        () => {
                            setLoadingButton("SUBMIT REQUEST");
                            setSubmitAction("SendDraft");
                            if (formRef.current)
                                formRef.current.handleSubmit();
                        }));
                }

                break;

            case RequestStatus.Rework:
                if (isUserInRole(ApplicationRoles.Opener)) {
                    buttons.push(getActionButton(<SaveIcon />, "SAVE",
                        () => {
                            setLoadingButton("SAVE");
                            setSubmitAction("SaveRework");
                            if (formRef.current)
                                formRef.current.handleSubmit();
                        }));
                    if (state.request.transactionRequestId > 0) {
                        buttons.push(getActionButton(<FileCopy />, "COPY",
                            () => {
                                if (formRef.current) {
                                    cloneRequest(formRef.current.values);
                                    navigateTo(NavRoutes.NewRequest);
                                }
                            }));
                    }
                    buttons.push(getActionButton(<SendIcon />, "SUBMIT REWORK",
                        () => {
                            setLoadingButton("SUBMIT REWORK");
                            setSubmitAction("SendRework");
                            if (formRef.current)
                                formRef.current.handleSubmit();
                        }));
                }

                break;

            case RequestStatus.WaitingForCiso:

                if (isUserInRole(ApplicationRoles.Ciso)) {
                    buttons.push(getActionButton(<AssignmentReturnIcon />, "REASSIGN TO OPENER",
                        () => {
                            setLoadingButton("REASSIGN TO OPENER");
                            setNoteObligatory(true);
                            setSubmitAction("ReassignToOpener");
                            if (formRef.current)
                                formRef.current.handleSubmit();
                        }));
                }

                if (isUserInRole(ApplicationRoles.Ciso) && !state.request.submitByCiso) {
                    buttons.push(getActionButton(<SaveIcon />, "SAVE",
                        () => {
                            setLoadingButton("SAVE");
                            setNoteObligatory(false);
                            setSubmitAction("SaveCiso");
                            if (formRef.current)
                                formRef.current.handleSubmit();
                        }));
                    buttons.push(getActionButton(<SendIcon />, "SUBMIT REQUEST",
                        () => {
                            setLoadingButton("SUBMIT REQUEST");
                            setNoteObligatory(false);
                            setSubmitAction("SendCiso");
                            if (formRef.current)
                                formRef.current.handleSubmit();
                        }));
                }

                if (isUserInRole(ApplicationRoles.Administrator)) {
                    buttons.push(getActionButton(<SkipNextIcon />, "SKIP TO SOD AC. REWORK",
                        () => {
                            setLoadingButton("SKIP TO SOD AC. REWORK");
                            setSubmitAction("SkipToApproval");
                            if (formRef.current)
                                formRef.current.handleSubmit();
                        }));
                }

                if (isUserInRole(ApplicationRoles.Opener) && state.request.transactionRequestId > 0) {
                    buttons.push(getActionButton(<FileCopy />, "COPY",
                        () => {
                            if (formRef.current) {
                                cloneRequest(formRef.current.values);
                                navigateTo(NavRoutes.NewRequest);
                            }
                        }));
                }

                break;

            case RequestStatus.WaitingForBusiness:

                if (isUserInRole(ApplicationRoles.Business)) {
                    buttons.push(getActionButton(<AssignmentReturnIcon />, "REASSIGN TO OPENER",
                        () => {
                            setLoadingButton("REASSIGN TO OPENER");
                            setNoteObligatory(true);
                            setSubmitAction("ReassignToOpener");
                            if (formRef.current)
                                formRef.current.handleSubmit();
                        }));
                }

                if (isUserInRole(ApplicationRoles.Business) && !state.request.submitByBusiness) {
                    buttons.push(getActionButton(<SaveIcon />, "SAVE",
                        () => {
                            setLoadingButton("SAVE");
                            setSubmitAction("SaveBusiness");
                            if (formRef.current)
                                formRef.current.handleSubmit();
                        }));
                    buttons.push(getActionButton(<SendIcon />, "SUBMIT REQUEST",
                        () => {
                            setLoadingButton("SUBMIT REQUEST");
                            setSubmitAction("SendBusiness");
                            if (formRef.current)
                                formRef.current.handleSubmit();
                        }, isRejectedProp
                    ));
                }

                if (isUserInRole(ApplicationRoles.Administrator)) {
                    buttons.push(getActionButton(<SkipNextIcon />, "SKIP TO SOD AC. REWORK",
                        () => {
                            setLoadingButton("SKIP TO SOD AC. REWORK");
                            setSubmitAction("SkipToApproval");
                            if (formRef.current)
                                formRef.current.handleSubmit();
                        }));
                }

                if (isUserInRole(ApplicationRoles.Opener) && state.request.transactionRequestId > 0) {
                    buttons.push(getActionButton(<FileCopy />, "COPY",
                        () => {
                            if (formRef.current) {
                                cloneRequest(formRef.current.values);
                                navigateTo(NavRoutes.NewRequest);
                            }
                        }));
                }

                break;

            case RequestStatus.WaitingForApproval:
                if (isUserInRole(ApplicationRoles.Administrator)) {
                    if (state.request.status !== RequestStatus.ToBeReviewed &&
                        !state.request.alreadyApprovedOnce) {
                        buttons.push(getActionButton(<ThumbDownIcon />, "DELETE",
                            () => {
                                setLoadingButton("DELETE");
                                setNoteObligatory(true);
                                setSubmitAction("Reject");
                                if (formRef.current)
                                    formRef.current.handleSubmit();
                            }));
                    }
                    buttons.push(getActionButton(<SaveIcon />, "SAVE",
                        () => {
                            setLoadingButton("SAVE");
                            setSubmitAction("FinalSave");
                            if (formRef.current)
                                formRef.current.handleSubmit();
                        }));
                    buttons.push(getActionButton(<ThumbUpIcon />, "APPROVE",
                        () => {
                            setLoadingButton("APPROVE");
                            setNoteObligatory(true);
                            setSubmitAction("FinalApprove");
                            if (formRef.current)
                                formRef.current.handleSubmit();
                        }));
                }

                if (isUserInRole(ApplicationRoles.Opener) && state.request.transactionRequestId > 0) {
                    buttons.push(getActionButton(<FileCopy />, "COPY",
                        () => {
                            if (formRef.current) {
                                cloneRequest(formRef.current.values);
                                navigateTo(NavRoutes.NewRequest);
                            }
                        }));
                }

                break;

            case RequestStatus.Approved:
                if (isUserInRole(ApplicationRoles.Administrator)) {
                    buttons.push(getActionButton(<LoopIcon />, "RESTART",
                        () => {
                            setLoadingButton("RESTART");
                            setNoteObligatory(true)
                            setSubmitAction("Restart")
                            if (formRef.current)
                                formRef.current.handleSubmit();
                        }));
                }

                if ((isUserInRole(ApplicationRoles.Opener) && state.request.ownerKey === userInfo.externalKey)
                    || isUserInRole(ApplicationRoles.Administrator)
                    || isUserInRole(ApplicationRoles.Ciso)
                    || isUserInRole(ApplicationRoles.Business)) {
                    isEditMode ?
                        buttons.push(getActionButton(<SaveIcon />, "SAVE",
                            () => {
                                setLoadingButton("SAVE");
                                setNoteObligatory(isUserInRole(ApplicationRoles.Opener)); // only opener has to write a mandatory note here
                                setEditMode(false);
                                setSubmitAction("ReviewApproved");
                                if (formRef.current)
                                    formRef.current.handleSubmit();
                            })) :
                        buttons.push(getActionButton(<EditIcon />, "EDIT",
                            () => {
                                setLoadingButton(null);
                                setEditMode(true);
                            }));
                }

                if (isUserInRole(ApplicationRoles.Business) || isUserInRole(ApplicationRoles.Ciso) || isUserInRole(ApplicationRoles.Administrator)) {
                    if (!saveNoteButton)
                        setSaveNoteButton(true);
                }

                if (isUserInRole(ApplicationRoles.Opener) && state.request.transactionRequestId > 0) {
                    buttons.push(getActionButton(<FileCopy />, "COPY",
                        () => {
                            if (formRef.current) {
                                cloneRequest(formRef.current.values);
                                navigateTo(NavRoutes.NewRequest);
                            }
                        }));
                }

                break;

            default:
                break;
        }

        return buttons;
    }

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const getActionButton = (icon, text, action, disabled) => {
        if (isMobile) {
            return (
                <Grid item key={text}>
                    <Button
                        size="small"
                        type="button"
                        disabled={readOnly || isSavingRequest}
                        className={classes.saveButtonMobile}
                        onClick={action}>
                        <Grid container>
                            <Grid item xs={12}>
                                {isSavingRequest && loadingButton === text ?
                                    <CircularProgress className={classes.progress} />
                                    :
                                    icon
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    {text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Button>
                </Grid>
            );
        } else {
            return (
                <Grid item key={text}>
                    <Button
                        type="button"
                        variant="contained"
                        disabled={readOnly || isSavingRequest || disabled}
                        className={classes.saveButton}
                        onClick={action}>
                        {isSavingRequest && loadingButton === text ?
                            <><CircularProgress size={20} className={classes.progress} />&nbsp;&nbsp;{text}</>
                            :
                            <>{icon}&nbsp;{text}</>
                        }
                    </Button>
                </Grid>
            );
        }
    }

    const openNoteWhen = (additionalCondition) => {
        if (!isNoteOpen && additionalCondition) {
            setIsNoteOpen(true);
            return true;
        }
        return false;
    }

    const submitClickHandler = async (values, actions) => {

        let success = false;
        switch (submitAction) {

            case "SaveDraft":
            case "SendDraft":

                if (openNoteWhen(submitAction === "SendDraft")) return;

                success = await saveDraftRequest(
                    {
                        ...state.request,
                        ...values,
                        transactionRequestId: state.request.transactionRequestId,
                    },
                    submitAction === "SendDraft"
                );

                if (success) {
                    if (submitAction === "SaveDraft"
                        && (state.request && state.request.transactionRequestId === 0)) {
                        setDraftRequests(draftRequests + 1);
                    } else if (submitAction === "SendDraft" && draftRequests > 0
                        //  && !values.alreadyApprovedOnce
                    ) {
                        setDraftRequests(draftRequests - 1)
                    }
                }

                break;

            case "SaveBusiness":
            case "SendBusiness":

                if (openNoteWhen(submitAction === "SendBusiness")) return;

                success = await updateBusinessInfo(
                    {
                        ...state.request,
                        ...values,
                        transactionRequestId: state.request.transactionRequestId,
                    },
                    submitAction === "SendBusiness"
                );

                break;

            case "SaveCiso":
            case "SendCiso":

                if (openNoteWhen(submitAction === "SendCiso")) return;

                success = await updateCisoInfo(
                    {
                        ...state.request,
                        ...values,
                        transactionRequestId: state.request.transactionRequestId,
                    },
                    submitAction === "SendCiso"
                );

                break;

            case "FinalSave":
            case "FinalApprove":

                if (openNoteWhen(submitAction === "FinalApprove")) return;

                success = await approveRequest(
                    {
                        ...state.request,
                        ...values,
                        transactionRequestId: state.request.transactionRequestId,
                    },
                    submitAction === "FinalApprove"
                );
                break;

            case "Reject":

                if (openNoteWhen(true)) return;

                success = await abortRequest({ transactionRequestId: values.transactionRequestId, note: values.note });
                break;

            case "Restart":
            case "ReassignToOpener":

                if (openNoteWhen(true)) return;

                // map all the rejected fields in one single array property
                let techFieldNotes = [];

                if (values) {

                    if (values.transactionScopeNote)
                        techFieldNotes.push({ fieldName: 'transactionScope', note: 'Note from Business Team: ' + values.transactionScopeNote });

                    if (values.relevantParametersNote)
                        techFieldNotes.push({ fieldName: 'relevantParameters', note: 'Note from Business Team: ' + values.relevantParametersNote });

                    if (values.transactionRunResultNote)
                        techFieldNotes.push({ fieldName: 'transactionRunResult', note: 'Note from Business Team: ' + values.transactionRunResultNote });

                    if (values.relatedTransactionsNote)
                        techFieldNotes.push({ fieldName: 'relatedTransactions', note: 'Note from Business Team: ' + values.relatedTransactionsNote });

                    if (values.outputNote)
                        techFieldNotes.push({ fieldName: 'output', note: 'Note from Business Team: ' + values.outputNote });

                    if (values.outputExportNote)
                        techFieldNotes.push({ fieldName: 'outputExport', note: 'Note from Business Team: ' + values.outputExportNote });

                    if (values.hasSimilarTransactionReferenceNote)
                        techFieldNotes.push({ fieldName: 'hasSimilarTransactionReference', note: 'Note from Business Team: ' + values.hasSimilarTransactionReferenceNote });

                    if (values.sapCodeDescriptionNote)
                        techFieldNotes.push({ fieldName: 'sapCodeDescription', note: 'Note from Business Team: ' + values.sapCodeDescriptionNote });

                    if (values.similarTransactionFunctionalDifferenceNote)
                        techFieldNotes.push({ fieldName: 'similarTransactionFunctionalDifference', note: 'Note from Business Team: ' + values.similarTransactionFunctionalDifferenceNote });

                    if (values.transactionModelNote)
                        techFieldNotes.push({ fieldName: 'transactionModel', note: 'Note from Business Team: ' + values.transactionModelNote });

                    if (values.similarTransactionReferencesNote)
                        techFieldNotes.push({ fieldName: 'similarTransactionReferences', note: 'Note from Business Team: ' + values.similarTransactionReferencesNote });
                }

                success = await reassignRequest({
                    transactionRequestId: values.transactionRequestId,
                    note: values.note,
                    targetStatus: RequestStatus.Rework,
                    techFieldNotes: techFieldNotes
                });

                if (submitAction === "ReassignToOpener" || submitAction === "Restart")
                    setReworkRequests(reworkRequests + 1);

                navigateTo(NavRoutes.MyDesk); // to avoid redirect to forbidden page
                break;

            case "SkipToApproval":
                success = await skipToApproval({ transactionRequestId: values.transactionRequestId, note: "Automatic skips to status 'Waiting for SoD Activity Rework'" });
                break;

            case "ReviewApproved": // in some cases this restarts the process, in others it doesn't
                if (openNoteWhen(true)) return;

                const data = {
                    ...state.request,
                    ...values,
                    transactionRequestId: state.request.transactionRequestId,
                };

                if (isUserInRole(ApplicationRoles.Administrator))
                    success = await approveRequest(data, true); // reapproves the transaction
                else if (isUserInRole(ApplicationRoles.Opener))
                    success = await saveDraftRequest(data, true); // transaction goes back to opener
                else if (isUserInRole(ApplicationRoles.Business))
                    success = await updateBusinessInfo(data, false); // transaction stays approved

                if (isUserInRole(ApplicationRoles.Ciso))
                    success = await updateCisoInfo(data, false); // transaction stays approved

                break;

            case "SaveRework":
            case "SendRework":

                if (openNoteWhen(submitAction === "SendRework")) return;

                success = await saveReworkRequest(
                    {
                        ...state.request,
                        ...values,
                        transactionRequestId: state.request.transactionRequestId,
                    },
                    submitAction === "SendRework"
                );

                if (success) {
                    if (submitAction === "SaveRework"
                        && (state.request && state.request.transactionRequestId === 0)) {
                        setReworkRequests(reworkRequests + 1);
                    } else if (submitAction === "SendRework" && reworkRequests > 0) {
                        setReworkRequests(reworkRequests - 1)
                    }
                }

                break;
            default:
                break;
        }

        actions.setSubmitting(false);

        if (success
            && (submitAction.startsWith("Send") || submitAction === "Reject")) {
            navigateTo(NavRoutes.RunningRequests);
        }
    }

    // todo: fix for transaction request
    const alertCommandHandler = (command) => {

        switch (command) {

            // case "ShowBusinessTab":
            //     setCurrentTab("business");
            //     break;

            // case "ShowLogisticsTab":
            //     setCurrentTab("logistics");
            //     break;

            // case "ShowContractTab":
            //     setCurrentTab("contract");
            //     break;

            // case "ShowAccRecTab":
            //     setCurrentTab("accountReceivable");
            //     break;

            default:
                break;
        }
    }

    const a11yProps = (index) => {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    useEffect(() => {
        clearError("Note")
    }, [isNoteOpen]);

    const renderOpenerSelector = () => {
        if (isUserInRole(ApplicationRoles.Administrator) &&
            state.request.transactionRequestId !== 0)
            return <OpenerSelector
                disabled={readOnly || !isEditMode || state.request.status !== RequestStatus.Approved} />

        else if (isUserInRole(ApplicationRoles.Ciso) &&
            state.request.transactionRequestId !== 0) {
            return <OpenerSelector disabled />
        }
        return null;
    }

    const actionButtons = getActionButtons();
    const hasActionButtons = actionButtons.length > 0;
    return (
        <>
            <Grid className={classes.header} container justify="space-between">
                <Grid item>
                    <RequestHeader />
                </Grid>
                <Grid item>
                    <Hidden mdDown>
                        <Grid container spacing={2}>
                            {actionButtons}
                        </Grid>
                    </Hidden>
                </Grid>
                <Grid item xl={12} md={12} xs={12}>
                    <RequestStepper />
                </Grid>
                <Grid item xs={12} xl={12} md={12}>
                    <Tabs
                        className={classes.tabs}
                        onChange={(event, value) => setCurrentTab(value)}
                        value={currentTab}
                        variant="scrollable">
                        {getTabs()}
                    </Tabs>
                    <Divider className={classes.divider} />
                </Grid>
            </Grid>
            <Container style={{ marginBottom: hasActionButtons && isMobile ? 60 : null }}>
                <Formik
                    initialValues={createInitialValues(state.request)}
                    innerRef={formRef}
                    onSubmit={submitClickHandler}>
                    {(props) => {
                        return (
                            <Form noValidate autoComplete="off">
                                <TabsProvider {...props}>
                                    <NoteForm
                                        disabled={readOnly || isSavingRequest}
                                        isNoteObligatory={isNoteObligatory}
                                        isNoteOpen={isNoteOpen}
                                        setIsNoteOpen={(arg) => setIsNoteOpen(arg)}
                                        submitForm={props.submitForm}
                                        saveNoteButton={saveNoteButton}
                                    />

                                    <RequestAlerts onCommand={alertCommandHandler} />

                                    <DataContainer>

                                        {currentTab === techInfoTab &&
                                            <TechInfoProvider {...props}>
                                                <TechInfoTab disabled={readOnly || isSavingRequest} />
                                            </TechInfoProvider>
                                        }

                                        {currentTab === businessInformationTab &&
                                            <BusinessInfoProvider {...props}>
                                                <BusinessInfoTab disabled={readOnly || isSavingRequest} />
                                            </BusinessInfoProvider>
                                        }

                                        {currentTab === sodActivityTab &&
                                            <SodActivityInfoProvider {...props}>
                                                <SodActivityInfoTab disabled={readOnly || isSavingRequest} />
                                            </SodActivityInfoProvider>
                                        }

                                        {currentTab === cisoInformationTab &&
                                            <CisoInfoProvider {...props}>
                                                {renderOpenerSelector()}
                                                <CisoInfoTab disabled={readOnly || isSavingRequest} />
                                            </CisoInfoProvider>
                                        }

                                    </DataContainer>

                                </TabsProvider>
                            </Form>
                        );
                    }}
                </Formik>
            </Container>
            {hasActionButtons && (
                <Hidden lgUp>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Grid justify="space-around" container spacing={2}>
                                {getActionButtons()}
                            </Grid>
                        </Toolbar>
                    </AppBar>
                </Hidden>
            )}
        </>
    );
}