export const defaultTransactionRequest = {
    hasSimilarTransactionReference: true,
    hasAuthorityCheck: true,
    isMappingCompleted: true,
    isInCatalogue: true,
    applicationId: "",
    tablesUpdated: [],
    companiesInScope: [],
    similarTransactionReferences: [],
    treeStructureReportingTransactions: [],
    displayOnlyTransactions: [],
    integratedTransactions: [],
    replacingTransactions: [],
    authorityCheckTransactions: [],
    // businessSodActivities: [], // hidden
    cisoSodActivities: [],
    ownerKey: null,
    ownerFullName: null,
    goLiveDate: new Date(),
    transactionName: "",
    transactionFunction: ""
}