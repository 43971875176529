import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { CisoInfoForm } from './CisoInfoForm'
import { TransactionRequestContext } from '../../TransactionRequestContext';
import { TabsContext } from '../TabsContext';
import { UserContext } from '../../../../contexts';
import { RequestStatus, ApplicationRoles } from '../../../../../helpers';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: 20
    }
}));

export const CisoInfoTab = (props) => {
    const classes = useStyles();
    const {
        userInfo,
        isUserInRole
    } = useContext(UserContext);

    const {
        state,
        isEditMode
    } = useContext(TransactionRequestContext);

    const {
        isSubmitting,
    } = useContext(TabsContext);

    const [disabled, setDisabled] = useState(false);
    useEffect(() => {
        const canCompleteRequest = (
            state.request.status === RequestStatus.WaitingForCiso
            && isUserInRole(ApplicationRoles.Ciso)
            && !state.request.submitByCiso);

        const canApproveRequest = (state.request.status === RequestStatus.WaitingForApproval
            && state.request.transactionRequestId !== 0
            && isUserInRole(ApplicationRoles.Administrator));

        const canEditApprovedRequest = (isUserInRole(ApplicationRoles.Ciso) || isUserInRole(ApplicationRoles.Administrator))
            && state.request.status === RequestStatus.Approved
            && isEditMode;

        // const canEditReview = isUserInRole(ApplicationRoles.Administrator)
        //     && state.request.status === RequestStatus.ToBeReviewed;

        setDisabled(
            props.disabled
            || isSubmitting
            || !(
                canCompleteRequest
                || canApproveRequest
                || canEditApprovedRequest
            )
        );
    }, [isSubmitting, state.request.status, isEditMode, userInfo.roles])

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <CisoInfoForm disabled={disabled} />
            </Grid>
        </div>
    );
}
