import React, { useContext } from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

import {
    AuthContext,
    NavContext,
    NavRoutes,
    UserContext
} from '../contexts';

import {
    ForbiddenPage, HistoryPage, ImportPage, NoSettingsPage, NotFoundPage, RolePage, TransactionRequestPage,
    TransactionRequestPageReadOnly, UserInRolePage
} from '../pages';

import { ApplicationRoles } from '../../helpers';

const ProtectedRoute = (props) => {

    const {
        isAuthenticated
    } = useContext(AuthContext);

    const {
        userInfo,
        isUserInRole,
        isUserInRoles,
    } = useContext(UserContext);

    const allowRoute = (path) => {

        if (!isAuthenticated)
            return false;

        if (userInfo.roles !== null && userInfo.roles.length > 0) {

            if (isUserInRole(ApplicationRoles.Administrator))
                return true;

            switch (path) {

                case NavRoutes.MyDesk:
                case NavRoutes.Logout:
                case NavRoutes.NotFound:
                case NavRoutes.Forbidden:
                case NavRoutes.NotValidAS:
                    return true;

                case NavRoutes.NewRequest:
                    return isUserInRoles([
                        ApplicationRoles.Opener
                    ]);

                case NavRoutes.EditRequest:
                case NavRoutes.ViewRequest:
                case NavRoutes.RunningRequests:
                case NavRoutes.CompletedRequests:
                case NavRoutes.UndeletedRequests:
                case NavRoutes.ReworkRequests:
                case NavRoutes.ReworkedRequests:
                    return isUserInRoles([
                        ApplicationRoles.Opener,
                        ApplicationRoles.Business,
                        ApplicationRoles.Display,
                        ApplicationRoles.Ciso
                    ]);

                case NavRoutes.RequestHistory:
                    return isUserInRoles([
                        ApplicationRoles.Opener,
                        ApplicationRoles.Business,
                        ApplicationRoles.Ciso
                    ]);

                case NavRoutes.DraftRequests:
                    return isUserInRoles([
                        ApplicationRoles.Opener,
                    ]);

                case NavRoutes.AllRequests:
                    return isUserInRoles([
                        ApplicationRoles.Ciso
                    ]);
                case NavRoutes.MassiveImport:
                    return isUserInRoles([
                        ApplicationRoles.Ciso,
                        ApplicationRoles.Administrator,
                    ]);

                case NavRoutes.Admin.Dashboard:
                case NavRoutes.Admin.Roles:
                case NavRoutes.Admin.EditRole:
                case NavRoutes.Admin.AddUserInRole:
                case NavRoutes.Admin.EditUserInRole:
                case NavRoutes.Admin.LocalTables:
                    return false; // only admin here, admin is checked above

                default:
                    return false;
            }

        } else {
            return false;
        }
    }

    const routeWithRules = (path, routeProps) => {
        // switch(path) {

        //     case NavRoutes.NewRequest:
        //     case NavRoutes.EditRequest:
        //         break;

        //     default:
        //         break;
        // }

        return <Route {...props} />
    }

    if (allowRoute(props.path)) {
        return routeWithRules(props.path, props);
    } else {
        return <Redirect to={NavRoutes.Forbidden} />
    }
}

export const Routes = (props) => {
    const { menuItems } = useContext(NavContext);

    return (

        <Switch>

            {menuItems && menuItems.map((item, index) => {
                if (item.isProtected) {
                    return <ProtectedRoute key={index} exact path={item.routePath} component={item.component || NotFoundPage} />
                } else {
                    return <Route key={index} exact path={item.routePath} component={item.component || NotFoundPage} />
                }
            })}

            <ProtectedRoute exact path={NavRoutes.Admin.EditRole} component={RolePage} />
            <ProtectedRoute exact path={NavRoutes.Admin.AddUserInRole} component={UserInRolePage} />
            <ProtectedRoute exact path={NavRoutes.Admin.EditUserInRole} component={UserInRolePage} />

            <ProtectedRoute exact path={NavRoutes.EditRequest} component={TransactionRequestPage} />
            <ProtectedRoute exact path={NavRoutes.ViewRequest} component={TransactionRequestPageReadOnly} />

            <ProtectedRoute exact path={NavRoutes.RequestHistory} component={HistoryPage} />

            <ProtectedRoute exact path={NavRoutes.NoSettings} component={NoSettingsPage} />
            <ProtectedRoute exact path={NavRoutes.MassiveImport} component={ImportPage} />

            <Route exact path={NavRoutes.Forbidden} component={ForbiddenPage} />

            <Route component={NotFoundPage} />

        </Switch>
    );
}