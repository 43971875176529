import React from 'react';
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { MenuItem, ListSubheader } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    field: {
        width: '100%'
    }
}));

export const Select = (props) => {
    const classes = useStyles();

    const renderOptions = props.options && props.options.length > 0 &&
        props.options.map((option, index) => {
            // grouping only if values is string
            if (option.value === option.value + '' && option.value.startsWith("~divider"))
                return (
                    <ListSubheader>{option.label}</ListSubheader>
                );
            return (
                <MenuItem key={index} value={option.value}>
                    {option.label !== "@None" ? option.label : <em>None</em>}
                </MenuItem>
            );
        });

    const selectField = () => <Field
        select
        className={classes.field}
        name={props.name}
        variant="outlined"
        InputProps={{
            readOnly: props.isLoading,
        }}
        helperText={props.helperText}
        label={props.label}
        disabled={props.disabled}
        component={TextField}
        {...props}>
        {renderOptions}
    </Field>;

    const multiSelectField = () => {
        return <Field
            select
            SelectProps={{
                multiple: true,
                readOnly: props.isLoading,
                displayEmpty: true,
            }}
            className={classes.field}
            name={props.name}
            variant="outlined"
            helperText={props.helperText}
            label={props.label}
            disabled={props.disabled}
            component={TextField}
            type="text"
            fullWidth
            {...props}
        >
            {renderOptions}
        </Field>
    }
    return (
        props.multiple ?
            multiSelectField() : selectField()
    );
}