import React, { useContext, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { HttpMethods, FetchContext, ApiRoutes, InsightContext, SettingsContext } from '../../../contexts';
import { useAggregatedState, ComplexEntityNames, niceTruncate } from '../../../../helpers';

export const TablesContext = React.createContext(null);

export const TableValueType = {
    Local: 0
}

const { Provider } = TablesContext;
export const TablesProvider = (props) => {
    const { fetchAuthorized } = useContext(FetchContext);
    const { trackError } = useContext(InsightContext);

    const [state, setState] = useAggregatedState({
        complexTables: [],
        tables: [],
        relatedTable: null,
        tableValues: [],
        tableValuesOptions: [],
        totalValues: 0,
        selectedTableId: null,
        isSelectedTableComplex: false,
        complexOptions: {
            company: [],
            compensatoryControl: [],
            risk: [],
            sodActivity: []
        }
    });

    const [isLoadingComplexOptions, setisLoadingComplexOptions] = useState(true);
    const getComplexOptions = async (...entityNames) => {

        try {
            setisLoadingComplexOptions(true);

            const localComplexOptions = {
                company: [],
                compensatoryControl: [],
                risk: [],
                sodActivity: []
            };

            await Promise.all(entityNames.map(async entityName => {
                const r = await fetchAuthorized(ApiRoutes.Data.GetComplexOptions(entityName),
                    {
                        method: HttpMethods.GET
                    });

                const options = await r.json();

                switch (entityName) {
                    case ComplexEntityNames.Company:
                        localComplexOptions.company = options;
                        break;
                    case ComplexEntityNames.CompensatoryControl:
                        localComplexOptions.compensatoryControl = options;
                        break;
                    case ComplexEntityNames.Risk:
                        localComplexOptions.risk = options;
                        break;
                    case ComplexEntityNames.SodActivity:
                        localComplexOptions.sodActivity = options;
                        break;
                    default:
                        break;
                }
            }));

            setState({
                complexOptions: localComplexOptions
            });

            setisLoadingComplexOptions(false);

        } catch (e) {
            console.debug(e)
            trackError(e);
            setisLoadingComplexOptions(false);
        }
    }

    const [isLoadingTableDefs, setIsLoadingTableDefs] = useState(false);
    const getTableDefinitions = async (valueType) => {

        try {

            setIsLoadingTableDefs(true);
            const r = await fetchAuthorized(ApiRoutes.Table.GetTableDefinitions({ valueType, withRelatedTable: true, withValues: false, isObsolete: false }),
                {
                    method: HttpMethods.GET
                });

            const tables = await r.json();
            setState({ tables });
            setIsLoadingTableDefs(false);
        } catch (e) {
            console.debug(e)
            trackError(e);
            setIsLoadingTableDefs(false);
        }
    }

    const getComplexTableDefinitions = async (valueType) => {

        try {
            setIsLoadingTableDefs(true);

            const complexTables = [
                { tableDefinitionId: ComplexEntityNames.Company, name: "Companies" },
                //{ tableDefinitionId: ComplexEntityNames.CompensatoryControl, name: "Compensatory Controls" },
                //{ tableDefinitionId: ComplexEntityNames.Risk, name: "Risks" },
                //{ tableDefinitionId: ComplexEntityNames.SodActivity, name: "SoD Activities" },
            ]
            setState({ complexTables });
            setIsLoadingTableDefs(false);
        } catch (e) {
            console.debug(e)
            trackError(e);
            setIsLoadingTableDefs(false);
        }
    }

    const getChoosenTableDefinitions = async (nameList) => {

        try {

            setIsLoadingTableDefs(true);
            const r = await fetchAuthorized(ApiRoutes.Table.GetChoosenTableDefinitions({ nameList, withRelatedTable: false, withValues: true, isObsolete: false }),
                {
                    method: HttpMethods.GET
                });

            const tables = await r.json();
            setState({ tables });
            setIsLoadingTableDefs(false);
        } catch (e) {
            console.debug(e)
            trackError(e);
            setIsLoadingTableDefs(false);
        }
    }

    const [isLoadingRelTable, setIsLoadingRelTable] = useState(false);
    const getRelatedTable = async (tableDefinitionId) => {

        try {

            setIsLoadingRelTable(true);
            const r = await fetchAuthorized(ApiRoutes.Table.GetTableDefinition({ tableDefinitionId, withRelatedTable: false, withValues: true, isObsolete: false }),
                {
                    method: HttpMethods.GET
                });

            const relatedTable = await r.json();
            setState({ relatedTable });
            setIsLoadingRelTable(false);
        } catch (e) {
            console.debug(e)
            trackError(e);
            setIsLoadingRelTable(false);
        }
    }

    const resetRelatedTable = () => {
        setState({ relatedTable: null });
    }

    const [isLoadingTableValues, setIsLoadingTableValues] = useState(false);
    const getTableValues = async (tableId, searchText, showObsolete, pageIndex, pageSize) => {
        try {
            setIsLoadingTableValues(true);
            const table = state.tables.find(x => x.tableDefinitionId === tableId);
            const tableDefinitionId = table.tableDefinitionId;
            const r = await fetchAuthorized(ApiRoutes.Table.GetTableValues({
                tableDefinitionId,
                searchText,
                withRelatedValues: true,
                isObsolete: showObsolete,
                pageIndex: pageIndex,
                pageSize
            }),
                {
                    method: HttpMethods.GET
                });

            const list = await r.json();

            setState({
                tableValues: list.items,
                totalValues: list.totalItems
            });

            setIsLoadingTableValues(false);

        } catch (e) {
            console.debug(e)
            trackError(e);
            setIsLoadingTableValues(false);
        }
    }
    const getComplexTableValues = async (tableId, searchText, pageIndex, pageSize) => {

        try {
            setIsLoadingTableValues(true);
            const entityName = getEntityName(state.complexTables, tableId);
            const r = await fetchAuthorized(ApiRoutes.Data.GetComplexTableValues(entityName, {
                searchText,
                pageIndex: pageIndex,
                pageSize
            }),
                {
                    method: HttpMethods.GET
                });

            const list = await r.json();
            setState({
                tableValues: list.items,
                totalValues: list.totalItems,
                tableValuesOptions: list.tableValueOptions
            });

            setIsLoadingTableValues(false);

        } catch (e) {
            console.debug(e)
            trackError(e);
            setIsLoadingTableValues(false);
        }
    }

    const [isAddingValue, setIsAddingValue] = useState(false);
    const addTableValue = async (data) => {

        try {

            setIsAddingValue(true);
            const r = await fetchAuthorized(ApiRoutes.Table.AddTableValue(),
                {
                    method: HttpMethods.POST,
                    body: JSON.stringify(data)
                });

            const tableValue = await r.json();
            const tableValues = [].concat(state.tableValues);
            tableValues.push(tableValue);

            setState({ tableValues });
            setIsAddingValue(false);

        } catch (e) {
            console.debug(e)
            trackError(e);
            setIsAddingValue(false);
        }
    }
    const addComplexTableValue = async (data) => {

        try {

            setIsAddingValue(true);
            const entityName = getEntityName(state.complexTables, data.tableDefinitionId);
            const r = await fetchAuthorized(ApiRoutes.Data.AddComplexTableValue(entityName),
                {
                    method: HttpMethods.POST,
                    body: JSON.stringify(data)
                });

            const tableValue = await r.json();
            const tableValues = [].concat(state.tableValues);
            tableValues.push(tableValue);

            setState({ tableValues });
            setIsAddingValue(false);

        } catch (e) {
            console.debug(e)
            trackError(e);
            setIsAddingValue(false);
        }
    }

    const [isUpdatingValue, setIsUpdatingValue] = useState(false);
    const updateTableValue = async (data, completed) => {

        try {

            setIsUpdatingValue(true);
            const r = await fetchAuthorized(ApiRoutes.Table.UpdateTableValue(),
                {
                    method: HttpMethods.POST,
                    body: JSON.stringify(data)
                });

            const tableValue = await r.json();
            const oldTableValue = state.tableValues.find(x => x.tableValueId === tableValue.tableValueId);
            let tableValues = [].concat(state.tableValues);
            if (oldTableValue.isObsolete != tableValue.isObsolete) {
                const values = tableValues.filter(x => x.tableValueId !== tableValue.tableValueId);
                tableValues = values
            } else {
                tableValues[tableValues.findIndex(x => x.tableValueId === tableValue.tableValueId)] = tableValue;
            }

            setState({ tableValues });
            setIsUpdatingValue(false);

        } catch (e) {
            console.debug(e)
            trackError(e);
            setIsUpdatingValue(false);
        }
    }
    const updateComplexTableValue = async (data) => {

        try {

            setIsUpdatingValue(true);
            const entityName = getEntityName(state.complexTables, data.tableDefinitionId);
            const r = await fetchAuthorized(ApiRoutes.Data.UpdateComplexTableValue(entityName),
                {
                    method: HttpMethods.PATCH,
                    body: JSON.stringify(data)
                });

            const tableValue = await r.json();
            // update locally
            let tableValues = [].concat(state.tableValues);
            tableValues[tableValues.findIndex(x => x.id === tableValue.id)] = tableValue;

            setState({ tableValues });
            setIsUpdatingValue(false);

        } catch (e) {
            console.debug(e)
            trackError(e);
            setIsUpdatingValue(false);
        }
    }

    const [isDeletingValue, setIsDeletingValue] = useState(false);
    const deleteTableValue = async (tableValueId) => {

        try {

            setIsDeletingValue(true);
            const r = await fetchAuthorized(ApiRoutes.Table.DeleteTableValue({ tableValueId }),
                {
                    method: HttpMethods.DELETE
                });

            const tableValues = [].concat(state.tableValues.filter(x => x.tableValueId !== tableValueId));

            setState({ tableValues });
            setIsDeletingValue(false);

        } catch (e) {
            console.debug(e)
            trackError(e);
            setIsDeletingValue(false);
        }
    }
    const deleteComplexTableValue = async (id, tableDefinitionId) => {

        try {

            setIsDeletingValue(true);
            const entityName = getEntityName(state.complexTables, tableDefinitionId);
            const r = await fetchAuthorized(ApiRoutes.Data.DeleteComplexTableValue(entityName, id),
                {
                    method: HttpMethods.DELETE
                });

            const tableValues = [].concat(state.tableValues.filter(x => x.id !== id));

            setState({ tableValues });
            setIsDeletingValue(false);

        } catch (e) {
            console.debug(e)
            trackError(e);
            setIsDeletingValue(false);
        }
    }

    const getTable = (tableName) => {
        if (state.tables && state.tables.length > 0) {
            const table = state.tables.find(x => x.name === tableName);
            return table;
        } else {
            return null;
        }
    }

    const getValues = (tableName, relatedValueId) => {
        if (state.tables && state.tables.length > 0) {
            const table = getTable(tableName);
            if (table && table.childValues) {
                let values = [].concat(table.childValues);
                if (relatedValueId) {
                    values = values.filter(x => x.relatedValueIds.find(id => id === relatedValueId) !== undefined);
                    if (values.length === 0) {
                        return [];
                    }
                }
                return values;
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    const getValueName = (tableName, valueId) => {
        if (state.tables && state.tables.length > 0) {
            const table = getTable(tableName);
            if (table && table.childValues) {
                const value = table.childValues.find(x => x.tableValueId === valueId);
                return value ? value.value : undefined;
            } else {
                return "";
            }
        } else {
            return "";
        }
    }

    const getValueNameCode = (tableName, valueId) => {
        if (state.tables && state.tables.length > 0) {
            const table = getTable(tableName);
            if (table && table.childValues) {
                const value = table.childValues.find(x => x.tableValueId === valueId);
                return value ? value.externalKey + " - " + value.value : undefined;
            } else {
                return "";
            }
        } else {
            return "";
        }
    }

    const setSelectedTableId = (tableId) => {
        const newIsSelectedTableComplex = isNaN(tableId);
        setState({
            selectedTableId: tableId,
            isSelectedTableComplex: newIsSelectedTableComplex,
            tableValues: [] // Prevent TableValueList to break when switching to simple tabledefinitions from complex tables
        });
    }

    const deUglifyLongString = (longString) => {
        return longString ?
            (
                <Typography>
                    {niceTruncate(longString, 30, true)}
                </Typography>
            ) :
            (
                <Typography>
                    &nbsp;
                </Typography>
            );
    }

    return (
        <Provider value={{

            state,
            isLoadingTableDefs,
            getTableDefinitions,
            getComplexTableDefinitions,
            getChoosenTableDefinitions,
            getComplexOptions,
            isLoadingComplexOptions,

            isLoadingRelTable,
            getRelatedTable,
            resetRelatedTable,

            isLoadingTableValues,
            getTableValues,
            getComplexTableValues,

            isAddingValue,
            addTableValue,
            addComplexTableValue,

            isUpdatingValue,
            updateTableValue,
            updateComplexTableValue,

            isDeletingValue,
            deleteTableValue,
            deleteComplexTableValue,

            getTable,
            getValues,
            getValueName,
            getValueNameCode,

            setSelectedTableId,

            deUglifyLongString
        }}>
            {props.children}
        </Provider>
    );
}

function getEntityName(complexTables, selectedTableId) {
    const table = complexTables.find(x => x.tableDefinitionId === selectedTableId);
    const entityName = table.tableDefinitionId;
    return entityName;
}
