import {
    Button, Card, CardContent, CardHeader, CircularProgress,
    colors, Divider, Grid, InputAdornment, Link,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useContext, useEffect, useState } from 'react';
import { AuthPlatformTableNames, ComplexEntityNames } from '../../../../../helpers';
import { AsyncAutocomplete, FieldError, InputFiles, Select } from '../../../../common';
import { Switch } from '../../../../common/formik';
import { TransactionRequestContext } from '../../TransactionRequestContext';
import { TabsContext } from '../TabsContext';
import './../../../form.css';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    field: {
        width: '100%',
        "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.6)" // (default alpha is 0.38)
        }
    },
    invalidField: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    transactionNameUnique: {
        color: colors.green[500]
    },
    transactionNameNotUnique: {
        color: colors.red[500]
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    errorLink: {
        color: "red"
    }
}));

export const TechInfoForm = (props) => {
    const classes = useStyles();

    const {
        state,
        getTableOptions,
        getTableOptionsWithCode,
        getComplexOptionsByEntity,
        isCheckingTransactionNameUniqueness,
        searchCompletedRequestOptions,
        searchCompletedRequestOptionsTree,
        isLoadingTableOptions,
        searchTableOptions,
        isSearchingCompletedRequestOptions,
        //handleReplacingTransactions
    } = useContext(TransactionRequestContext)

    const {
        values,
        isSavingRequest,
        setFieldValue,
    } = useContext(TabsContext);

    const fileUploadedHandler = (storedFile) => {
        const docs = [].concat(values.techDocumentation || []);
        docs.push({ ...storedFile });
        // We need to set the value manually
        // otherwise setFieldValue will not work property
        values.techDocumentation = docs;
        setFieldValue("techDocumentation", docs);
    }

    const fileRemovedHandler = (storedFile) => {
        const docs = values.riskEvaluation.sheetInfoDocs.filter(x => x.storedFileId !== storedFile.storedFileId);
        // We need to set the value manually
        // otherwise setFieldValue will not work property
        values.riskEvaluation.sheetInfoDocs = docs;
        setFieldValue("riskEvaluation.sheetInfoDocs", docs);
    }

    const getTransactionNameValidationMessage = () => {
        if (values.transactionName) {
            if (!isUnique) {
                const url = `/requests/view/${state.existingTransactionIdWithSameName}`;
                return <Typography>
                    <Link href={url} target="_blank" color="inherit">Transaction name is already in use by another transaction. Click here to open it.</Link>
                </Typography>
            }
        }
    }

    const searchTableOptionsTables = async (searchText) => {
        const res = await searchTableOptions(AuthPlatformTableNames.Table, searchText);
        return res;
    }

    const [isUnique, setIsUnique] = useState(null);
    useEffect(() => {
        if (!values.transactionName || values.transactionName === "") return;
        const { existingTransactionIdWithSameName } = state;
        const alreadyExists = existingTransactionIdWithSameName !== 0 &&
            existingTransactionIdWithSameName !== values.transactionRequestId;
        setIsUnique(!alreadyExists);
    }, [state.existingTransactionIdWithSameName])

    const dividerClasses = `${classes.field} ${classes.divider}`;
    return (
        <>
            <Grid item xl={12} xs={12} md={12}>
                <Card>
                    <CardHeader title="Main Information" />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Select
                                    name="applicationId"
                                    label="Application *"
                                    className={classes.field}
                                    disabled={props.disabled}
                                    options={getTableOptionsWithCode(AuthPlatformTableNames.Application)}>
                                </Select>
                                <FieldError name="applicationId" errors={state.errors} />
                            </Grid>
                            <Grid item xl={6} md={6} xs={12}>
                                <Select
                                    name="transactionType"
                                    label="Transaction Type *"
                                    disabled={props.disabled}
                                    className={classes.field}
                                    options={[
                                        { value: 0, label: "Custom" },
                                        { value: 1, label: "Standard" }]}>
                                </Select>
                                <FieldError name="transactionType" errors={state.errors} />
                            </Grid>
                            <Grid item xl={6} md={6} xs={12}>
                                <Select
                                    name="moduleSapId"
                                    label="Module SAP *"
                                    disabled={props.disabled}
                                    className={classes.field}
                                    options={getTableOptions(AuthPlatformTableNames.Area)}>
                                </Select>
                                <FieldError name="moduleSapId" errors={state.errors} />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    type="text"
                                    className={classes.field}
                                    name="transactionName"
                                    variant="outlined"
                                    label="Transaction *"
                                    spellCheck="true"
                                    disabled={props.disabled || values.transactionRequestId !== 0}
                                    component={TextField}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <Button
                                                    startIcon={isCheckingTransactionNameUniqueness ?
                                                        <CircularProgress size={20} className={classes.loader} />
                                                        :
                                                        <>
                                                            {isUnique === null ?
                                                                ""
                                                                :
                                                                isUnique === true
                                                                    ? <CheckIcon className={classes.transactionNameUnique} />
                                                                    : <CloseIcon className={classes.transactionNameNotUnique} />
                                                            }
                                                        </>
                                                    }
                                                    disabled={props.disabled}>
                                                </Button>
                                            </InputAdornment>
                                    }}
                                />
                                <FieldError name="transactionName" errors={state.errors} />

                                {!isUnique && state.existingTransactionIdWithSameName &&
                                    <FieldError name="transactionName" errors={[{ errorId: "transactionName", message: getTransactionNameValidationMessage() }]} />}

                            </Grid>
                            <Grid item xl={12} md={12} xs={12}>
                                <Field
                                    className={classes.field}
                                    variant="outlined"
                                    type="text"
                                    name="sapCodeDescription"
                                    label="SAP Code Description *"
                                    disabled={props.disabled}
                                    component={TextField}
                                    autoComplete="new-password"
                                />
                                <FieldError name="sapCodeDescription" errors={state.errors} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xl={12} xs={12} md={12}>
                <Card>
                    <CardHeader title="Data needed for Full Description" />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field
                                    multiline
                                    type="text"
                                    className={classes.field}
                                    name="transactionScope"
                                    variant="outlined"
                                    helperText="Detailed description of the Transaction scope, specify what the transaction does and what is the objective"
                                    label="Transaction Scope (Function) *"
                                    disabled={props.disabled}
                                    component={TextField}
                                />
                                <FieldError name="transactionScope" errors={state.errors} />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    multiline
                                    type="text"
                                    className={classes.field}
                                    name="relevantParameters"
                                    variant="outlined"
                                    helperText="List all mandatory or relevant/significant Parameters"
                                    label="Mandatory/Relevant Selection Parameters *"
                                    disabled={props.disabled}
                                    component={TextField}
                                />
                                <FieldError name="relevantParameters" errors={state.errors} />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    multiline
                                    type="text"
                                    className={classes.field}
                                    name="transactionRunResult"
                                    variant="outlined"
                                    helperText="Describe the impacts and result of the transaction run (what the transaction is for and what generates) and if created ad-hoc for specific Companies"
                                    label="Transaction Run Result *"
                                    disabled={props.disabled}
                                    component={TextField}
                                />
                                <FieldError name="transactionRunResult" errors={state.errors} />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    multiline
                                    type="text"
                                    className={classes.field}
                                    name="relatedTransactions"
                                    variant="outlined"
                                    helperText="Highlight if there are transactions strictly related to another one (eg: transaction CK11N necessary to CK24)."
                                    label="Related Transactions *"
                                    disabled={props.disabled}
                                    component={TextField}
                                />
                                <FieldError name="relatedTransactions" errors={state.errors} />

                            </Grid>
                            <Grid item xl={6} md={6} xs={12}>
                                <Field
                                    type="text"
                                    className={classes.field}
                                    name="output"
                                    variant="outlined"
                                    helperText="if it generates only reporting or, instead, it creates/changes/deletes documents. If Documents are created/changed/deleted, specify which ones."
                                    label="Output Type *"
                                    disabled={props.disabled}
                                    component={TextField}
                                />
                                <FieldError name="output" errors={state.errors} />

                            </Grid>
                            <Grid item xl={6} md={6} xs={12}>
                                <Field
                                    type="text"
                                    className={classes.field}
                                    name="outputExport"
                                    variant="outlined"
                                    helperText="if it can be downloaded"
                                    label="Output Export Available? *"
                                    disabled={props.disabled}
                                    component={TextField}
                                />
                                <FieldError name="outputExport" errors={state.errors} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xl={12} xs={12} md={12}>
                <Card>
                    <CardHeader title="Tree Structure & Cockpit Information" />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field
                                    name="isTreeStructure"
                                    label="Is the Transaction a Tree Structure Transaction? *"
                                    disabled={isSavingRequest || props.disabled}
                                    className={classes.field}
                                    component={Switch}
                                />
                                <FieldError name="isTreeStructure" errors={state.errors} />

                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name="isTreeStructureReporting"
                                    label="Is the Transaction part of a Tree Structure Transaction? (i.e. ZPROCODA is into ZF_98) *"
                                    disabled={isSavingRequest || props.disabled}
                                    className={classes.field}
                                    component={Switch}
                                />
                                <FieldError name="isTreeStructureReporting" errors={state.errors} />

                            </Grid>

                            {values.isTreeStructureReporting &&
                                <>
                                    <Grid item xl={6} md={6} xs={12}>
                                        <AsyncAutocomplete
                                            name="treeStructureReportingTransactions"
                                            variant="outlined"
                                            helperText="If the transaction is inserted within a Tree Structure, select the Tree Transaction"
                                            label="Tree Structure Reporting *"
                                            disabled={props.disabled}
                                            className={classes.field}
                                            isLoading={isSearchingCompletedRequestOptions}
                                            updateOptions={searchCompletedRequestOptionsTree} />
                                        <FieldError name="treeStructureReportingTransactions" errors={state.errors} />

                                    </Grid>

                                    <Grid item xl={6} md={6} xs={12}>
                                        <Field
                                            type="text"
                                            className={classes.field}
                                            name="treeStructureReportingOther"
                                            variant="outlined"
                                            helperText="Other Information on Tree Structure"
                                            label="Other"
                                            disabled={props.disabled}
                                            component={TextField}
                                        />
                                        <FieldError name="treeStructureReportingOther" errors={state.errors} />

                                    </Grid>
                                </>}

                            <Divider className={dividerClasses} />

                            {/* in the future use this because material ui select does not scale with number of options
https://codesandbox.io/s/material-demo-forked-2zep7?file=/demo.js
and look here
https://github.com/mui-org/material-ui/issues/17001 */}

                            <Grid item xs={12}>
                                <Field
                                    name="isTransactionIncludedInCockpit"
                                    label="Is the Transaction a Cockpit? *"
                                    disabled={isSavingRequest || props.disabled}
                                    className={classes.field}
                                    component={Switch}
                                />
                                <FieldError name="isTransactionIncludedInCockpit" errors={state.errors} />

                            </Grid>
                            {values.isTransactionIncludedInCockpit &&
                                <Grid item xs={12}>
                                    <Field
                                        multiline
                                        type="text"
                                        className={classes.field}
                                        name="cockpitTransactions"
                                        variant="outlined"
                                        helperText="List the Name of all Functionalities this Cockpit allows the user to (and for each functionality write the relative Transaction called)"
                                        label="Transactions included in Cockpit *"
                                        disabled={props.disabled}
                                        component={TextField}
                                    />
                                    <FieldError name="cockpitTransactions" errors={state.errors} />

                                </Grid>
                            }
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xl={12} xs={12} md={12}>
                <Card>
                    <CardHeader title="Similar Transaction Reference" />
                    <CardContent>
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <Field
                                    name="hasSimilarTransactionReference"
                                    label="Is there a similar transaction reference? *"
                                    disabled={isSavingRequest || props.disabled}
                                    className={classes.field}
                                    component={Switch}
                                />
                                <FieldError name="hasSimilarTransactionReference" errors={state.errors} />
                            </Grid>

                            {values.hasSimilarTransactionReference && (
                                <>
                                    <Grid item xs={12}>
                                        <AsyncAutocomplete
                                            name="similarTransactionReferences"
                                            variant="outlined"
                                            helperText="Select the similar Transaction/Transactions"
                                            label="Similar Transaction Reference *"
                                            disabled={props.disabled}
                                            className={classes.field}
                                            isLoading={isSearchingCompletedRequestOptions}
                                            updateOptions={searchCompletedRequestOptions} />
                                        <FieldError name="similarTransactionReferences" errors={state.errors} />

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            multiline
                                            type="text"
                                            className={classes.field}
                                            name="similarTransactionFunctionalDifference"
                                            variant="outlined"
                                            helperText="Highlight the functional differences from the New and the Reference Transaction"
                                            label="Functional Difference between Transaction and its Similar *"
                                            disabled={props.disabled}
                                            component={TextField}
                                        />
                                        <FieldError name="similarTransactionFunctionalDifference" errors={state.errors} />

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Select
                                            name="transactionModel"
                                            label="Similar Transaction Type (Custom or Standard?)"
                                            disabled={props.disabled}
                                            className={classes.field}
                                            options={[
                                                { value: 0, label: "Custom" },
                                                { value: 1, label: "Standard" }]}>
                                        </Select>
                                        <FieldError name="transactionModel" errors={state.errors} />
                                    </Grid>
                                </>)}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xl={12} xs={12} md={12}>
                <Card>
                    <CardHeader title="Replacing Transaction Reference" />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field
                                    multiline
                                    type="text"
                                    className={classes.field}
                                    name="newOrReplacingTransaction"
                                    variant="outlined"
                                    helperText="Type here if the Transaction is new, is replacing a transaction or is obsolete"
                                    label="New Transaction or already Existing in Production Env."
                                    disabled={props.disabled}
                                    component={TextField}
                                />
                                <FieldError name="newOrReplacingTransaction" errors={state.errors} />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    label="Is a replacing transaction?"
                                    name="isReplacingTransaction"
                                    disabled={isSavingRequest || props.disabled}
                                    className={classes.field}
                                    component={Switch}
                                />
                                <FieldError name="isReplacingTransaction" errors={state.errors} />

                            </Grid>
                            {values.isReplacingTransaction && (
                                <Grid item xs={12}>
                                    <AsyncAutocomplete
                                        name="replacingTransactions"
                                        variant="outlined"
                                        label="Replacing transaction *"
                                        disabled={props.disabled}
                                        className={classes.field}
                                        isLoading={isSearchingCompletedRequestOptions}
                                        updateOptions={searchCompletedRequestOptions}
                                    //onChange={handleReplacingTransactions()}
                                    />
                                    <FieldError name="replacingTransactions" errors={state.errors} />

                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <Field
                                    label="Is this transaction Obsolete?"
                                    name="isTransactionObsolete"
                                    disabled={isSavingRequest || props.disabled}
                                    className={classes.field}
                                    component={Switch}
                                />
                                <FieldError name="isTransactionObsolete" errors={state.errors} />
                            </Grid>
                        </Grid>

                    </CardContent>
                </Card>
            </Grid>

            <Grid item xl={12} xs={12} md={12}>
                <Card>
                    <CardHeader title="Transaction Technical Information" />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Select
                                    name="mode"
                                    helperText=""
                                    label="Transaction Mode *"
                                    disabled={props.disabled}
                                    className={classes.field}
                                    options={[
                                        { value: 0, label: "Create / Edit" },
                                        { value: 1, label: "Display only" }]}>
                                </Select>
                                <FieldError name="mode" errors={state.errors} />

                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    label="If Create/Edit Transaction, is there a Transaction which shows the same data in Display Only?"
                                    name="isThereTransactionSameDataInDisplayMode"
                                    disabled={isSavingRequest || props.disabled}
                                    className={classes.field}
                                    component={Switch}
                                />
                                <FieldError name="isThereTransactionSameDataInDisplayMode" errors={state.errors} />

                            </Grid>
                            {values.isThereTransactionSameDataInDisplayMode && (
                                <Grid item xs={12}>
                                    <AsyncAutocomplete
                                        name="displayOnlyTransactions"
                                        variant="outlined"
                                        helperText="List the Display Only Transaction"
                                        label="Display only transactions *"
                                        disabled={props.disabled}
                                        className={classes.field}
                                        isLoading={isSearchingCompletedRequestOptions}
                                        updateOptions={searchCompletedRequestOptions} />
                                    <FieldError name="displayOnlyTransactions" errors={state.errors} />
                                </Grid>
                            )}

                            <Divider className={dividerClasses} />

                            <Grid item xs={12}>
                                <Select
                                    multiple
                                    name="companiesInScope"
                                    helperText="Select the Companies for which the Transaction could potentially work (i.e. MIGO could be potentially be released to everyone)"
                                    label="Company in scope *"
                                    disabled={props.disabled}
                                    className={classes.field}
                                    options={getComplexOptionsByEntity(ComplexEntityNames.Company).sort((a, b) => a.label.localeCompare(b.label))}>
                                </Select>
                                <FieldError name="companiesInScope" errors={state.errors} />

                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xl={12} xs={12} md={12}>
                <Card>
                    <CardHeader title="Program Technical Information" />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field
                                    type="text"
                                    className={classes.field}
                                    name="program"
                                    variant="outlined"
                                    helperText="SAP Transaction Program/Report Name"
                                    label="Program *"
                                    disabled={props.disabled}
                                    component={TextField}
                                />
                                <FieldError name="program" errors={state.errors} />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    label="Is there an integration with other Transactions?"
                                    name="isThereIntegrationOtherTransactions"
                                    helperText="(i.e., The transaction run a Job (through an User) that run another Transaction)"
                                    disabled={isSavingRequest || props.disabled}
                                    className={classes.field}
                                    component={Switch}
                                />
                                <FieldError name="isThereIntegrationOtherTransactions" errors={state.errors} />
                            </Grid>
                            {values.isThereIntegrationOtherTransactions && (
                                <Grid item xs={12}>
                                    <AsyncAutocomplete
                                        name="integratedTransactions"
                                        variant="outlined"
                                        label="Integrated transactions *"
                                        disabled={props.disabled}
                                        className={classes.field}
                                        isLoading={isSearchingCompletedRequestOptions}
                                        updateOptions={searchCompletedRequestOptions} />
                                    <FieldError name="integratedTransactions" errors={state.errors} />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <AsyncAutocomplete
                                    name="tablesUpdated"
                                    variant="outlined"
                                    helperText="Select all the Tables in which the Transaction could create/edit/delete data"
                                    label="Tables updated by the transaction *"
                                    disabled={props.disabled}
                                    className={classes.field}
                                    isLoading={isLoadingTableOptions}
                                    updateOptions={searchTableOptionsTables}
                                />
                                <FieldError name="tablesUpdated" errors={state.errors} />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    multiline
                                    type="text"
                                    className={classes.field}
                                    name="involvementNote"
                                    variant="outlined"
                                    helperText="Type here if this Transaction can work only if a specific table is properly configurated (if yes, type the name of the table)"
                                    label="Involvement/link with Custom/Standard Table (i.e. parameters for transaction usage) *"
                                    disabled={props.disabled}
                                    component={TextField}
                                />
                                <FieldError name="involvementNote" errors={state.errors} />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    label="Does the Transaction run a Job in background?"
                                    name="hasBackgroundJob"
                                    disabled={isSavingRequest || props.disabled}
                                    className={classes.field}
                                    component={Switch}
                                />
                                <FieldError name="hasBackgroundJob" errors={state.errors} />
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card >
            </Grid>

            <Grid item xl={12} xs={12} md={12}>
                <Card>
                    <CardHeader title="Authority Check" />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field
                                    name="hasAuthorityCheck"
                                    label="Is there any authority check? *"
                                    disabled={isSavingRequest || props.disabled}
                                    className={classes.field}
                                    component={Switch}
                                />
                                <FieldError name="hasAuthorityCheck" errors={state.errors} />
                            </Grid>

                            {values.hasAuthorityCheck && (
                                <>
                                    <Grid item xs={12}>
                                        <Field
                                            multiline
                                            type="text"
                                            className={classes.field}
                                            name="authorityCheckProgramNote"
                                            variant="outlined"
                                            label="Authority Check inserted directly into the Program *"
                                            helperText="List all Object; Field and Value requested (i.e. F_BKPF_BUK with BUKRS and ACTVT = 03)"
                                            disabled={props.disabled}
                                            component={TextField}
                                        />
                                        <FieldError name="authorityCheckProgramNote" errors={state.errors} />
                                    </Grid>

                                    <Divider className={dividerClasses} />

                                    <Grid item md={6} xs={12}>
                                        <AsyncAutocomplete
                                            name="authorityCheckTransactions"
                                            variant="outlined"
                                            label="Authority Check from other Transaction"
                                            disabled={props.disabled}
                                            className={classes.field}
                                            isLoading={isSearchingCompletedRequestOptions}
                                            updateOptions={searchCompletedRequestOptions} />
                                        <FieldError name="authorityCheckTransactions" errors={state.errors} />
                                    </Grid>

                                    <Grid item md={6} xs={12}>
                                        <Field
                                            multiline
                                            type="text"
                                            className={classes.field}
                                            name="authorityCheckTransactionNote"
                                            variant="outlined"
                                            label="Authority Check details from transaction"
                                            disabled={props.disabled}
                                            component={TextField}
                                        />
                                        <FieldError name="authorityCheckTransactionNote" errors={state.errors} />
                                    </Grid>

                                    <Divider className={dividerClasses} />

                                    <Grid item md={6} xs={12}>
                                        <Field
                                            type="text"
                                            className={classes.field}
                                            name="authorityCheckBapi"
                                            variant="outlined"
                                            label="Authority Check from BAPI Code"
                                            helperText="List Names of BAPIs (i.e. BAPI_REPORT_CREATE)"
                                            disabled={props.disabled}
                                            component={TextField}
                                        />
                                        <FieldError name="authorityCheckBapi" errors={state.errors} />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Field
                                            multiline
                                            type="text"
                                            className={classes.field}
                                            name="authorityCheckBapiNote"
                                            variant="outlined"
                                            helperText=""
                                            label="Authority Check details from BAPI"
                                            disabled={props.disabled}
                                            component={TextField}
                                        />
                                        <FieldError name="authorityCheckBapiNote" errors={state.errors} />
                                    </Grid>
                                </>)}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xl={12} xs={12} md={12}>
                <Card>
                    <CardHeader title="Users & Go-live" />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xl={4} md={12} xs={12}>
                                <Select
                                    name="requestType"
                                    helperText=""
                                    label="Request type *"
                                    disabled={props.disabled}
                                    className={classes.field}
                                    options={[
                                        { value: 0, label: "Change" },
                                        { value: 1, label: "Incident" },
                                        { value: 2, label: "Project Code" },
                                        { value: 3, label: "N/A" }]}>
                                </Select>
                                <FieldError name="requestType" errors={state.errors} />
                            </Grid>
                            <Grid item xl={4} md={12} xs={12}>
                                <Field
                                    type="text"
                                    className={classes.field}
                                    name="incidentNumber"
                                    variant="outlined"
                                    helperText=""
                                    label="Incident Number (or CHG; Project ID) *"
                                    disabled={props.disabled}
                                    component={TextField}
                                />
                                <FieldError name="incidentNumber" errors={state.errors} />
                            </Grid>
                            <Grid item xl={4} md={12} xs={12}>
                                <Field
                                    type="date"
                                    className={classes.field}
                                    name="goLiveDate"
                                    variant="outlined"
                                    helperText=""
                                    label="Transaction Go-live date *"
                                    disabled={props.disabled}
                                    component={TextField}
                                />
                                <FieldError name="goLiveDate" errors={state.errors} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card >
            </Grid>

            <Grid item xl={12} xs={12} md={12}>
                <Card>
                    <CardHeader title="Notes" />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field
                                    name="canTransactionRunByFile"
                                    label="Can the transaction be run by Excel File? *"
                                    disabled={isSavingRequest || props.disabled}
                                    className={classes.field}
                                    component={Switch}
                                />
                                <FieldError name="canTransactionRunByFile" errors={state.errors} />
                            </Grid>
                            {values.canTransactionRunByFile &&
                                <Grid item xs={12}>
                                    <InputFiles
                                        title="Template File"
                                        isMassiveImport={false}
                                        onFileUploaded={fileUploadedHandler}
                                    />
                                    <FieldError name="techDocumentation" errors={state.errors} />

                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Field
                                    multiline
                                    type="text"
                                    className={classes.field}
                                    name="techInfoNotes"
                                    variant="outlined"
                                    helperText=""
                                    label="Notes"
                                    disabled={props.disabled}
                                    component={TextField}
                                />
                                <FieldError name="techInfoNotes" errors={state.errors} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card >
            </Grid>
        </>
    );
}