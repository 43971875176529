import { RequestStatus } from '../helpers';
import { intersection } from 'lodash';

export const getRequestStatusText = (request) => {
    let message = getRequestStatusMessage(request.status, request.reworkInProgress);
    if (request.alreadyApprovedOnce && request.status !== RequestStatus.Approved)
        message += " (restarted)";
    return message;
}

export const getRequestStatusMessage = (status, reworkInProgress) => {
    let message = "";

    switch (status) {
        case RequestStatus.Draft:
            message = "Draft";
            break;
        case RequestStatus.WaitingForCiso:
            message = reworkInProgress ? "Waiting for CISO (rework in progress)" : "Waiting for CISO";
            break;
        case RequestStatus.WaitingForApproval:
            message = "Waiting for SoD Activity Rework";
            break;
        case RequestStatus.Approved:
            message = "Approved";
            break;
        case RequestStatus.Deleted:
            message = "Deleted";
            break;
        case RequestStatus.ToBeReviewed:
            message = "Review";
            break;
        case RequestStatus.WaitingForBusiness:
            message = reworkInProgress ? "Waiting for Business (rework in progress)" : "Waiting for Business";
            break;
        case RequestStatus.Rework:
            message = "Rework";
            break;
        default:
            message = "Unknown";
            break;
    }

    return message;
}

export const getRequestPerformanceText = (status, performance) => {
    const interval = performance / 30.5;
    if (interval >= 1) {
        const months = Math.floor(interval)
        const days = Math.floor((interval % 1) * 30.5);
        return `${months} ${months > 1 ? "months" : "month"} ${days > 0 ? `${days} ${(days > 1 ? "days" : "day")}` : ""}`;
    } else {
        const days = Math.floor(performance);
        if (days >= 1) {
            return `${days} days`;
        } else {
            return status === RequestStatus.Approved ? "Less than 1 day" : "Today";
        }
    }
}

const COLORS = [
    "#003f5c",
    "#ffa600",
    "#2f4b7c",
    "#ff7c43",
    "#665191",
    "#f95d6a",
    "#a05195",
    "#d45087",
]

export const mapColorsToLabels = labels => {
    let labelColors = {}; // colors used for each label
    const usedKeys = intersection(Object.keys(labelColors), labels);
    let firstAvailColor = usedKeys.length; // sensible place to start looking for new colors

    let chartColors = [];
    let usedColors = {};

    // get previously used colors for all labels in current report
    usedKeys.forEach(label => {
        usedColors[labelColors[label]] = true;
    });

    labels.forEach(label => {
        // if we've never seen this label before
        if (!labelColors[label]) {

            while (usedColors[COLORS[firstAvailColor]]) {
                // if we are already using this color, get the next color
                firstAvailColor += 1;
            }
            // if we are not already using this color, save it
            labelColors[label] = COLORS[firstAvailColor];
            firstAvailColor += 1;
        }

        // add color for new label to array that we will push to Chart.js
        chartColors.push(labelColors[label]);
    });


    // return 1D array of colors assigned to current labels
    return chartColors;
};

export const niceTruncate = (stringValue, limit, useWordBoundary) => {
    if (stringValue.length <= limit) { return stringValue; }
    const subString = stringValue.substr(0, limit - 1); // the original check
    return (useWordBoundary
        ? subString.substr(0, subString.lastIndexOf(" "))
        : subString) + "…";
};