import React from 'react';
import jwt from 'jsonwebtoken';

import { AuthenticationContext, adalGetToken, adalFetch } from 'react-adal';

import config from '../../config.json';
const adalConfig = {
    tenant: config.adal.tenant,
    clientId: config.adal.clientId,
    redirectUri: config.adal.redirectUri,
    endpoints: {
        api: config.adal.endpoints.api
    }
};

export const AdalAuthContext = new AuthenticationContext(adalConfig);

export const AuthContext = React.createContext(null);

const { Provider } = AuthContext;
export const AuthProvider = (props) => {

    const adalAuthContext = AdalAuthContext;

    const getToken = () => {
        return adalGetToken(adalAuthContext, adalConfig.endpoints.api);
    }

    const getCachedToken = () => {
        return adalAuthContext.getCachedToken(adalConfig.clientId);
    }

    const getUserTokenInfo = () => {
        if (!isAuthenticated()) return null;
        return adalAuthContext.getCachedUser();        
    }

    const logOut = () => {
        adalAuthContext.logOut();        
    }

    const isAuthenticated = () => {
        const token = getCachedToken();
        return token && token.length > 0;
    }

    const fetchAdal = (fetch, api, cfg) => {
        return adalFetch(adalAuthContext, adalConfig.endpoints.api, fetch, api, cfg);
    }

    return (
        <Provider value={{
            getToken,
            getCachedToken,
            getUserTokenInfo,
            logOut,
            isAuthenticated,
            fetchAdal
        }}>
            {props.children}
        </Provider>
    );
}