import { Button, Card, CardContent, colors, Grid, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DownloadIcon from '@material-ui/icons/FileCopyOutlined';
import React, { useContext, useEffect } from 'react';
import { DateTime } from '../../../../common';
import { ImportContext } from '../../ImportContext';
import './../../../form.css';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    field: {
        width: '100%',
        "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.6)" // (default alpha is 0.38)
        }
    },
    invalidField: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    transactionNameUnique: {
        color: colors.green[500]
    },
    transactionNameNotUnique: {
        color: colors.red[500]
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    errorLink: {
        color: "red"
    },
    skeleton: {
        margin: theme.spacing(1)
    },
    viewall: {
        marginLeft: theme.spacing(2)
    },
    blackHeader: {
        backgroundColor: '#333333',
    },
    whiteHeaderLabel: {
        color: "white"
    },
    chip: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    buttonIcon: {
        marginRight: theme.spacing(1)
    },
    drawerHeight: {
        height: "70px"
    },
    drawerButton: {
        height: "100%",
        width: "100%"
    },
}));

export const ImportAuditForm = (props) => {
    const classes = useStyles();

    const {
        state,
        isLoadingMassiveImports,
        getStoredMassiveImports,
        getStoredMassiveImportStep1,
        getStoredMassiveImportStep2
    } = useContext(ImportContext)

    const downloadClickHandler = async (step, massiveImport) => {
        switch (step) {
            case 'step1':
                await getStoredMassiveImportStep1(massiveImport);
                break;
            case 'step2':
                await getStoredMassiveImportStep2(massiveImport);
                break;
        }
    }

    useEffect(() => { getStoredMassiveImports() }, []);

    return (
        <>
            <Grid item xl={12} xs={12} md={12}>
                <Card>
                    {/* <CardHeader title="I" /> */}
                    <CardContent>
                        <Table>
                            <TableHead>
                                <TableRow className={props.blackHeader ? classes.blackHeader : null}>
                                    <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Date</TableCell>
                                    {/*<TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>User</TableCell>*/}
                                    <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Transaction imported step 1</TableCell>
                                    <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Transaction failed step 1</TableCell>
                                    <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Download summary step 1</TableCell>
                                    <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Transaction imported step 2</TableCell>
                                    <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Transaction failed step 2</TableCell>
                                    <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Download summary step 2</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoadingMassiveImports && <LinearProgress color="primary" />}
                                {!isLoadingMassiveImports ?
                                    state.massiveImports.map((row, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell> <Typography > <DateTime value={row.date} date /></Typography> </TableCell>
                                                {/*<TableCell> <Typography> {row.displayName} </Typography> </TableCell>*/}
                                                <TableCell> <Typography> {row.transactionsImportedStep1} </Typography> </TableCell>
                                                <TableCell> <Typography> {row.transactionsFailedStep1} </Typography> </TableCell>
                                                <TableCell> <Typography> {row.contentStep1 !== null ? <Button
                                                    color="secondary"
                                                    size="small"
                                                    variant="contained"
                                                    onClick={() => downloadClickHandler('step1', row)}
                                                >
                                                    <DownloadIcon />
                                                </Button>
                                                    : undefined
                                                }</Typography> </TableCell>

                                                <TableCell> <Typography> {row.transactionsImportedStep2} </Typography> </TableCell>
                                                <TableCell> <Typography> {row.transactionsFailedStep2} </Typography> </TableCell>
                                                <TableCell> <Typography> {row.contentStep2 !== null ? <Button
                                                    color="secondary"
                                                    size="small"
                                                    variant="contained"
                                                    onClick={() => downloadClickHandler('step2', row)}
                                                >
                                                    <DownloadIcon />
                                                </Button>
                                                    : undefined
                                                }</Typography> </TableCell>
                                            </TableRow>
                                        );
                                    })
                                    :
                                    undefined
                                }
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card >
            </Grid>
        </>
    );
}