import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { 
    Card,
    CardContent,
    CardHeader,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Link,
    colors
 } from '@material-ui/core';

 import {
     DateTime
 } from '../../../common';

import {HomeContext} from '../HomeContext'
import { NavContext, NavRoutes } from '../../../contexts';
import { TodoAction } from '../../../../helpers'

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { BorderAll } from '@material-ui/icons';
const useStyles = makeStyles((theme) => ({
    skeleton:{
        margin: theme.spacing(1)
    },
    empty: {
        width: "100%",
        textAlign: "center",
        color: colors.grey[600],
        marginTop: "32px"
    }
}));

const LinkRouter = props => <Link {...props} component={RouterLink} />;

export const ToDoList = (props) => {
    const classes = useStyles();
    const { getRouteTo } = useContext(NavContext);
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const { 
        state,
        isLoadingToDos,
    } = useContext(HomeContext);

    const getListItem = (toDo, index) => {
        let routeTo = null;
        switch(toDo.action){
            case TodoAction.OpenRequest:
                routeTo = getRouteTo(NavRoutes.EditRequest, [toDo.transactionRequestId]);
                break;

            case TodoAction.OpenRolesList:
                routeTo = getRouteTo(NavRoutes.Admin.Roles);
                break;

            default:
                break;
        }
        return (
            <ListItem key={index}>
                <ListItemIcon>
                    <CheckBoxOutlineBlankIcon />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography>                                    
                            <LinkRouter key={index} color="textPrimary" to={routeTo}>
                                {toDo.title}
                            </LinkRouter>                                        
                        </Typography>
                    }
                    secondary={
                        <>
                            <Typography component="span" variant="caption">{toDo.description}</Typography>
                            <Typography component="span" variant="caption"> at <DateTime value={toDo.statusChangeDate} time /></Typography>
                            <Typography component="span" variant="caption"> on <DateTime value={toDo.statusChangeDate} date /></Typography>
                        </>
                    }/>
            </ListItem>
        );
    }
    
    const getSkeleton = (props) =>{
    
        var i;
        var list = []
        for (i = 0; i < props.number; i++) {
            list.push(<Skeleton key={i} className={props.class} variant="rect" width="100%" height="60px" />) 
        }
        return list;
    }    

    return (
        <Card style={{height: isMobile ? null : "500px"}}>
            <CardContent>
                {isLoadingToDos && getSkeleton({number: 5, class: classes.skeleton})}
                {!isLoadingToDos && (
                    <>
                        <CardHeader title="To-do list"/>
                        <List>
                            {state.toDos && state.toDos.length > 0 ?
                                state.toDos.map((toDo, index) => getListItem(toDo, index))
                                :
                                <Typography component="div" variant="subtitle2" className={classes.empty}>
                                    Your TO DOs list is empty!
                                </Typography>
                            }
                        </List>
                    </>  
                )}
            </CardContent>
        </Card>
    );
}