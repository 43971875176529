import {
    Card, CardContent, CardHeader, colors, Grid, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { ImportContext } from '../../ImportContext';
import { TabsContext } from '../TabsContext';
import { InputFiles } from '../../../../common';
import './../../../form.css';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    field: {
        width: '100%',
        "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.6)" // (default alpha is 0.38)
        }
    },
    invalidField: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    transactionNameUnique: {
        color: colors.green[500]
    },
    transactionNameNotUnique: {
        color: colors.red[500]
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    errorLink: {
        color: "red"
    },
    skeleton: {
        margin: theme.spacing(1)
    },
    viewall: {
        marginLeft: theme.spacing(2)
    },
    blackHeader: {
        backgroundColor: '#333333',
    },
    whiteHeaderLabel: {
        color: "white"
    },
    chip: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    buttonIcon: {
        marginRight: theme.spacing(1)
    },
    drawerHeight: {
        height: "70px"
    },
    drawerButton: {
        height: "100%",
        width: "100%"
    },
}));

export const NewImportForm = (props) => {
    const classes = useStyles();

    const {
        state,
        getImportTemplate,
        getStoredMassiveImports,
    } = useContext(ImportContext)

    const {
        values,
        isSavingRequest,
        setFieldValue,
    } = useContext(TabsContext);

    const mockup = [{
        transactionName: 'data',
        errorList: 'user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,user,'
    }]

    const fileUploadedHandler = (storedFile) => {
        debugger;
        // se c'è  piu di un file segnalare  errore
        // fare  chiamata di  import
        // 
        const docs = [].concat(values.techDocumentation || []);
        docs.push({ ...storedFile });
        // We need to set the value manually
        // otherwise setFieldValue will not work property
        values.techDocumentation = docs;
        setFieldValue("techDocumentation", docs);
    }

    const fileRemovedHandler = (storedFile) => {
        const docs = values.riskEvaluation.sheetInfoDocs.filter(x => x.storedFileId !== storedFile.storedFileId);
        // We need to set the value manually
        // otherwise setFieldValue will not work property
        values.riskEvaluation.sheetInfoDocs = docs;
        setFieldValue("riskEvaluation.sheetInfoDocs", docs);
    }

    const onClickDownloadHanlder = async () => {
        await getImportTemplate();
        await getStoredMassiveImports();
    }

    const dividerClasses = `${classes.field} ${classes.divider}`;
    return (
        <>
            <Grid item xl={12} xs={12} md={12}>

                <Card>
                    <CardHeader title="Import Transactions" />
                    <CardContent>
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    onClick={() => onClickDownloadHanlder()}>
                                    Download empty Template
                                </Button>
                            </Grid>

                            <Grid item xs={12}>
                                <InputFiles
                                    title="Template File"
                                    isMassiveImport={true}
                                    onFileUploaded={fileUploadedHandler}
                                />
                            </Grid>

                            {state.actualImport !== null ?
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <Typography item xs={4} variant="h6"        >First import step</Typography>
                                        <Typography item xs={4} variant="subtitle1" >Succeded import: {state.actualImport.transactionsImportedStep1}</Typography>
                                        <Typography item xs={4} variant="subtitle1" >failed import: {state.actualImport.transactionsFailedStep1}</Typography>
                                    </Grid>

                                    {
                                        state.actualImport.errorStep2.length > 0 ?
                                            <Grid item xs={12}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow className={props.blackHeader ? classes.blackHeader : null}>
                                                            <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Transaction Name</TableCell>
                                                            <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Error List</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            state.actualImport.errorStep1 && state.actualImport.errorStep1.map((transaction, index) => {
                                                                return (
                                                                    <TableRow key={index}>
                                                                        <TableCell> <Typography> {transaction.referenceCode} </Typography> </TableCell>
                                                                        <TableCell>{transaction && transaction.fieldErrors.map((er, idx) => { return (<Typography key={idx}> {er.message} </Typography>); })}</TableCell>
                                                                    </TableRow>
                                                                )
                                                            })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </Grid>

                                            : undefined
                                    }

                                    <Grid item xs={12}>
                                        <Typography item xs={4} variant="h6"        >Second import step</Typography>
                                        <Typography item xs={4} variant="subtitle1" >Succeded import: {state.actualImport.transactionsImportedStep2}</Typography>
                                        <Typography item xs={4} variant="subtitle1" >failed import: {state.actualImport.transactionsFailedStep2}</Typography>
                                    </Grid>

                                    {
                                        state.actualImport.errorStep2.length > 0 ?
                                            <Grid item xs={12}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow className={props.blackHeader ? classes.blackHeader : null}>
                                                            <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Transaction Name</TableCell>
                                                            <TableCell className={props.blackHeader ? classes.whiteHeaderLabel : null}>Error List</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            state.actualImport.errorStep2 && state.actualImport.errorStep2.map((transaction, index) => {
                                                                return (
                                                                    <TableRow key={index}>
                                                                        <TableCell> <Typography> {transaction.referenceCode} </Typography> </TableCell>
                                                                        <TableCell>{transaction && transaction.fieldErrors.map((er, idx) => { return (<Typography key={idx}> {er.message} </Typography>); })} </TableCell>
                                                                    </TableRow>
                                                                )
                                                            })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </Grid>

                                            : undefined
                                    }
                                </Grid>
                                :
                                undefined}
                        </Grid>
                    </CardContent>
                </Card >
            </Grid >
        </>
    );
}