import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Link
} from '@material-ui/core';

import { Skeleton } from '@material-ui/lab';

import { HomeContext } from '../HomeContext'
import { NavContext, NavRoutes } from '../../../contexts';
import { RequestsList } from '../../../common'

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    skeleton: {
        margin: theme.spacing(1)
    },
    viewall: {
        marginLeft: theme.spacing(2)
    },
    header: {
        backgroundColor: '#333333',
    },
    headerLabel: {
        color: "white"
    },
    performanceChip: {
        marginTop: theme.spacing(1)
    },
    card: {
        [theme.breakpoints.up('md')]: {
            height: "500px"
        }
    }
}));

const LinkRouter = props => <Link {...props} component={RouterLink} />;

export const RequestsTable = (props) => {
    const { getRouteTo } = useContext(NavContext);
    const classes = useStyles();

    const {
        state,
        isLoadingRequests
    } = useContext(HomeContext);

    const getSkeleton = (props) => {
        var i;
        var list = []
        for (i = 0; i < props.number; i++) {
            list.push(<Skeleton key={i} className={props.class} variant="rect" width="100%" height="70px" />)
        }
        return list;
    }

    return (
        <Card className={classes.card}>
            <CardContent>
                {isLoadingRequests && getSkeleton({ number: 5, class: classes.skeleton })}
                {!isLoadingRequests && (
                    <>
                        <CardHeader title={
                            <>
                                Transaction in WIP
                                <Typography component="span" variant="overline" className={classes.viewall}>
                                    <LinkRouter color="textSecondary" to={getRouteTo(NavRoutes.RunningRequests)}>
                                        View all
                                    </LinkRouter>
                                </Typography>
                            </>
                        } />
                        <RequestsList
                            blackHeader
                            requests={state.runningRequests}
                            inChargeOf
                        />
                    </>
                )}
            </CardContent>
        </Card>
    );
}