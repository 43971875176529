import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
    Card,
    CardHeader,
    CardContent,
    CardActions,    
    Avatar,
    Typography,
    Button,
    Link
} from '@material-ui/core';

import { NavContext, NavRoutes, UserContext } from '../../../../contexts';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ApplicationRoles } from '../../../../../helpers';
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',        
        overflow: 'hidden',
        padding: 8,
    },
    card: {
        [theme.breakpoints.up('sm')]:{
            minWidth: 460,
            width: 460,
            margin: 8
        },
        [theme.breakpoints.down('xs')]:{
            minWidth: "100%",
            width: "100%",
        },
        marginTop: 8,
        marginBottom: 8,
        position: "relative",
        height: 240
    },
    cardContent:{
        marginBottom: "35px"
    },
    cardAction:{
        position:"absolute",
        bottom:0
    },
    title: {
        fontSize: 14,
    },
    description: {
        marginBottom: 12,
    }
}));

const LinkRouter = props => <Link {...props} component={RouterLink} />;

export const BlockMenu = (props) => {
    const classes = useStyles();
    
    const { 
        menuItems, 
        navigateTo
    } = useContext(NavContext);

    const {
        isUserInRole,
        isUserInRoles
    } = useContext(UserContext);    

    const itemClickHandler = (item) => {
        navigateTo(item.routePath);
    }

    return (
        <div className={classes.root}>
            {menuItems && menuItems
                .filter(item => item.isAdmin && item.isAdmin === true)
                .filter(item => item.roles === null || item.roles === undefined || isUserInRoles(item.roles) || isUserInRole(ApplicationRoles.Administrator))
                .slice(1).map((item, index) => 
                <Card key={index} className={classes.card}>
                    <CardHeader
                        title={
                            <Typography variant="h5">
                                <LinkRouter key={index} color="textSecondary" to={item.routePath}>
                                    {item.title}
                                </LinkRouter>                                        
                            </Typography>
                        }
                        avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                            {item.icon}
                        </Avatar>
                        }                                                
                    />                    
                    <CardContent className={classes.cardContent}>
                        <Typography className={classes.description}>
                            {item.description}
                        </Typography>
                    </CardContent>
                    <CardActions className={classes.cardAction}>
                        <Button size="small" onClick={() => itemClickHandler(item)}>Open</Button>
                    </CardActions>
                </Card>
            )}
        </div>
    );
}