import React, { useContext } from 'react';
import { Field } from 'formik'
import { TextField } from 'formik-material-ui';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Grid, Card, CardContent, CardHeader } from '@material-ui/core';
import { FieldError, Select, AsyncAutocomplete, Autocomplete } from '../../../../common';
import { Switch } from '../../../../common/formik';

import { AuthPlatformTableNames } from '../../../../../helpers';
import { TransactionRequestContext } from '../../TransactionRequestContext';
import { makeStyles } from '@material-ui/core/styles';
import { TabsContext } from '../TabsContext';
import Checkbox from '@material-ui/core/Checkbox';
import { CheckboxWithLabel as CheckTest } from 'formik-material-ui';


const useStyles = makeStyles((theme) => ({
    field: {
        width: '100%',
        "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.6)" // (default alpha is 0.38)
        }
    },
    invalidField: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1)
    }
}));

export const BusinessInfoForm = (props) => {
    const classes = useStyles();

    const {
        state,
        isSavingRequest,
        getTableOptions,
        searchCompletedRequestOptions,
        isSearchingCompletedRequestOptions,
    } = useContext(TransactionRequestContext);

    const {
        values,
    } = useContext(TabsContext);
    return (
        <>
            <Grid item xl={12} xs={12} md={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field
                                    name="isInCatalogue"
                                    label="TCodeCatalogue"
                                    disabled={isSavingRequest || props.disabled}
                                    className={classes.field}
                                    component={Switch}
                                />
                                <FieldError name="isInCatalogue" errors={state.errors} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xl={12} xs={12} md={12}>
                <Card>
                    <CardHeader title="Transaction Full Details" />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field
                                    multiline
                                    className={classes.field}
                                    variant="outlined"
                                    type="text"
                                    name="transactionDescriptionFunctionality"
                                    label="Transaction Description (Functionality)"
                                    disabled={props.disabled}
                                    component={TextField}
                                    autoComplete="new-password"
                                />
                                {!props.disabled &&
                                    <Grid item xs={12}>
                                        <Field
                                            component={CheckTest}
                                            type="checkbox"
                                            name="transactionScopeClick"
                                            Label={{ label: 'Reject Transaction Scope' }}
                                            //onChange={(e) => handleRejectCheckBox('transactionScope')}
                                            size="small"
                                        />
                                    </Grid>
                                }
                                {values.transactionScopeClick && !props.disabled &&
                                    <Grid item xs={12}>
                                        <Field
                                            multiline
                                            className={classes.field}
                                            variant="outlined"
                                            type="text"
                                            name="transactionScopeNote"
                                            label="Rejection note"
                                            component={TextField}
                                            disabled={props.disabled}
                                        />
                                    </Grid>
                                }

                                {!props.disabled &&
                                    <Grid item xs={12}>
                                        <Field
                                            component={CheckTest}
                                            type="checkbox"
                                            name="relevantParametersClick"
                                            Label={{ label: 'Reject Mandatory/Relevant Selection Parameters' }}
                                            //onChange={(e) => handleRejectCheckBox('relevantParameters')}
                                            size="small"
                                        />
                                    </Grid>
                                }
                                {values.relevantParametersClick && !props.disabled &&
                                    <Grid item xs={12}>
                                        <Field
                                            multiline
                                            className={classes.field}
                                            variant="outlined"
                                            type="text"
                                            name="relevantParametersNote"
                                            label="Rejection note"
                                            component={TextField}
                                            disabled={props.disabled}
                                        />
                                    </Grid>
                                }
                                {!props.disabled &&
                                    <Grid item xs={12}>
                                        <Field
                                            component={CheckTest}
                                            type="checkbox"
                                            name="transactionRunResultClick"
                                            Label={{ label: 'Reject Transaction Run Result' }}
                                            //onChange={(e) => handleRejectCheckBox('transactionRunResult')}
                                            size="small"
                                        />
                                    </Grid>
                                }
                                {values.transactionRunResultClick && !props.disabled &&
                                    <Grid item xs={12}>
                                        <Field
                                            multiline
                                            className={classes.field}
                                            variant="outlined"
                                            type="text"
                                            name="transactionRunResultNote"
                                            label="Rejection note"
                                            component={TextField}
                                            disabled={props.disabled}
                                        />
                                    </Grid>
                                }

                                {!props.disabled &&
                                    <Grid item xs={12}>
                                        <Field
                                            component={CheckTest}
                                            type="checkbox"
                                            name="relatedTransactionsClick"
                                            Label={{ label: 'Reject Related Transactions' }}
                                            //onChange={(e) => handleRejectCheckBox('relatedTransactions')}
                                            size="small"
                                        />
                                    </Grid>
                                }
                                {values.relatedTransactionsClick && !props.disabled &&
                                    <Grid item xs={12}>
                                        <Field
                                            multiline
                                            className={classes.field}
                                            variant="outlined"
                                            type="text"
                                            name="relatedTransactionsNote"
                                            label="Rejection note"
                                            component={TextField}
                                            disabled={props.disabled}
                                        />
                                    </Grid>
                                }

                                {!props.disabled &&
                                    <Grid item xs={12}>
                                        <Field
                                            component={CheckTest}
                                            type="checkbox"
                                            name="outputClick"
                                            Label={{ label: 'Reject Output Type' }}
                                            //onChange={(e) => handleRejectCheckBox('output')}
                                            size="small"
                                        />
                                    </Grid>

                                }
                                {values.outputClick && !props.disabled &&
                                    <Grid item xs={12}>
                                        <Field
                                            multiline
                                            className={classes.field}
                                            variant="outlined"
                                            type="text"
                                            name="outputNote"
                                            label="Rejection note"
                                            component={TextField}
                                            disabled={props.disabled}
                                        />
                                    </Grid>
                                }


                                {!props.disabled &&
                                    <Grid item xs={12}>
                                        <Field
                                            component={CheckTest}
                                            type="checkbox"
                                            name="outputExportClick"
                                            Label={{ label: 'Reject Output Export' }}
                                            //onChange={(e) => handleRejectCheckBox('outputExport')}
                                            size="small"
                                        />
                                    </Grid>

                                }
                                {values.outputExportClick && !props.disabled &&
                                    <Grid item xs={12}>
                                        <Field
                                            multiline
                                            className={classes.field}
                                            variant="outlined"
                                            type="text"
                                            name="outputExportNote"
                                            label="Rejection note"
                                            component={TextField}
                                            disabled={props.disabled}
                                        />
                                    </Grid>
                                }

                                <FieldError name="transactionDescriptionFunctionality" errors={state.errors} />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field
                                    className={classes.field}
                                    variant="outlined"
                                    type="text"
                                    name="sapCodeDescription"
                                    label="SAP Code Description *"
                                    component={TextField}
                                    disabled={props.disabled}
                                />
                                {!props.disabled &&
                                    <Grid item xs={12}>
                                        <Field
                                            component={CheckTest}
                                            type="checkbox"
                                            name="sapCodeDescriptionClick"
                                            Label={{ label: 'Reject this field' }}
                                            //onChange={(e) => handleRejectCheckBox('sapCodeDescription')}
                                            size="small"
                                        />
                                    </Grid>
                                }
                                {values.sapCodeDescriptionClick && !props.disabled &&
                                    <Grid item xs={12}>
                                        <Field
                                            multiline
                                            className={classes.field}
                                            variant="outlined"
                                            type="text"
                                            name="sapCodeDescriptionNote"
                                            label="Rejection note"
                                            component={TextField}
                                            disabled={props.disabled}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card >
            </Grid>

            <Grid item xl={12} xs={12} md={12}>
                <Card>
                    <CardHeader title="Functional Details" />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Select
                                    name="transactionFunction"
                                    label="Transaction Function *"
                                    disabled={props.disabled}
                                    className={classes.field}
                                    options={[
                                        { value: 0, label: "Change" },
                                        { value: 1, label: "Create" },
                                        { value: 2, label: "Display" },
                                        { value: 3, label: "Report" },
                                        { value: 4, label: "Cockpit" },
                                        { value: 5, label: "Create/Change" },
                                        { value: 6, label: "Interface" },
                                        { value: 7, label: "Print" },
                                        { value: 8, label: "Simulation" },
                                        { value: 10, label: "Import Data" },
                                        { value: 9, label: "N/A" },
                                    ]}>
                                </Select>
                                <FieldError name="transactionFunction" errors={state.errors} />
                            </Grid>
                            <Grid item xl={6} md={6} xs={12}>
                                <Select
                                    name="businessProcessId"
                                    label="Business process *"
                                    disabled={props.disabled}
                                    className={classes.field}
                                    options={getTableOptions(AuthPlatformTableNames.Process)}>
                                </Select>
                                <FieldError name="businessProcessId" errors={state.errors} />
                            </Grid>
                            <Grid item xl={6} md={6} xs={12}>
                                <Select
                                    name="businessSubProcessId"
                                    label="Sub-process *"
                                    disabled={props.disabled}
                                    className={classes.field}
                                    options={getTableOptions(AuthPlatformTableNames.SubProcess, values.businessProcessId)}>
                                </Select>
                                <FieldError name="businessSubProcessId" errors={state.errors} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card >
            </Grid>

            <Grid item xl={12} xs={12} md={12}>
                <Card>
                    <CardHeader title="Other Information" />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field
                                    className={classes.field}
                                    variant="outlined"
                                    type="text"
                                    name="transactionShortDescription"
                                    label="Transaction Short Description *"
                                    disabled={props.disabled}
                                    component={TextField}
                                />
                                <FieldError name="transactionShortDescription" errors={state.errors} />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xl={12} xs={12} md={12}>
                                <Field
                                    name="hasSimilarTransactionReference"
                                    label="Is there a similar transaction reference? *"
                                    disabled={true}
                                    className={classes.field}
                                    component={Switch}
                                />
                            </Grid>
                            {!props.disabled &&
                                <Grid item xs={12}>
                                    <Field
                                        component={CheckTest}
                                        type="checkbox"
                                        name="hasSimilarTransactionReferenceClick"
                                        Label={{ label: 'Reject this field' }}
                                        //onChange={(e) => handleRejectCheckBox('hasSimilarTransactionReference')}
                                        size="small"
                                    />
                                </Grid>
                            }
                            {values.hasSimilarTransactionReferenceClick && !props.disabled &&
                                <Grid item xs={12}>
                                    <Field
                                        multiline
                                        className={classes.field}
                                        variant="outlined"
                                        type="text"
                                        name="hasSimilarTransactionReferenceNote"
                                        label="Rejection note"
                                        component={TextField}
                                        disabled={props.disabled}
                                    />
                                </Grid>
                            }
                            {values.hasSimilarTransactionReference && (
                                <>
                                    <Grid item xs={12}>
                                        <Select
                                            name="similarTransactionExtensiveRestrictive"
                                            label="Similar Transaction: *"
                                            helperText="Describe what is different: in order to understand if, compared to the standard transaction, the scope of the new transaction is to add or limit functionalities"
                                            disabled={props.disabled}
                                            className={classes.field}
                                            options={[
                                                { value: 0, label: "Extensive" },
                                                { value: 1, label: "Restrictive" },
                                                { value: 2, label: "N/A" }
                                            ]}>
                                        </Select>
                                        <FieldError name="similarTransactionExtensiveRestrictive" errors={state.errors} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AsyncAutocomplete
                                            name="similarTransactionReferences"
                                            variant="outlined"
                                            label="Similar Transaction Reference *"
                                            disabled={true}
                                            className={classes.field}
                                            isLoading={isSearchingCompletedRequestOptions}
                                            updateOptions={searchCompletedRequestOptions} />
                                        {!props.disabled &&
                                            <Field
                                                component={CheckTest}
                                                type="checkbox"
                                                name="similarTransactionReferencesClick"
                                                Label={{ label: 'Reject this field' }}
                                                //onChange={(e) => handleRejectCheckBox('similarTransactionReferences')}
                                                size="small"
                                            />
                                        }
                                        {values.similarTransactionReferencesClick && !props.disabled &&
                                            <Grid item xs={12}>
                                                <Field
                                                    multiline
                                                    className={classes.field}
                                                    variant="outlined"
                                                    type="text"
                                                    name="similarTransactionReferencesNote"
                                                    label="Rejection note"
                                                    component={TextField}
                                                    disabled={props.disabled}
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            multiline
                                            type="text"
                                            className={classes.field}
                                            name="similarTransactionFunctionalDifference"
                                            variant="outlined"
                                            label="Functional Difference between Transaction and its Similar *"
                                            disabled={true}
                                            component={TextField}
                                        />
                                        {!props.disabled &&
                                            <Field
                                                component={CheckTest}
                                                type="checkbox"
                                                name="similarTransactionFunctionalDifferenceClick"
                                                Label={{ label: 'Reject this field' }}
                                                //onChange={(e) => handleRejectCheckBox('similarTransactionFunctionalDifference')}
                                                size="small"
                                            />
                                        }
                                        {values.similarTransactionFunctionalDifferenceClick && !props.disabled &&
                                            <Grid item xs={12}>
                                                <Field
                                                    multiline
                                                    className={classes.field}
                                                    variant="outlined"
                                                    type="text"
                                                    name="similarTransactionFunctionalDifferenceNote"
                                                    label="Rejection note"
                                                    component={TextField}
                                                    disabled={props.disabled}
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Select
                                            name="transactionModel"
                                            label="Similar Transaction Type (Custom or Standard?)"
                                            disabled={true}
                                            className={classes.field}
                                            options={[
                                                { value: 0, label: "Custom" },
                                                { value: 1, label: "Standard" }]}>
                                        </Select>
                                        {!props.disabled &&
                                            <Field
                                                component={CheckTest}
                                                type="checkbox"
                                                name="transactionModelClick"
                                                Label={{ label: 'Reject this field' }}
                                                //onChange={(e) => handleRejectCheckBox('transactionModel')}
                                                size="small"
                                            />
                                        }
                                        {values.transactionModelClick && !props.disabled &&
                                            <Grid item xs={12}>
                                                <Field
                                                    multiline
                                                    className={classes.field}
                                                    variant="outlined"
                                                    type="text"
                                                    name="transactionModelNote"
                                                    label="Rejection note"
                                                    component={TextField}
                                                    disabled={props.disabled}
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                </>)}
                        </Grid>
                    </CardContent>
                </Card >
            </Grid>

            <Grid item xl={12} xs={12} md={12}>
                <Card>
                    <CardHeader title="Notes" />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field
                                    multiline
                                    type="text"
                                    className={classes.field}
                                    name="businessInfoNotes"
                                    variant="outlined"
                                    helperText=""
                                    label="Notes"
                                    disabled={props.disabled}
                                    component={TextField}
                                />
                                <FieldError name="businessInfoNotes" errors={state.errors} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card >
            </Grid>
        </>
    );
}