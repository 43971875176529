import React, { useContext, useState } from 'react';

import {
    HttpMethods,
    FetchContext,
    ApiRoutes,
    InsightContext
} from '../../contexts';

import { useAggregatedState } from '../../../helpers';

export const HomeContext = React.createContext(null);

const { Provider } = HomeContext;
export const HomeProvider = (props) => {
    const { fetchAuthorized } = useContext(FetchContext);
    const { trackError } = useContext(InsightContext);

    const [state, setState] = useAggregatedState({
        runningRequests: [],
        toDos: [],
        stats: [],
        involved: []
    });

    const [isLoadingRequests, setIsLoadingRequests] = useState(false);
    const loadRequests = async () => {
        try {

            const filter = {
                pageIndex: 0,
                pageSize: 5,
                category: 1
            };
            setIsLoadingRequests(true);
            const r = await fetchAuthorized(ApiRoutes.Request.GetFilteredRequests(),
                {
                    method: HttpMethods.POST,
                    body: JSON.stringify(filter),
                    collectErrorDetails: true
                });

            const runningRequests = (await r.json()).items;
            const involved = runningRequests.map(x => ({ transactionRequestId: x.transactionRequestId, isLoading: false, users: null }));

            setState({ runningRequests, involved })
            setIsLoadingRequests(false);

        } catch (e) {
            console.debug(e);
            trackError(e);
            setIsLoadingRequests(false);
        }
    }

    const [isLoadingToDos, setIsLoadingToDos] = useState(false);
    const loadToDos = async () => {
        try {

            setIsLoadingToDos(true);
            const r = await fetchAuthorized(ApiRoutes.User.GetUserTodos({ pageSize: 5 }),
                {
                    method: HttpMethods.GET
                });

            let toDos = await r.json();
            setState({ toDos });
            setIsLoadingToDos(false);
        }
        catch (e) {
            console.debug(e);
            trackError(e);
            setIsLoadingToDos(false);
        }
    }

    const [isLoadingStats, setIsLoadingStats] = useState(false);
    const loadStats = async () => {
        try {
            let apiUrl = ApiRoutes.User.GetUserStatistics();

            setIsLoadingStats(true);
            const r = await fetchAuthorized(apiUrl,
                {
                    method: HttpMethods.GET
                });

            let stats = await r.json();
            setState({ stats });
            setIsLoadingStats(false);
        }
        catch (e) {
            console.debug(e);
            trackError(e);
            setIsLoadingToDos(false);
        }
    }

    return (
        <Provider value={{
            state,
            setState,

            loadRequests,
            loadToDos,
            loadStats,

            isLoadingRequests,
            isLoadingToDos,
            isLoadingStats

        }}>
            {props.children}
        </Provider>
    );
}