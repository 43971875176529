import React, { useContext } from 'react';

import { 
    Typography    
} from '@material-ui/core';

import { HistoryContext } from '../HistoryContext';

import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
    root: {        
    }
}));

export const RequestHeader = (props) => {
    const classes = useStyles(); 

    const {
        state
    } = useContext(HistoryContext);

    return (
        <div className={classes.root}>
            <Typography component="h2" gutterBottom variant="overline">
                {`#NUMBER ${state.history.transactionRequestId}`}
            </Typography>
            <Typography variant="h4">
                {state.history.transactionName}
            </Typography>
        </div>
    );
}
