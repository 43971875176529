import React, { useContext, useState } from 'react';
import { ApiRoutes, FetchContext, HttpMethods, InsightContext, NavContext, NavRoutes, } from '../../contexts';
import { useAggregatedState } from '../../../helpers';

export const ImportContext = React.createContext(null);

const { Provider } = ImportContext;
export const ImportProvider = (props) => {
    const { fetchAuthorized } = useContext(FetchContext);
    const { trackError } = useContext(InsightContext)

    const [state, setState] = useAggregatedState({
        massiveImports: [],
        actualImport: null
    });

    const {
        navigateTo
    } = useContext(NavContext);

    const [isLoadingMassiveImports, setIsLoadingMassiveImports] = useState(false);
    const getStoredMassiveImports = async () => {
        try {
            setIsLoadingMassiveImports(true);
            const r = await fetchAuthorized(ApiRoutes.MassiveImport.GetStoredMassiveImports(),
                {
                    method: HttpMethods.GET,
                });

            const response = await r.json();

            setState({ massiveImports: response });

            setIsLoadingMassiveImports(false);

        } catch (e) {
            console.debug(e);
            trackError(e);
            setIsLoadingMassiveImports(false);
            if (e.code === 403) {
                navigateTo(NavRoutes.Forbidden);
            }
        }
    }

    const [isDownLoadingMassiveImportStep1, setIsDownLoadingMassiveImportStep1] = useState(false);
    const getStoredMassiveImportStep1 = async (massiveImport) => {
        try {
            setIsDownLoadingMassiveImportStep1(true);
            const id = massiveImport.massiveImportId
            const fileName = massiveImport.fileNameStep1;

            const r = await fetchAuthorized(ApiRoutes.MassiveImport.DownloadStep1({ id }), { method: HttpMethods.GET });

            const blob = await r.blob();
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();

            setIsDownLoadingMassiveImportStep1(false);
        } catch (e) {
            console.debug(e);
            trackError(e);
            setIsDownLoadingMassiveImportStep1(false);
            if (e.code === 403) {
                navigateTo(NavRoutes.Forbidden);
            }
        }
    }

    const [isDownLoadingMassiveImportStep2, setIsDownLoadingMassiveImportStep2] = useState(false);
    const getStoredMassiveImportStep2 = async (massiveImport) => {
        try {
            setIsDownLoadingMassiveImportStep2(true);
            const id = massiveImport.massiveImportId
            const fileName = massiveImport.fileNameStep2;

            const r = await fetchAuthorized(ApiRoutes.MassiveImport.DownloadStep2({ id }), { method: HttpMethods.GET });

            const blob = await r.blob();
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();

            setIsDownLoadingMassiveImportStep2(false);
        } catch (e) {
            console.debug(e);
            trackError(e);
            setIsDownLoadingMassiveImportStep2(false);
            if (e.code === 403) {
                navigateTo(NavRoutes.Forbidden);
            }
        }
    }

    const [isLoadingImportTemplate, setIsLoadingImportTemplate] = useState(false);
    const getImportTemplate = async () => {
        try {
            setIsLoadingImportTemplate(true);
            const r = await fetchAuthorized(ApiRoutes.MassiveImport.DownloadMassiveImportTemplate({}),
                {
                    method: HttpMethods.GET,

                });

            const blob = await r.blob();
            var url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `ImportTemplate.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();

            setIsLoadingImportTemplate(false);
        } catch (e) {
            console.debug(e);
            trackError(e);
            setIsLoadingImportTemplate(false);
            if (e.code === 403) {
                navigateTo(NavRoutes.Forbidden);
            }
        }
    }

    const setActualImport = (value) => {
        setState({ actualImport: value });
    }

    return (
        <Provider value={{
            state,
            getImportTemplate,
            setActualImport,
            getStoredMassiveImports,
            getStoredMassiveImportStep1,
            getStoredMassiveImportStep2
        }}>
            {props.children}
        </Provider>
    );
}