import React, { useContext, useState } from 'react';
import { HttpMethods, FetchContext, ApiRoutes } from './FetchContext';
import { InsightContext } from './'

export const UserContext = React.createContext(null);

const { Provider } = UserContext;
export const UserProvider = (props) => {
    const { trackError } = useContext(InsightContext);
    const { fetchAuthorized } = useContext(FetchContext);

    const [userInfo, setUserInfo] = useState({ externalKey: null, roles: [] });
    const [draftRequests, setDraftRequests] = useState(0);
    const [reworkRequests, setReworkRequests] = useState(0);

    const [isLoadingInfo, setIsLoadingInfo] = useState(false);
    const getUserInfo = async () => {
        try {

            setIsLoadingInfo(true);
            const r = await fetchAuthorized(ApiRoutes.User.GetMe({ withValues: true }),
                {
                    method: HttpMethods.GET,
                });

            const userInfo = await r.json();
            setUserInfo(userInfo);
            setDraftRequests(userInfo.draftRequests);
            setReworkRequests(userInfo.reworkRequests);

            setIsLoadingInfo(false);

        } catch (e) {
            console.debug(e);
            trackError(e);
            setUserInfo({ externalKey: "not-allowed", roles: [] });
            setIsLoadingInfo(false);
        }
    }

    const isUserInRole = (roleName) => {
        if (userInfo === null || userInfo.roles === null) return false;
        if (userInfo.roles.length === 0) return false;
        const role = userInfo.roles.find(x => x.roleName === roleName);
        return role !== null && role !== undefined;
    }

    const isUserInRoles = (roleNames) => {
        if (userInfo === null || userInfo.roles === null) return false;
        if (userInfo.roles.length === 0) return false;
        const role = userInfo.roles.find(x => roleNames.find(n => x.roleName === n));
        return role !== null && role !== undefined;
    }

    const [clonedRequest, setClonedRequest] = useState(null);
    const cloneRequest = ({
        transactionShortDescription,
        similarTransactionExtensiveRestrictive,
        transactionDescriptionFunctionality,
        transactionFunction,
        businessProcessId,
        businessSubProcessId,
        isMappingCompleted,
        isInCatalogue,
        cisoSodActivities,
        releaseToUsersId,
        securityTeamActivityNotes,
        dedicatedRole,
        pfcgLink,
        noLogTransaction,
        alignmentAuthorityChecks,
        securityTeamTransactionNotes,
        sodAnalysisFeedback,
        cisoInfoNotes,
        ...request
    }) => {
        const cloned = {
            ...request,
            status: 0,
            cisoSodActivities: [],
            transactionRequestId: 0,
            transactionName: null,
            ownerKey: userInfo.externalKey // cloner sets itself as owner
        }
        setClonedRequest(cloned);
    };

    return (
        <Provider value={{

            userInfo,

            isLoadingInfo,
            getUserInfo,

            isUserInRole,
            isUserInRoles,

            draftRequests,
            setDraftRequests,
            reworkRequests,
            setReworkRequests,

            clonedRequest,
            cloneRequest
        }}>
            {props.children}
        </Provider>
    );
}