import React from 'react';
import { useSnackbar } from 'notistack';

export const ToastContext = React.createContext(null);

const { Provider } = ToastContext;
export const ToastProvider = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const defaultOptions = {
        autoHideDuration: 7000,
        
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
        }
    }

    const showInfoMessage = (message) => {
        const snackOptions = { variant: "info", ...defaultOptions };
        enqueueSnackbar(message, snackOptions);
    }

    const showSuccessMessage = (message) => {
        const snackOptions = { variant: "success", ...defaultOptions };
        enqueueSnackbar(message, snackOptions);
    }

    const showWarningMessage = (message) => {
        const snackOptions = { variant: "warning", ...defaultOptions };
        enqueueSnackbar(message, snackOptions);
    }

    const showErrorMessage = (message) => {
        const snackOptions = { variant: "error", ...defaultOptions };
        enqueueSnackbar(message, snackOptions);
    }

    return (
        <Provider value={{
            showInfoMessage,
            showSuccessMessage,
            showWarningMessage,
            showErrorMessage
        }}>
            {props.children}
        </Provider>
    );
}