import React, { useContext, useState, useEffect } from 'react';

import {
    AppBar,
    CssBaseline,
    Divider,
    Drawer,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography,
    LinearProgress,
    Tooltip,
} from '@material-ui/core';

import {
    AuthContext,
    NavContext,
    NavRoutes,
    UserContext
} from '../contexts';

import { CrumbsBar } from './CrumbsBar';

import { ApplicationRoles } from '../../helpers';

import MenuIcon from '@material-ui/icons/Menu';
import LockIcon from '@material-ui/icons/LockOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToAppOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { makeStyles, useTheme } from '@material-ui/core/styles';
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('lg')]: {
            width: drawerWidth,
            flexShrink: 0
        },
    },
    appBar: {
        backgroundColor: '#f5f2f0',
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth
        },
    },
    footer: {
        color: 'white',
        backgroundColor: 'rgba(0, 0, 0, 0.87)',
        top: 'auto',
        bottom: 0,
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth
        },
    },
    footerToolBar: {
        minHeight: '48px',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    },
    toolbar: theme.mixins.toolbar,
    toolbarButton: {
        margin: theme.spacing(1)
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: '#f5f2f0'
    },
    content: {
        flexGrow: 1,
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        },
        marginBottom: '48px'
    },
    title: {
        flexGrow: 1
    },
    user: {
        margin: theme.spacing(1)
    }
}));

export const Layout = (props) => {
    const { menuItems, navigateTo } = useContext(NavContext);
    const { container } = props;
    const classes = useStyles();
    const theme = useTheme();

    const {
        getUserTokenInfo,
        logOut
    } = useContext(AuthContext);

    const {
        userInfo,
        getUserInfo,
        isUserInRoles
    } = useContext(UserContext);

    const [mobileOpen, setMobileOpen] = useState(false);

    const drawerToggleHandler = () => {
        setMobileOpen(!mobileOpen);
    };

    const itemClickHandler = (item) => {
        navigateTo(item.routePath);
    }

    const logoutClickHandler = () => {
        logOut();
    }

    const goToAdminClickHandler = () => {
        navigateTo(NavRoutes.Admin.Dashboard);
    }

    const [username, setUsername] = useState(null);
    const [userFullName, setUserFullName] = useState(null);
    useEffect(() => {
        const userTokenInfo = getUserTokenInfo();
        if (userTokenInfo && userTokenInfo.profile) {
            setUsername(userTokenInfo.userName);
            const composedName = userTokenInfo.profile.family_name ?
                `${userTokenInfo.profile.family_name} ${userTokenInfo.profile.given_name}` :
                userTokenInfo.profile.given_name;
            setUserFullName(composedName);
        }
    }, []);

    const [ready, setIsReady] = useState(false);
    useEffect(() => {
        if (username !== null) {
            getUserInfo();
        }
    }, [username])

    useEffect(() => {
        if (userInfo !== null && userInfo.externalKey !== null) {
            setIsReady(true);
            if (userInfo.roles === null || userInfo.roles.length === 0) {
                navigateTo(NavRoutes.Forbidden);
            }
        }
    }, [userInfo])

    const drawer = (
        <div>
            <div className={classes.toolbar} >
                <img alt="bulgari logo" src="/logo.png" width="100%"></img>
            </div>
            <Divider />
            <List>
                {menuItems && menuItems
                    .filter(item => (!item.isAdmin || item.isAdmin === false)
                        && (item.roles === null || item.roles === undefined || isUserInRoles(item.roles)))
                    .map((item, index) =>
                        <ListItem
                            key={index}
                            button
                            onClick={() => itemClickHandler(item)}>
                            <ListItemIcon>{item.icon !== undefined ? item.icon : <MenuIcon />}</ListItemIcon>
                            <ListItemText primary={item.title || item.routePath} />
                        </ListItem>
                    )}
            </List>
        </div>
    );

    return (
        <div className={classes.root}>
            {ready ?
                <>
                    <CssBaseline />
                    <AppBar position="fixed" className={classes.appBar} >
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={drawerToggleHandler}
                                className={classes.menuButton}>
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" noWrap className={classes.title}>
                                <CrumbsBar />
                            </Typography>
                            <Hidden smDown>
                                <Typography variant="body1" noWrap className={classes.user}>
                                    welcome <Typography color="textSecondary" component="strong">{userFullName}</Typography>
                                </Typography>
                            </Hidden>
                            <Tooltip title="Open help page in separate tab" aria-label="help page">
                                <IconButton aria-label="help" className={classes.toolbarButton} color="inherit" href="https://lvmhwj.sharepoint.com/sites/bul-pit-SS18104/SitePages/TCode-Map-Homepage.aspx?source=https://lvmhwj.sharepoint.com/sites/bul-pit-SS18104/SitePages/Forms/ByAuthor.aspx" target="_blank">
                                    <HelpOutlineIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                            {isUserInRoles([ApplicationRoles.Administrator]) &&
                                <Tooltip title="Go to admin area" aria-label="admin area">
                                    <IconButton aria-label="admin area" className={classes.toolbarButton} color="inherit" onClick={goToAdminClickHandler}>
                                        <LockIcon color="primary" />
                                    </IconButton>
                                </Tooltip>
                            }
                            <Tooltip title="Logout" aria-label="logout">
                                <IconButton aria-label="logout" className={classes.toolbarButton} color="inherit" onClick={logoutClickHandler}>
                                    <ExitToAppIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <nav className={classes.drawer} aria-label="menu items">
                        {/* Mobile Screens */}
                        <Hidden lgUp implementation="js">
                            <Drawer
                                container={container}
                                variant="temporary"
                                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                                open={mobileOpen}
                                onClose={drawerToggleHandler}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}>
                                {drawer}
                            </Drawer>
                        </Hidden>
                        {/* Desktop Screens */}
                        <Hidden mdDown implementation="js">
                            <Drawer
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                variant="permanent"
                                open>
                                {drawer}
                            </Drawer>
                        </Hidden>
                    </nav>
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        {props.children}
                    </main>

                    <AppBar position="fixed" className={classes.footer}>
                        <Toolbar className={classes.footerToolBar}>
                            <Typography variant="subtitle1" noWrap className={classes.title}>
                                Chief Information Security Office
                            </Typography>
                            {/* <Tooltip title="link1" aria-label="link1">
                                <Button color="primary" href="#contained-buttons">
                                    Link1
                                </Button>
                            </Tooltip>
                            <Tooltip title="link2" aria-label="link2">
                                <Button color="primary" href="#contained-buttons">
                                    Link2
                                </Button>
                            </Tooltip> */}
                        </Toolbar>
                    </AppBar>
                </>
                :
                <>
                    <CssBaseline />
                    <main className={classes.content}>
                        <LinearProgress />
                    </main>
                </>
            }
        </div>
    );
}