import 'typeface-roboto';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import registerServiceWorker, { unregister } from './registerServiceWorker';

import { runWithAdal } from 'react-adal';
import { AdalAuthContext } from './components/contexts/AuthContext';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const DO_NOT_LOGIN = false;

runWithAdal(
    AdalAuthContext,
    () => {
        ReactDOM.render(
            <App baseUrl={baseUrl}/>
            , rootElement
        );

        registerServiceWorker();
    }, DO_NOT_LOGIN
);

registerServiceWorker();

// Disable service worker on production
if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    unregister();
}

