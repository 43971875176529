import React, { useContext, useState } from 'react';

// Enable accordingly
import { HttpMethods, InsightContext, FetchContext, ApiRoutes } from '../../contexts';
import { useAggregatedState, RequestStatus } from '../../../helpers';

export const TransactionRequestsContext = React.createContext(null);

const { Provider } = TransactionRequestsContext;
export const TransactionRequestsProvider = (props) => {
    const { fetchAuthorized } = useContext(FetchContext);
    const { trackError } = useContext(InsightContext);

    const [state, setState] = useAggregatedState({
        requests: [],
        isSearchingMode: false,
        totalItems: 0,
        involved: [],
        propertyList: [],
        filter: {
            category: 0,
            searchText: "",
            pageIndex: 0,
            pageSize: 30,
            propertyFilters: []
        }
    });

    const setFilter = (newData) => {
        const oldData = state.filter;
        setState({ filter: { ...oldData, ...newData } });
    }

    const [isLoadingPropertyList, setIsLoadingPropertyList] = useState(false);
    const loadPropertyList = async () => {
        try {

            setIsLoadingPropertyList(true);
            const r = await fetchAuthorized(ApiRoutes.Request.GetFilters(),
                {
                    method: HttpMethods.GET
                });

            const propertyList = await r.json();
            setState({ propertyList });

            setIsLoadingPropertyList(false);
        } catch (e) {
            console.debug(e);
            trackError(e);
            setIsLoadingPropertyList(false);
        }
    }

    const [isLoadingRequests, setIsLoadingRequests] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const loadRequests = async (filter) => {
        try {

            if (filter.searchText && filter.searchText !== "") {
                setIsSearching(true)
            } else {
                setIsLoadingRequests(true);
            }

            const r = await fetchAuthorized(ApiRoutes.Request.GetFilteredRequests(),
                {
                    method: HttpMethods.POST,
                    body: JSON.stringify(filter),
                    collectErrorDetails: true
                });

            const object = await r.json();
            const totalItems = object.totalItems;
            const requests = object.items;
            const involved = requests.map(x => ({
                transactionRequestId: x.transactionRequestId,
                isLoading: false,
                users: null
            }));
            setState({ requests, involved, totalItems });
            setIsLoadingRequests(false);
            setIsSearching(false)
        } catch (e) {
            console.debug(e);
            trackError(e);
            setIsSearching(false)
            setIsLoadingRequests(false);
        }
    }

    const [isDeletingRequest, setIsDeletingRequest] = useState(false);
    const deleteDraftRequest = async (transactionRequestId) => {
        try {

            setIsDeletingRequest(true);
            const r = await fetchAuthorized(ApiRoutes.Request.DeleteTransactionRequestDraft({ transactionRequestId }),
                {
                    method: HttpMethods.DELETE
                });

            let requests = [].concat(state.requests.filter(x => x.transactionRequestId !== transactionRequestId));
            setState({ requests });
            setState({ totalItems: state.totalItems - 1 })

            setIsDeletingRequest(false);

            return true;

        } catch (e) {
            console.debug(e);
            trackError(e);
            setIsDeletingRequest(false);

            return false;
        }
    }

    const [isAbortingRequest, setIsAbortingRequest] = useState(false);
    const abortRequest = async (transactionRequestId) => {
        try {

            setIsAbortingRequest(true);
            const r = await fetchAuthorized(ApiRoutes.Request.AbortRequest({ transactionRequestId }),
                {
                    method: HttpMethods.DELETE
                });

            let requests = [].concat(state.requests.filter(x => x.transactionRequestId !== transactionRequestId));
            setState({ requests });
            setState({ totalItems: state.totalItems - 1 })

            setIsAbortingRequest(false);

        } catch (e) {
            console.debug(e);
            trackError(e);
            setIsAbortingRequest(false);
        }
    }

    const [isExporting, setIsExporting] = useState(false);
    const getExport = async (data) => {
        try {
            setIsExporting(true);
            const r = await fetchAuthorized(ApiRoutes.Export.ExportRequests(),
                {
                    method: HttpMethods.POST,
                    body: JSON.stringify(data),
                    collectErrorDetails: true
                });

            const blob = await r.blob();
            var url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            // ISO 8601
            const timestamp = new Date().toJSON().replaceAll(':', '-').replaceAll('.', '-');
            link.setAttribute('download', `BulgariTransactionList-${timestamp}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();

            setIsExporting(false);
        } catch (e) {
            console.debug(e);
            trackError(e);
            setIsExporting(false);
        }
    }

    return (
        <Provider value={{
            state,
            isLoadingRequests,
            isSearching,
            loadRequests,

            isDeletingRequest,
            deleteDraftRequest,

            isAbortingRequest,
            abortRequest,
            
            isExporting,
            getExport,

            isLoadingPropertyList,
            loadPropertyList,

            setFilter
        }}>
            {props.children}
        </Provider>
    );
}