import React, { useContext } from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import {
    Card,
    Grid,
    CardHeader,
    Typography,
    CardContent,
} from '@material-ui/core';

import { HomeContext } from '../HomeContext'
import { mapColorsToLabels } from '../../../../helpers'

import { Skeleton } from '@material-ui/lab';
import { Category } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    skeleton: {
        margin: theme.spacing(1)
    },
    cardHeader: {
        marginBottom: theme.spacing(1)
    }
}));

const options = {
    scales: {
        xAxes: [{
            stacked: true
        }],
        yAxes: [{
            stacked: true
        }]
    }
}

export const TypeRequests = (props) => {
    const classes = useStyles();

    const {
        state,
        isLoadingStats,
    } = useContext(HomeContext);

    const {
        stats
    } = state;

    const getData = () => {

        if (stats
            && stats.totals
            && stats.totals.length > 0) {

            let months = [];
            let buCategories = [];
            stats.totals.filter(x => x.type === "requestType").forEach(x => {
                if (!months.find(month => month === x.groupName)) {
                    months.push(x.groupName);
                }
                if (!buCategories.find(buCat => buCat === x.subGroupName)) {
                    buCategories.push(x.subGroupName);
                }
            });

            const colors = mapColorsToLabels(buCategories)
            const dataSets = [];
            months.forEach((month, ixm) => {
                buCategories.forEach((buCat, ixbc) => {
                    const s = stats.totals.find(x => x.groupName === month && x.subGroupName === buCat);
                    const total = s ? s.total : 0;
                    const dataSet = dataSets.find(ds => ds.label === buCat);
                    if (!dataSet) {
                        dataSets.push({ label: buCat, data: [total], backgroundColor: colors[ixbc] })
                    } else {
                        dataSet.data.push(total)
                    }
                });
            });

            return { datasets: dataSets, labels: months }
        } else {
            return {}
        }
    }

    return (
        <Card>
            <CardContent>
                {isLoadingStats && (
                    <Grid container justify="center">
                        <Grid item xs={12} xl={12}>
                            <Skeleton className={classes.skeleton} variant="rect" width="100%" height={50} />
                        </Grid>
                        <Grid item xs={12} xl={12}>
                            <Skeleton className={classes.skeleton} variant="rect" width="100%" height={300} />
                        </Grid>
                    </Grid>
                )}
                {!isLoadingStats && (
                    <>
                        <CardHeader className={classes.cardHeader} title={`Requests timeline - ${new Date().getFullYear()}`} />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography align="center" variant="body2">
                                    Data about Transaction in Catalogue only
                            </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Bar options={options} data={getData()} />
                            </Grid>
                        </Grid>
                    </>
                )}
            </CardContent>
        </Card>
    );
}