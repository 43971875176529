import React, { useContext } from 'react';

import { 
    Card,
    CardContent,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Divider
 } from '@material-ui/core';

 import { Skeleton } from '@material-ui/lab';

 import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
 import EqualizerOutlinedIcon from '@material-ui/icons/EqualizerOutlined';

import {HomeContext} from '../HomeContext'

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    skeleton:{
        margin: theme.spacing(1)
    }
}));

export const RequestsCounter = (props) => {
    const classes = useStyles();

    const { 
        state,    
        isLoadingStats,    
    } = useContext(HomeContext);

    const getRequestCount = (type) => {

        switch(type){

            case "running":
                if(state.stats && state.stats.totals){
                    let stat = state.stats.totals.find(x => x.groupName === "Running Requests");
                    if(stat){
                        return stat.total;
                    }
                    return 0;
                }
                break;

            case "completed":
                if (state.stats && state.stats.totals) {
                    let stat = state.stats.totals.find(x => x.groupName === "Transactions in Catalogue");
                    if (stat) {
                        return stat.total;
                    }
                    return 0;
                }
                break;

            case "mapped":
                if (state.stats && state.stats.totals) {
                    let stat = state.stats.totals.find(x => x.groupName === "Transactions in Mapping");
                    if (stat) {
                        return stat.total;
                    }
                    return 0;
                }
                break;

            default:
                break;
        }
    
    }

    return (
        <Card>
            <CardContent>
                {isLoadingStats && (
                    <>
                        <Skeleton className={classes.skeleton} variant="rect" width="100%" height="70px" />
                        <Skeleton className={classes.skeleton} variant="rect" width="100%" height="70px" />
                    </>
                )}
                {!isLoadingStats && (
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <InsertDriveFileOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="body2">
                                        Running request
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant="h6">
                                        {getRequestCount("running")}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemIcon>
                                <EqualizerOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="body2">
                                        Transaction in Catalogue
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant="h6">
                                        {getRequestCount("completed")}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemIcon>
                                <EqualizerOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="body2">
                                        Transaction in Mapping
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant="h6">
                                        {getRequestCount("mapped")}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </List>
                )}
            </CardContent>
        </Card>
    );
}