import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import _ from "lodash";

import {
    Typography
} from '@material-ui/core';

import AsyncSelect from 'react-select/async'

import PersonIcon from '@material-ui/icons/PersonOutlined'

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

export const SearchUser = (props) => {
    const classes = useStyles();    

    // We use lodash debounce to avoid gazillion of request
    // We use the old fashion promise way because react select + debounce 
    // seems to work well only when using promises :(
    //
    // https://github.com/JedWatson/react-select/issues/3075
    //
    const loadOptions = _.debounce((inputValue, callback) => {
        const { loadOptions } = props;
        if(loadOptions) loadOptions(inputValue, callback);
        else callback([]);
    }, 500);

    return (
        <React.Fragment>
            {props.label &&
                <Typography variant="overline">{props.label}</Typography>
            }
            <AsyncSelect
                {...props}
                isClearable
                placeholder="Search by name or email..."
                loadOptions={loadOptions}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}/>
        </React.Fragment>
    );
}