import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
    DashboardPage, HomePage, ImportPage, LocalTablesPage, RolesPage, TransactionRequestPage,
    TransactionRequestsPage
} from '../pages';

import { UserContext } from '../contexts';

// Looking for an icon name?
// look here! https://material-ui.com/components/material-icons/
import AddIcon from '@material-ui/icons/Add';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFileOutlined';
import LayersIcon from '@material-ui/icons/LayersOutlined';
import ListIcon from '@material-ui/icons/ListOutlined';
import PeopleAltIcon from '@material-ui/icons/PeopleAltOutlined';
import RestorePageOutlinedIcon from '@material-ui/icons/RestorePageOutlined';
import SpeedIcon from '@material-ui/icons/SpeedOutlined';
import { ApplicationRoles } from '../../helpers';

export const NavRoutes = {

    MyDesk: "/",
    Logout: "/logout",
    NotFound: "/notfound",
    NoSettings: "/nosettings",
    Forbidden: "/forbidden",
    NewRequest: "/requests/new",
    EditRequest: "/requests/edit/:requestId",
    ViewRequest: "/requests/view/:requestId",
    RequestHistory: "/requests/history/:requestId",
    DraftRequests: "/requests/draft",
    RunningRequests: "/requests/running",
    CompletedRequests: "/requests/completed",
    UndeletedRequests: "/requests/repository",
    AllRequests: "/requests/all",
    ReworkRequests: "/requests/rework",
    ReworkedRequests: "/requests/reworked",
    Performances: "/performances",
    MassiveImport: "/massiveImport",

    Admin: {
        Dashboard: "/admin",
        Roles: "/admin/roles/all",
        EditRole: "/admin/roles/edit/:roleId",
        AddUserInRole: "/admin/roles/edit/:roleId/adduser",
        EditUserInRole: "/admin/roles/edit/:roleId/edituser/:userKey",
        SapTables: "/admin/tables/sap",
        LocalTables: "/admin/tables/local",
        Settings: "/admin/settings"
    }
};

export const NavContext = React.createContext(null);

const { Provider } = NavContext;
export const NavProvider = (props) => {
    const history = useHistory();
    const location = useLocation();

    const {
        draftRequests,
        reworkRequests
    } = useContext(UserContext)

    const [breadcrumbs, setBreadcrumbs] = useState(JSON.parse(sessionStorage.getItem("breadcrumbs") || "[]"));

    const navigateTo = (route, args) => {
        route = compileRoute(route, args);
        history.push(route);
    }

    const switchTo = (route, args) => {
        route = compileRoute(route, args);
        history.replace(route);
    }

    const getRouteTo = (route, args) => {
        route = compileRoute(route, args);
        return route;
    }

    const compileRoute = (route, args) => {
        if (route.includes(":") && args && args.length > 0) {
            for (let i = 0; i < args.length; i++) {
                let startIndex = route.indexOf(":", 0);
                if (startIndex === -1) break;
                let endIndex = route.indexOf("/", startIndex);
                if (endIndex === -1) endIndex = route.length - 1;
                else endIndex--;
                const token = route.substr(startIndex, (endIndex - startIndex) + 1);
                route = route.replace(token, args[i]);
            }
        }

        return route;
    }

    const setBreadcrumbRoot = (title, path) => {
        const tokens = [{ title, url: path ? path : location.pathname }];
        setBreadcrumbs(tokens);
        sessionStorage.setItem("breadcrumbs", JSON.stringify(tokens));
    }

    const setBreadcrumbToken = (title) => {
        const tokens = [].concat(breadcrumbs);
        const token = tokens.find(x => x.url === location.pathname);
        if (token) {
            token.title = title;
            setBreadcrumbs(tokens);
            sessionStorage.setItem("breadcrumbs", JSON.stringify(tokens));
        }
    }

    const setBreadcrumbTokens = (tokens) => {
        if (tokens) {
            setBreadcrumbs(tokens)
            sessionStorage.setItem("breadcrumbs", JSON.stringify(tokens));
        }
    }

    const addBreadcrumbToken = (title) => {
        let tokens = [].concat(breadcrumbs);
        if (tokens.find(x => x.url === location.pathname)) {
            const index = tokens.findIndex(x => x.url === location.pathname);
            tokens = tokens.slice(0, index + 1);
        } else {
            tokens.push({ title, url: location.pathname });
        }

        setBreadcrumbs(tokens);
        sessionStorage.setItem("breadcrumbs", JSON.stringify(tokens));
    }

    const getCurrentLocation = () => {
        return location;
    }

    const [menuItems, setMenuItems] = useState([]);
    useEffect(() => {

        setMenuItems([
            {
                title: "MyDesk",
                routePath: NavRoutes.MyDesk,
                icon: <SpeedIcon color="primary" />,
                isProtected: true,
                component: HomePage,
                isAdmin: false
            },
            {
                title: "New Transaction",
                routePath: NavRoutes.NewRequest,
                icon: <AddIcon color="primary" />,
                isProtected: true,
                component: TransactionRequestPage,
                isAdmin: false,
                roles: [ApplicationRoles.Opener]
            },
            {
                title: "Multiple New Transactions",
                routePath: NavRoutes.MassiveImport,
                icon: <AddIcon color="primary" />,
                isProtected: true,
                component: ImportPage,
                isAdmin: false,
                roles: [ApplicationRoles.Administrator, ApplicationRoles.Ciso]
            },
            {
                title: `Draft Transaction (${draftRequests})`,
                routePath: NavRoutes.DraftRequests,
                icon: <InsertDriveFileIcon color="primary" />,
                isProtected: true,
                component: TransactionRequestsPage,
                isAdmin: false,
                roles: [ApplicationRoles.Opener]
            },
            {
                title: "Running requests",
                routePath: NavRoutes.RunningRequests,
                icon: <ListIcon color="primary" />,
                isProtected: true,
                component: TransactionRequestsPage,
                isAdmin: false
            },
            {
                title: `Rework requests`,
                routePath: NavRoutes.ReworkRequests,
                icon: <RestorePageOutlinedIcon color="primary" />,
                isProtected: true,
                component: TransactionRequestsPage,
                isAdmin: false
            },
            {
                title: `Reworked Transactions`,
                routePath: NavRoutes.ReworkedRequests,
                icon: <ListIcon color="primary" />,
                isProtected: true,
                component: TransactionRequestsPage,
                isAdmin: false,
                roles: [ApplicationRoles.Business, ApplicationRoles.Ciso, ApplicationRoles.Opener]
            },
            {
                title: "TCodeMap Repository",
                routePath: NavRoutes.UndeletedRequests,
                icon: <ListIcon color="primary" />,
                isProtected: true,
                component: TransactionRequestsPage,
                isAdmin: false
            },
            {
                title: "All Transactions in TCodemap",
                routePath: NavRoutes.AllRequests,
                icon: <ListIcon color="primary" />,
                isProtected: true,
                component: TransactionRequestsPage,
                isAdmin: false,
                roles: [ApplicationRoles.Administrator, ApplicationRoles.Ciso]
            },
            {
                title: "Dashboard",
                routePath: NavRoutes.Admin.Dashboard,
                isProtected: true,
                component: DashboardPage,
                isAdmin: true
            },
            {
                title: "Roles",
                description: "View and manage user role assignations. Here you can monitor and handle user authorizations.",
                routePath: NavRoutes.Admin.Roles,
                icon: <PeopleAltIcon />,
                isProtected: true,
                component: RolesPage,
                isAdmin: true,
                roles: [ApplicationRoles.Administrator]
            },
            {
                title: "Local Data",
                description: "Manage local data values. Here you can monitor and handle which data values are available.",
                routePath: NavRoutes.Admin.LocalTables,
                icon: <LayersIcon />,
                isProtected: true,
                component: LocalTablesPage,
                isAdmin: true,
                roles: [ApplicationRoles.Administrator]
            },
            {
                title: "Massive Import",
                description: "",
                routePath: NavRoutes.MassiveImport,
                icon: <LayersIcon />,
                isProtected: true,
                component: ImportPage,
                isAdmin: true,
                roles: [ApplicationRoles.Administrator]
            }
        ]);

    }, [draftRequests, reworkRequests])

    return (
        <Provider value={{
            menuItems,
            navigateTo,
            switchTo,
            getRouteTo,
            breadcrumbs,
            setBreadcrumbRoot,
            setBreadcrumbToken,
            setBreadcrumbTokens,
            addBreadcrumbToken,
            getCurrentLocation
        }}>
            {props.children}
        </Provider>
    );
}