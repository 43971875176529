import React, { useContext } from 'react';
import { useParams } from "react-router";

import {
    Grid,
    Typography,
    Link,
    Stepper,
    Step,
    StepLabel,
    Button,
    colors
} from '@material-ui/core';

import {
    DateTime
} from '../../../common';

import { HistoryContext } from '../HistoryContext';
import { NavContext, NavRoutes } from '../../../contexts';

import { RequestStatus, getRequestStatusMessage } from '../../../../helpers';

import GetAppIcon from '@material-ui/icons/GetApp';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    history: {
        marginBottom: theme.spacing(1)
    },
    date: {

    },
    time: {
        marginLeft: "6px"
    },
    exportButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    notes: {
        color: colors.blue[500],
        marginLeft: "4px"
    },
    urlContainer: {
        cursor: "pointer"
    },
    urlBody: {
        textDecoration: "underline"
    }
}));

export const RequestHistory = (props) => {
    const classes = useStyles();
    const { navigateTo } = useContext(NavContext);

    const {
        state,
        downloadHistory,
        isDownloading
    } = useContext(HistoryContext);

    const { requestId } = useParams()

    const getSteps = (history) => {
        if (history.statusChanges !== null
            && history.statusChanges.length > 0) {
            return history.statusChanges.map((change, index) =>
                <Step key={index} color="primary">
                    <StepLabel
                        optional={
                            <>
                                <Typography variant="caption" className={classes.date}>
                                    <DateTime value={change.changeDate} date />
                                </Typography>
                                <Typography variant="caption" className={classes.time}>
                                    <DateTime value={change.changeDate} time />
                                </Typography>
                                <Typography variant="caption" component="span">
                                    &nbsp; by {change.doerFullName || "Unknown"}
                                </Typography>
                            </>
                        }>

                        {index > 0 && `${getRequestStatusMessage(history.statusChanges[index - 1].status)} -> `}

                        {getRequestStatusMessage(change.status)}

                    </StepLabel>
                </Step>
            );
        }
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Link
                    component="button"
                    variant="caption"
                    className={classes.history}
                    onClick={() => {
                        navigateTo(NavRoutes.EditRequest, [state.history.transactionRequestId])
                    }}>
                    Go back to the request
                </Link>
            </Grid>
            <Grid item xs={12}>
                <Button
                    className={classes.exportButton}
                    disabled={isDownloading}
                    type="button"
                    variant="outlined"
                    onClick={() => downloadHistory(requestId)}>
                    <GetAppIcon />&nbsp;Export Excel
                </Button>
            </Grid>
            {state.history
                && state.history.statusChanges
                && state.history.statusChanges.length > 0 &&
                <Grid item xs={12}>
                    <Stepper orientation="vertical" activeStep={state.history.statusChanges.length - 1}>
                        {getSteps(state.history)}
                    </Stepper>
                </Grid>
            }
        </Grid>
    );
}